import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CardGen from "src/modules/admin/generated-videos/card-gen";
import OnBottom from "src/common/components/on-bottom/on-bottom";
import Preloader from "src/common/components/preloader/preloader";
import { getVideos } from "src/common/services/generate";

const ListGen = (props) => {
    const { vtype } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const [videos, setVideos] = useState(null);
    const [pageNum, setPageNum] = useState(1);
    const [fetching, setFetching] = useState(null);
    const [onUse, setOnUse] = useState(null);

    useEffect(() => {
        handleVideos({ opr: "list", pageNum: 1 });
    }, [location]);

    useEffect(() => {
        if (props.OnUse && onUse) {
            props.OnUse({ ...onUse });
        }
    }, [onUse]);

    const handleVideos = async ({ opr, pageNum }) => {
        setFetching(true);
        switch (opr) {
            case "list":
                try {
                    let list_res = [];

                    const thevtype = vtype ? vtype : "";
                    list_res = await getVideos({ vtype:thevtype, pageNum });

                    //console.log(`list_res ${pageNum}:: `, list_res);

                    if (location?.state?.reload) {
                        setVideos([]);
                        await new Promise((res) => setTimeout(() => res(), 10));
                        navigate(location.pathname);
                    }

                    if (list_res && pageNum > 1) {
                        setVideos([...videos, ...list_res]);
                    } else {
                        setVideos([...list_res]);
                    }

                    if (list_res.length > 0) {
                        setPageNum(pageNum + 1);
                    }
                } catch (error) {
                    //console.log(error);
                }

                break;
            default:
                break;
        }
        setFetching(false);
    };
    return (
        <>
            <div className="list-gen-container relative  slideFromTop flex-1 flex flex-col">
                {((fetching && pageNum === 1) || (fetching && props.paging === "manual")) && <Preloader />}

                <OnBottom
                    OnBottom={async (val) => {
                        if (pageNum > 1 && props.paging !== "manual") {
                            await handleVideos({ opr: "list", pageNum });
                        }
                    }}
                >
                    {videos?.length > 0 && (
                        <div className={`grid gap-6 grid-cols-1 justify-between ${props.cols ? `grid-cols-${props.cols}` : `grid-cols-6`}`}>
                            {videos.map((video, v) => (
                                <CardGen
                                    {...props}
                                    key={v}
                                    video={video}
                                    OnUse={(video) => {
                                        setOnUse(video);
                                    }}
                                    OnDelete={async () => {
                                        await handleVideos({ opr: "list", pageNum: 1 });
                                    }}
                                />
                            ))}
                        </div>
                    )}
                    {!fetching && props.paging === "manual" && videos?.length > 19 && (
                        <div
                            className="p-3 cursor-pointer mt-6 flex items-center justify-center border border-lightblue100 text-black/50 rounded-xl hover:text-black/100 hover:bg-lightblue100/30"
                            onClick={async () => {
                                await handleVideos({ opr: "list", pageNum });
                            }}
                        >
                            Load more
                        </div>
                    )}
                </OnBottom>
                {videos?.length <= 0 && fetching !== null && vtype !== "public" && (
                    <div className="flex h-full flex-1 items-center justify-center">
                        <div className="flex flex-col gap-4 items-center">
                            {/* <div className="border-2 border-indigo-500/10 rounded-full w-28 h-28 flex items-center justify-center mb-6"><CiImageOff className="text-6xl text-indigo-500/20 " /></div> */}
                            You have not generate any videos yet.
                            <button
                                className="button button-small px-4"
                                onClick={() => {
                                    navigate(`/admin/generate-video`, { state: { todo: "create" } });
                                }}
                            >
                                Generate
                            </button>
                        </div>
                    </div>
                )}
                {videos?.length <= 0 && fetching !== null && vtype === "public" && (
                    <div className="flex h-full flex-1 items-center justify-center">
                        No trending videos yet.
                    </div>
                )}
            </div>
            {/* <div></div> */}
        </>
    );
};

export default ListGen;
