import { useState, useEffect } from "react";
import { IoLayersOutline } from "react-icons/io5";
import { LuFunctionSquare } from "react-icons/lu";
import { PiMagicWand } from "react-icons/pi";
import StepClass from "./class-step";
import { listEffects } from "src/common/services/studio";
import { v4 as uuidv4 } from "uuid";
const StepAdd = (props) => {
    const { selectedScene, setSelectedScene, setShowStepAdd, setSelectedDetail } = props;
    const [search, setSearch] = useState("");
    const [effectsLibrary, setEffectsLibrary] = useState([]);

    const overlaysLibrary = [
        StepClass.overlay({
            name: "Image",
            description: "Add an image to your video",
            type: "image",
            action: "overlay-image",
            params: {
                width: null,
                height: null,
            },
        }),
        StepClass.overlay({
            name: "Video",
            description: "Add a video to your video",
            type: "video",
            action: "overlay-video",
            params: {
                width: null,
                height: null,
            },
        }),
        StepClass.overlay({
            name: "Text",
            description: "Add a text to your video",
            type: "text",
            action: "overlay-text",
            params: {
                text: "Add your text here",
                width: null,
                height: null,

                align: null,
                rotation: null,

                font: {
                    name: "Montserrat",
                    style: "normal 400",
                    size: 24,
                    color: "#000000",
                },
            },
        }),
    ];

    const functionsLibrary = [
        StepClass.audio({
            name: "Audio",
            description: "Add an audio to your video",
            params: {
                file: null,
                src: null,
                volume: 0.5,
            },
        }),
    ];

    // const effectsLibrary = [
    //     StepClass.effect({
    //         name: "Fade",
    //         description: "Add fade effect to your video",
    //         action: "fade",
    //         params: {
    //             option: "in",
    //             duration: 5,

    //         },

    //     }),

    //     StepClass.effect({
    //         name: "Zoom",
    //         description: "Add zoom effect to your video",
    //         action: "zoom",
    //         params: {
    //             option: "in",
    //             duration: 5,
    //         },

    //     }),

    //     StepClass.effect({
    //         name: "Adjust Brightness",
    //         description: "Adjust the brightness of your video",
    //         action: "adjust-brightness",
    //         params: {
    //             strength: 0.5,
    //         },

    //     }),
    //     StepClass.effect({
    //         name: "Grayscale",
    //         description: "Grayscale your video",
    //         action: "grayscale-video",
    //     }),

    //     StepClass.effect({
    //         name: "Gaussian Blur",
    //         description: "Blur your video",
    //         action: "gaussian-blur",
    //         params: {
    //             strength: 10,
    //         },

    //     }),
    //     StepClass.effect({
    //         name: "Vintage Look",
    //         description: "Add vintage effect to your video",
    //         action: "vintage-look",
    //     }),
    // ];

    useEffect(() => {
        handleList({ type: "effects" });
    }, []);

    const handleSelectStep = (step) => {

        const theStep = {
            ...step,
            id: uuidv4(),
            detailType: "steps",
            params: typeof step.params === "string" ? JSON.parse(step.params) : step.params,
           
        };

        console.log("theStep :: ", theStep);

        setSelectedScene((prev) => ({
            ...prev,
            steps: [...prev.steps, theStep],
        }));

        setSelectedDetail(theStep);
        setShowStepAdd(false);
    };

    const handleList = async ({ type }) => {
        switch (type) {
            case "effects":
                try {
                    const res = await listEffects();
                    console.log("res :: ", res);
                    setEffectsLibrary(res);
                } catch (err) {
                    console.log("listEffects error   :: ", err);
                }
                break;
        }
    };

    return (
        <div className="flex flex-col min-h-600 max-h-600 relative overflow-hidden">
            <div className="flex flex-col  gap-8 items-start justify-between p-10">
                <div className=" text-lg">Add Step</div>
                <div className="w-full step-library-search border border-lightblue100 rounded-2xl p-2">
                    <input type="text" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
                </div>
            </div>
            <div className="flex  flex-1 flex-col gap-4  relative overflow-y-auto p-10 pt-0">
                <div className="text-xs uppercase opacity-50">Overlays</div>
                <div className="step-library-list grid grid-cols-3 gap-4 pb-10">
                    {overlaysLibrary.map((step, s) => (
                        <div
                            key={s}
                            className="transition-all step-library-item border-4 border-lightblue100/50 rounded-3xl relative overflow-hidden hover:border-blue cursor-pointer"
                            onClick={() => handleSelectStep(step)}
                        >
                            {/* <div className="w-full h-52 bg-black relative overflow-hidden">
                            <VideoPlayer src={step.preview} fluid={true} hoverPlay={true} actions={false} fit={`cover`} />
                        </div> */}

                            <div className="flex flex-row items-start gap-6 p-6">
                                <div>
                                    <IoLayersOutline className="text-xl  text-blue w-7 h-7" />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <div className="font-semibold">{step.name}</div>
                                    <div className="text-sm text-black/30">{step.description}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="text-xs uppercase opacity-50">Effects</div>
                <div className="step-library-list grid grid-cols-3 gap-4 pb-10">
                    {effectsLibrary.map((step, s) => (
                        <div
                            key={s}
                            className="transition-all step-library-item border-4 border-lightblue100/50 rounded-3xl relative overflow-hidden hover:border-blue cursor-pointer"
                            onClick={() => handleSelectStep(step)}
                        >
                            {/* <div className="w-full h-52 bg-black relative overflow-hidden">
                            <VideoPlayer src={step.preview} fluid={true} hoverPlay={true} actions={false} fit={`cover`} />
                        </div> */}

                            <div className="flex flex-row items-start gap-6 p-6">
                                <div>
                                    <PiMagicWand className="text-xl  text-blue w-7 h-7" />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <div className="font-semibold">{step.name}</div>
                                    <div className="text-sm text-black/30">{step.description}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="text-xs uppercase opacity-50">Functions</div>
                <div className="step-library-list grid grid-cols-3 gap-4">
                    {functionsLibrary.map((step, s) => (
                        <div
                            key={s}
                            className="transition-all step-library-item border-4 border-lightblue100/50 rounded-3xl relative overflow-hidden hover:border-blue cursor-pointer"
                            onClick={() => handleSelectStep(step)}
                        >
                            {/* <div className="w-full h-52 bg-black relative overflow-hidden">
                            <VideoPlayer src={step.preview} fluid={true} hoverPlay={true} actions={false} fit={`cover`} />
                        </div> */}

                            <div className="flex flex-row items-start gap-6 p-6">
                                <div>
                                    <LuFunctionSquare className="text-xl  text-blue w-7 h-7" />
                                </div>
                                <div className="flex flex-col gap-1">
                                    <div className="font-semibold">{step.name}</div>
                                    <div className="text-sm text-black/30">{step.description}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default StepAdd;
