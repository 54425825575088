import VideoPlayer from "src/common/components/video-player/video-player";
import { v4 as uuidv4 } from "uuid";
const Input = (props) => {
    const { showInputAdd, setShowInputAdd, selectedScene, selectedDetail, setSelectedDetail, setSelectedScene } = props;

    const handleInput = ({ opr, input, media }) => {
        switch (opr) {
            case "select":
                setSelectedDetail(selectedDetail?.id === input.id ? null : { ...input });
                break;
            case "add":
                // const newInput = {
                //     id: uuidv4(),
                //     detailType: "inputs",
                //     action: "input-media",
                //     type: media.type.split("/")[0],

                //     params: {
                //         x: null,
                //         y: null,
                //         src: URL.createObjectURL(media),
                //         file: media,
                //         width: null,
                //         height: null,
                //     },
                // };

                // setSelectedScene({ ...selectedScene, inputs: [...selectedScene.inputs, newInput] });
                // setSelectedDetail(newInput);

                break;
            default:
                break;
        }
    };

    return (
        <div className="scene-input w-full flex flex-col gap-2 p-6 pb-0 items-center">
            {selectedScene.inputs.length > 0 &&
                selectedScene.inputs.map((input) => (
                    <div
                        key={input.id}
                        className={`relative overflow-hidden rounded-xl w-full h-52 bg-black ${selectedDetail?.id === input.id ? "border-2 border-blue" : ""}`}
                        onClick={() => {
                            handleInput({ opr: "select", input });
                        }}
                    >
                        {input.type.includes("image") ? (
                            <img src={input.params.src} alt="input" className="w-full h-full object-contain" />
                        ) : (
                            <VideoPlayer src={input.params.src} fluid={true} hoverPlay={true} actions={false} />
                        )}
                    </div>
                ))}
            {selectedScene.inputs.length === 0 && (
                <div
                    className="transition w-full h-52 scene-step-add flex flex-col items-center justify-center gap-2 p-3 group border border-dashed border-blue bg-blue/10 text-blue rounded-xl cursor-pointer"
                    onClick={() => {
                        setShowInputAdd(true);
                        //document.getElementById("mediaInput").click();
                    }}
                >
                    <div className="scene-step-action w-6 flex items-center items-center justify-center text-lg">+</div>
                    <div className="scene-step-add-type">Add Media</div>
                    {/* <input
                        type="file"
                        id="mediaInput"
                        className="hidden"
                        accept="image/*,video/*"
                        onChange={(e) => {
                            handleInput({ opr: "add", media: e.target.files[0] });
                        }}
                    /> */}
                </div>
            )}
        </div>
    );
};

export default Input;
