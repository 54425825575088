import { useState } from "react";
import Preloader from "src/common/components/preloader/preloader";
import { upload } from "src/common/services/utils";
import { IoIosAdd } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";
import { FiTrash2 } from "react-icons/fi";

const UiPhotos = (props) => {
    const { data, scene } = props;
    const [fetching, setFetching] = useState(false);

    const handleUpload = async (file) => {
        setFetching(true);
        try {
            const res = await upload({ file });
            //console.log("upload res :: ", res);
            if (res.data[0]?.cache) {
                const photoObj = {
                    id: uuidv4(),
                    duration: 0,
                    effect: {
                        id: "instant",
                        name: "Instant",
                    },
                    image: res.data[0]?.cache,
                };

                props.OnAdd(photoObj);
            }
        } catch (error) {
            //console.log("upload error :: ", error);
        }
        setFetching(false);
    };

    const handlePhoto = ({ opr, photo }) => {
        switch (opr) {
            case "delete":
                scene.photos = scene.photos.filter((p) => p.id !== photo.id);
                props.OnSet(scene);
                break;

            default:
                break;
        }
    };

    return (
        <>
            {fetching && (
                <div className="absolute w-full h-full z-50 left-0 top-0 bg-white/80 flex items-center justify-center">
                    <Preloader type={`inline`} />
                </div>
            )}
            {scene.photos.map((photo, p) => (
                <div
                    key={p}
                    className="transition-all border p-2 bg-lightblue50 border-lightblue100 rounded-xl relative overflow-hidden cursor-pointer hover:border-blue/50 hover:bg-white "
                >
                      <FiTrash2
                            className="absolute right-2 top-2 opacity-40 hover:opacity-100 z-20"
                            onClick={() => {
                                handlePhoto({ opr: "delete", photo });
                            }}
                        />
                    <div
                        className="flex flex-row items-center gap-4 "
                        onClick={() => {
                            props.onClick(photo);
                        }}
                    >
                      
                        <div className="relative min-h-16 max-h-16  min-w-16 max-w-16 relative overflow-hidden rounded-lg flex items-center justify-center">
                            <img src={photo.image} className="absolute left-0 top-0 object-cover w-full h-full" />
                        </div>
                        <div className="flex-1 flex flex-col gap-4 opacity-60">
                            <div className="flex flex-row items-center justify-between">
                                <div className="text-xs">...{photo.image.slice(-20)}</div>
                            </div>
                            <div className="flex flex-row gap-1 items-center">
                                <div className="text-xs bg-lightblue300 px-2 py-0 rounded-full">{photo.effect.name}</div>
                                <div className="text-xs bg-lightblue300 px-2 py-0 rounded-full">{photo.duration} sec</div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            <div
                className="text-xs flex-1 flex items-center justify-center gap-2 cursor-pointer w-full opacity-50 hover:opacity-100 text-center pt-2  rounded-full relative overflow-hidden"
                onClick={() => {}}
            >
                <input
                    type="file"
                    accept={`image/*`}
                    className="absolute z-30 left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                    onChange={async (e) => {
                        //console.log(e.target.files[0]);
                        await handleUpload(e.target.files[0]);
                    }}
                />
                <div>Add Photo</div>
                <IoIosAdd className="text-lg" />
            </div>
        </>
    );
};

export default UiPhotos;
