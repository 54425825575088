import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import OnBottom from "src/common/components/on-bottom/on-bottom";
import Preloader from "src/common/components/preloader/preloader";
import { upload } from "src/common/services/utils";
import { getInputImages } from "src/common/services/input";
import CardImage from "src/modules/admin/inputed-images/card-image";
import { CiImageOff } from "react-icons/ci";

const InputedImagesList = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [images, setImages] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [fetching, setFetching] = useState(null);
    const [onUse, setOnUse] = useState(null);

    useEffect(() => {
        handleImages({ opr: "list", pageNum: 1 });
    }, [location]);

    useEffect(() => {
        if (props.action && props.action.type) {
            switch (props.action.type) {
                case "upload":
                    handleImages({ opr: "upload", file: props.action.file });

                    break;

                default:
                    break;
            }
        }
    }, [props.action]);

    useEffect(() => {
        if (props.OnUse && onUse) {
            props.OnUse({ ...onUse });
        }
    }, [onUse]);

    const handleImages = async ({ opr, file, pageNum }) => {
        setFetching(true);
        switch (opr) {
            case "list":
                try {
                    const list_res = await getInputImages({ pageNum });
                    //console.log(`list_res :: ${pageNum}`, list_res);

                    if(location?.state?.reload){
                        setImages([])
                        await new Promise((res) => setTimeout(() => res(), 10));
                        navigate(location.pathname)
                    }

                    if (list_res && pageNum > 1) {
                        setImages([...images, ...list_res]);
                    } else {
                        setImages([...list_res]);
                    }
                    if (list_res.length > 0) {
                        setPageNum(pageNum + 1);
                    }
                } catch (error) {
                    //console.log("error ::", error);
                }

                break;

            case "upload":
                if (file) {
                    try {
                        const upload_res = await upload({ file, network: 'images', channel: "##inputimages" });
                        //console.log("upload_res ::", upload_res);

                        await handleImages({ opr: "list", pageNum: 1 });
                        if (props.OnUploaded) {
                            props.OnUploaded(upload_res);
                        }
                    } catch (error) {
                        //console.log(error);
                        if (props.OnError) {
                            props.OnError(error);
                        }
                    }
                }

                break;

            default:
                break;
        }

        setFetching(false);
    };

    return (
        <div className="min-h-full  h-full relative overflow-hidden slideFromTop flex-1">
            {fetching && pageNum === 1 && <Preloader />}
            <OnBottom
                OnBottom={async (val) => {
                    if (pageNum > 1) {
                        await handleImages({ opr: "list", pageNum });
                    }
                }}
            >
                {images.length > 0 && (
                      <div className={`grid grid-cols-1 gap-6 justify-between ${props.cols ? `grid-cols-${props.cols}` : `grid-cols-6`}`}>
                        {images.map((image, v) => (
                            <CardImage
                                {...props}
                                key={v}
                                image={image}
                                OnUse={(image) => {
                                    setOnUse(image);
                                }}
                                OnDelete={async () => {
                                    await handleImages({ opr: "list", pageNum: 1 });
                                }}
                            />
                        ))}
                    </div>
                )}
            </OnBottom>
            {images.length <= 0 && fetching !== null && (
                <div className="flex h-full flex-1 items-center justify-center">
                    <div className="flex flex-col gap-4 items-center">
                        {/* <div className="border-2 border-indigo-500/10 rounded-full w-28 h-28 flex items-center justify-center mb-6"><CiImageOff className="text-6xl text-indigo-500/20 " /></div> */}
                        You have not added any images yet.
                        <button className="button button-small px-4">
                            Upload
                            <input
                                title=""
                                type="file"
                                accept={`image/*`}
                                className="absolute z-50 left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                                onChange={async (e) => {
                                    //console.log(e.target.files[0]);
                                    handleImages({ opr: "upload", file: e.target.files[0] });
                                }}
                            />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InputedImagesList;
