import React, { useRef, useState, useEffect } from "react";
import { Rect, Group, Image, Transformer } from "react-konva";
import Konva from "konva";
import { FaPlay, FaPause } from "react-icons/fa";
import { renderToString } from "react-dom/server";

const ImageLayer = (props) => {
    const { stageSize, detail, selectedDetail, setSelectedDetail, previewOnly } = props;
    const [image, setImage] = useState(null);
    const videoRef = useRef(null);
    const imageRef = useRef();
    const trRef = useRef();
    const layerRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false);
    const [isDragging, setIsDragging] = useState(false);

    useEffect(() => {
        if (detail.type === "video") {
            // Create video element with same settings as preview.js
            const video = document.createElement("video");
            video.src = detail.params.src;
            video.crossOrigin = "anonymous";
            video.loop = true;
            video.muted = previewOnly;
            video.playsInline = true;
            video.volume = 1.0;
            video.preload = "auto";
            if (!videoRef.current) {
                video.currentTime = 0.01;
            } else {
                video.currentTime = videoRef.current.currentTime;
            }

            // Save video reference
            videoRef.current = video;

            // Wait for metadata and dimensions before setting the image
            video.addEventListener("loadedmetadata", () => {
                if (video.videoWidth && video.videoHeight) {
                    setImage(video);
                }
            });

            // Cleanup
            return () => {
                video.pause();
                video.src = "";
                videoRef.current = null;
            };
        } else if (detail.type === "image") {
            // Handle static image
            const img = new window.Image();
            img.crossOrigin = "anonymous";
            img.src = detail.params.src;
            img.onload = () => {
                setImage(img);
            };
        }
    }, [detail.params.src, detail.type, previewOnly]);

    // Add animation frame for video
    useEffect(() => {
        if (detail.type === "video" && videoRef.current && imageRef.current) {
            const anim = new Konva.Animation((frame) => {
                // Need to update the layer to show video frames
                imageRef.current?.getLayer().batchDraw();
                return true;
            }, imageRef.current?.getLayer());

            anim.start();

            return () => {
                anim.stop();
            };
        }
    }, [detail.type, videoRef.current, imageRef.current]);

    // Function to resize the image to cover the group
    useEffect(() => {
        // Run when image changes or when dimensions need to be set
        if (image) {
            const imgWidth = detail.type === "video" ? image.videoWidth : image.width;
            const imgHeight = detail.type === "video" ? image.videoHeight : image.height;


            const stageRatio = stageSize.width / stageSize.height;
            const imgRatio = imgWidth / imgHeight;

            let newWidth = detail.params.width;
            let newHeight = detail.params.height;
            let newX = detail.params.x;
            let newY = detail.params.y; 

            if (newWidth === null && newHeight === null) {
                if (imgRatio > stageRatio) {
                    // Image is wider than the stage, scale based on width
                    newWidth = stageSize.width;
                    newHeight = (stageSize.width / imgWidth) * imgHeight;
                } else {
                    // Image is taller than the stage, scale based on height
                    newHeight = stageSize.height;
                    newWidth = (stageSize.height / imgHeight) * imgWidth;
                }
            }

            if (newX === null && newY === null) {
                newX = (stageSize.width - newWidth) / 2;
                newY = (stageSize.height - newHeight) / 2;
            }

            // Center the image
            updateDetail({
                newWidth: newWidth,
                newHeight: newHeight,
                newX: newX,
                newY: newY,
            });
        }
    }, [image]);

    useEffect(() => {
        if (selectedDetail?.id === detail.id && trRef.current && imageRef.current) {
            trRef.current.nodes([imageRef.current]);
            trRef.current.getLayer().batchDraw();

            // const node = imageRef.current;
            // updateSelectedScene({ node });
        }
    }, [selectedDetail]);

    const handleSelect = () => {
        if (setSelectedDetail) {
            setSelectedDetail({ ...detail });
        }
    };

    const updateDetail = ({ node, newX, newY, newWidth, newHeight }) => {
        if (!node && !newWidth && !newHeight) return;

        // Get the current node if not provided
        const currentNode = node || imageRef.current;

        // Calculate final dimensions
        const width = newWidth || currentNode.width() * currentNode.scaleX();
        const height = newHeight || currentNode.height() * currentNode.scaleY();
        const x = newX ?? currentNode.x();
        const y = newY ?? currentNode.y();

        // Update the detail first
        if (setSelectedDetail && selectedDetail?.detailType) {
            setSelectedDetail((prev) => ({
                ...prev,
                params: {
                    ...prev.params,
                    x,
                    y,
                    width,
                    height,
                },
            }));
        }

        // Reset scale after updating dimensions
        if (currentNode) {
            currentNode.width(width);
            currentNode.height(height);
            currentNode.scaleX(1);
            currentNode.scaleY(1);
        }
    };

    const handlePlayPause = () => {
        if (!videoRef.current) return;

        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play().catch(console.error);
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <Group ref={layerRef}>
            <Image
                image={image}
                x={detail.params.x}
                y={detail.params.y}
                width={detail.params.width}
                height={detail.params.height}
                ref={imageRef}
                draggable={!previewOnly}
                onMouseDown={(e) => {
                    // Prevent click when dragging
                    if (isDragging) {
                        return;
                    }
                    handleSelect();
                }}
                onDragStart={() => setIsDragging(true)}
                onDragEnd={(e) => {
                    const node = e.target;
                    updateDetail({ node });
                    setIsDragging(false);
                }}
                onTransformStart={(e) => {
                    setIsDragging(true);
                }}
                onTransformEnd={(e) => {
                    setIsDragging(false);
                    const node = imageRef.current;
                    updateDetail({ node });
                }}
            />

            {/* Only show controls when not dragging */}
            {detail.type === "video" && !previewOnly && !isDragging && (
                <>
                    <Rect
                        x={detail.params.x + 10}
                        y={detail.params.y + detail.params.height - 30}
                        width={20}
                        height={20}
                        fill="rgba(0, 0, 0, 0.5)"
                        cornerRadius={5}
                        onClick={handlePlayPause}
                        onTap={handlePlayPause}
                        cursor="pointer"
                    />
                    <Image
                        x={detail.params.x + 15}
                        y={detail.params.y + detail.params.height - 25}
                        width={10}
                        height={10}
                        image={(() => {
                            const icon = isPlaying ? FaPause : FaPlay;
                            const svg = icon({
                                size: "20px",
                                color: "white",
                            });
                            const img = new window.Image();
                            img.src = `data:image/svg+xml;base64,${btoa(renderToString(svg))}`;
                            return img;
                        })()}
                        onClick={handlePlayPause}
                        onTap={handlePlayPause}
                        cursor="pointer"
                    />
                </>
            )}

            {/* Existing Transformer code */}
            {selectedDetail?.id === detail.id && image && detail.params.width !== "" && detail.params.height !== "" && !previewOnly && (
                <Transformer
                    ref={trRef}
                    anchorStroke={`#6676FF`}
                    borderStroke={`#6676FF`}
                    borderStrokeWidth={2}
                    anchorStrokeWidth={2}
                    rotateEnabled={false}
                    enabledAnchors={["top-left", "top-right", "bottom-left", "bottom-right"]}
                    boundBoxFunc={(oldBox, newBox) => {
                        if (newBox.width < 50 || newBox.height < 50) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </Group>
    );
};

export default ImageLayer;
