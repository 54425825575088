import { useState, useEffect } from "react";
import { getStoredUser } from "src/common/components/auth/auth";
import FormInput from "src/common/components/form/input";
import logoIconWhite from "src/common/assets/images/logo-icon-white.svg";
import { upload } from "src/common/services/utils";
import { updateUser } from "src/common/services/user";
import Preloader from "src/common/components/preloader/preloader";

const SettingProfile = (props) => {
    const [fetching, setFetching] = useState(false);
    const [user, setUser] = useState({
        name: "",
        description: "",
        //phone: "",
    });

    useEffect(() => {
        if (getStoredUser() && getStoredUser().id) {
            //console.log(getStoredUser());
            setUser(getStoredUser());
        }
    }, []);



    const handleUpload = async (file) => {
        if (file) {
            setFetching(true);
            try {
                const upload_res = await upload({ file });
                if (upload_res?.data[0]) {
                    const thekey = upload_res?.data[0].key;
                    const thebucket = upload_res?.data[0].s3.split("/")[2];
                    user.tkey = thekey;
                    user.tbucket = thebucket;
                    setUser({ ...user });
                }
            } catch (error) {
                //console.log(error);
            }
            setFetching(false);
        }
    };

    const handleSave = async () => {
        setFetching(true);
        try {
            const update_res = await updateUser(
                getStoredUser().id,
                {
                    name: user.name,
                    description: user.description,
                    //phone: user.phone,
                    tkey: user.tkey,
                    tbucket: user.tbucket,
                },
                true
            );

            localStorage.setItem("DIGITALU_user", JSON.stringify(update_res[0]));
        } catch (error) {
            //console.log(error);
        }
        setFetching(false);
    };

    return (
        <div className="flex flex-1 flex-col gap-8">
            {fetching && <Preloader />}
            <div className="text-lg">Profile</div>
            <div className="flex-1 flex flex-col gap-8">
                <div className="aspect-square w-72 bg-lightblue100 relative overflow-hidden rounded-4xl">
                    <div className="transition absolute w-full h-full  bg-indigo-950/40 opacity-0 hover:opacity-100 flex items-center justify-center">
                        <button className="button button-small button-white px-3">change</button>
                        <input
                            type="file"
                            title=""
                            accept={`image/*`}
                            className="absolute z-50 left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                            onChange={async (e) => {
                                //console.log(e.target.files[0]);
                                handleUpload(e.target.files[0]);
                            }}
                        />
                    </div>

                    {user && user.thumbnail !== "" ? (
                        <img className="object-cover w-full h-full" src={user.thumbnail} />
                    ) : (
                        <div className="w-full h-full bg-blue flex items-center justify-center">
                            <img className="w-1/3" src={logoIconWhite} />
                        </div>
                    )}
                </div>

                <FormInput
                    label={`Name:`}
                    type={`text`}
                    value={user?.name}
                    onChange={(e) => {
                        user.name = e.target.value;
                        setUser({ ...user });
                    }}
                />

                {/* <FormInput
                label={`Phone:`}
                type={`text`}
                value={user?.phone}
                className={`flex-1`}
                onChange={(e) => {
                    user.phone = e.target.value;
                    setUser({ ...user });
                }}
            /> */}

                <FormInput
                    label={`Description:`}
                    type={`textarea`}
                    value={user?.description}
                    onChange={(e) => {
                        user.description = e.target.value;
                        setUser({ ...user });
                    }}
                />
            </div>
            <div className="">
                <button
                    className="button"
                    onClick={async () => {
                        await handleSave();
                    }}
                >
                    Save
                </button>
            </div>
        </div>
    );
};

export default SettingProfile;
