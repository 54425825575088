import React, { useState, useRef } from "react";
import "./player.css"; // Add CSS for styling
import { FaPlay } from "react-icons/fa";

const VideoPlayer = ({ src, poster }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    const togglePlay = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current
                .play()
                .then(() => {})
                .catch((error) => {
                    //console.log("error playing video :", error);
                });
        }
        setIsPlaying(!isPlaying);
    };
    const handleVideoEnd = () => {
        setIsPlaying(false); // Reset play button when video ends
    };

    return (
        <div className="video-container  w-full h-full">
            <video
                ref={videoRef}
                src={src}
                poster={poster}
                className="video object-cover !w-full !h-full scale-110"
                onClick={togglePlay}
                onEnded={handleVideoEnd}
                playsInline
            />
            {!isPlaying && (
                <div className="transition-all absolute top-0 left-0 w-full h-full z-50">
                    <div className="flex flex-1 h-full items-center justify-center cursor-pointer" onClick={togglePlay}>
                        <div className="transition-all text-white w-24 h-24 rounded-full bg-blue text-lg flex items-center justify-center  hover:bg-blue/60">
                            <FaPlay />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoPlayer;
