import { useEffect, useState, useRef } from "react";
import { Group, Image } from "react-konva";
import Konva from "konva";

const TransitionLayer = ({ step, index, project, selectedScene }) => {
    const [video, setVideo] = useState(null);
    const imageRef = useRef(null);

    useEffect(() => {

        let videoSrc = null;    
        if (!step.params.scenes?.[0]?.result?.src) {
            console.log("No video source found");
            return;
        }
        if (selectedScene.result.src) {
            videoSrc = selectedScene.result.src;
        } else {
            videoSrc = step.params.scenes[0].result.src;
        }

        const video = document.createElement("video");
        video.style.display = "none";
        video.muted = true;
        video.playsInline = true;
        video.loop = true;
        video.src = videoSrc;

        video.addEventListener("loadedmetadata", () => {
            video
                .play()
                .then(() => {
                    //console.log("Video playing successfully");
                })
                .catch((error) => {
                    console.warn("Autoplay failed:", error);
                });
        });

        setVideo(video);

        // Cleanup
        return () => {
            video.pause();
            video.removeAttribute("src");
            video.load();
        };
    }, [step.params.scenes]);

    // Add Konva animation for video updates
    useEffect(() => {
        if (video && imageRef.current) {
            const anim = new Konva.Animation((frame) => {
                // Need to update the layer to show video frames
                imageRef.current?.getLayer().batchDraw();
                return true;
            }, imageRef.current?.getLayer());

            anim.start();

            return () => {
                anim.stop();
            };
        }
    }, [video, imageRef.current]);

    return <Group key={index}>{video && <Image ref={imageRef} image={video} width={project.width} height={project.height} />}</Group>;
};

export default TransitionLayer;
