import { useEffect, useState } from "react";
import { MdAlternateEmail, MdPhoneIphone } from "react-icons/md";

import { MdOutlineMms } from "react-icons/md";

import { copyToClipboard } from "src/common/utils/utils";
import { FaRegCopy } from "react-icons/fa";
import { LuCheckCircle } from "react-icons/lu";
import { FaTiktok, FaFacebook, FaInstagram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

import ShareSMS from "./share-sms";
import ShareMMS from "./share-mms";
import ShareEmail from "./share-email";



const Share = (props) => {
    const [copied, setCopied] = useState(false);

    const tabs = [
        {
            id: "sms",
            label: "SMS",
            icon: <MdPhoneIphone className="text-3xl" />,
        },
        {
            id: "mms",
            label: "MMS",
            icon: <MdOutlineMms className="text-2xl" />,
        },
        {
            id: "email",
            label: "Email",
            icon: <MdAlternateEmail className="text-2xl" />,
        },

        {
            id: "",
            label: <div className="flex flex-col gap-2 items-center">Facebook <div className="text-xs bg-lightblue100 rounded-full px-2 py-1">Coming soon</div></div>,
            icon: <FaFacebook className="text-2xl" />,
        },
        {
            id: "",
            label: <div className="flex flex-col gap-2 items-center">Tiktok <div className="text-xs bg-lightblue100 rounded-full px-2 py-1">Coming soon</div></div>,
            icon: <FaTiktok className="text-2xl" />,
        },
        {
            id: "",
            label: <div className="flex flex-col gap-2 items-center">Instagram <div className="text-xs bg-lightblue100 rounded-full px-2 py-1">Coming soon</div></div>,
            icon: <FaInstagram className="text-2xl" />,
        },
        {
            id: "",
            label: <div className="flex flex-col gap-2 items-center">Twitter <div className="text-xs bg-lightblue100 rounded-full px-2 py-1">Coming soon</div></div>,
            icon: <FaXTwitter className="text-2xl" />,
        },

       
    ];

    const [selTab, setSelTab] = useState();

    return (
        <>
            {!selTab && (
                <div className="flex flex-col gap-10 p-10">
                    <div className="">
                        <div className="text-xl font-medium tracking-snug">Share Video</div>
                    </div>

                    <div className="flex flex-row gap-10 items-start justify-start py-10 px-4 overflow-x-auto">
                        <div className="flex flex-row gap-10 min-w-max">
                            {tabs.map((tab, t) => (
                                <div
                                    key={t}
                                    className={`flex flex-col gap-4 justify-start items-center group cursor-pointer`}
                                    onClick={() => {
                                        if(tab.id !== ""){
                                        setSelTab({ ...tab });
                                        }
                                    }}
                                >
                                    <div
                                        className={`transition-all bg-lightblue80 flex w-32 h-32 items-center justify-center rounded-full text-blue  ${
                                            selTab?.id === tab.id ? "!bg-blue text-white " : "group-hover:bg-blue group-hover:text-white "
                                        }`}
                                    >
                                        {tab.icon}
                                    </div>
                                    <div className="group-hover:text-blue">{tab.label}</div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="flex flex-col gap-4">
                        <div>Video link : </div>

                        <div className="flex flex-row gap-4 items-center">
                        <div className="border border-lightblue100 bg-lightblue50 rounded-2xl p-4 items-center flex relative overflow-hidden">
                            <div className="flex-1 "> {props.video.result}</div>
                        </div>
                        <div
                            className="transition-all  bg-white border border-lightblue100 bg-lightblue50 rounded-2xl p-4 cursor-pointer hover:opacity-50 z-50 "
                            onClick={() => {
                                setCopied(copyToClipboard(props.video.result));
                            }}
                        >
                            {!copied ? <FaRegCopy className="text-lg" /> : <LuCheckCircle className="text-lg text-emerald-500" />}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {selTab?.id === "sms" && (
                <ShareSMS
                    video={props.video}
                    OnClose={() => {
                        setSelTab(null);
                    }}
                />
            )}

            {selTab?.id === "mms" && (
                <ShareMMS
                    video={props.video}
                    OnClose={() => {
                        setSelTab(null);
                    }}
                />
            )}

            {selTab?.id === "email" && (
                <ShareEmail
                    video={props.video}
                    OnClose={() => {
                        setSelTab(null);
                    }}
                />
            )}
        </>
    );
};

export default Share;
