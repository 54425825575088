import { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { MdOutlineFileDownload } from "react-icons/md";
import { TbRotateClockwise2 } from "react-icons/tb";
import { getNameFromUrl, pollApi } from "src/common/utils/utils";
import { toPublic } from "src/common/services/generate";
import NoBg from "../common/no-bg";
import EnhanceVid from "../common/enhance";
import { handleDownload } from "src/common/utils/utils";

const More = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const ref = useRef(null);
    const { video, vtype } = props;
    const [isEnchanced, setIsEnchanced] = useState("Enhance");

    useEffect(() => {
        //   handleEnhance({ opr: "check" });
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            // event.stopPropagation();
            if (ref.current && !ref.current.contains(event.target)) {
                props.OnClose(true);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);



    const handlePublic = async ({ id, value }) => {
        try {
            const pub_res = await toPublic({ id, value });
            //console.log(pub_res);
        } catch (error) {
            //console.log(error);
        }

        if(value === "false"){
            navigate("/admin/generated-videos", { state: { reload: true } });
        }else if(value === "true"){
            navigate("/admin/hot", { state: { reload: true } });
        }

        
    };


    ///COMPONENTS

    return (
        <div className="more-box absolute top-0 left-0 w-full h-full z-30 slideFromLeftFast">
            <div
                ref={ref}
                className={`absolute ${
                    props.position ? props.position : "bottom"
                }-4 py-4 min-w-52 right-4 bg-white shadow-lg shadow-indigo-600/10 border border-zinc-500/20 rounded-xl z-20 flex flex-col gap-2  !w-64`}
            >
                {video.result !== "" && (
                    <>
                        {vtype !== "public" && (    
                            <>
                            <div className="flex flex-col border-b border-indigo-500/20 pb-3 flex-1">
                                <EnhanceVid video={video} />
                                </div>
                                <div className="flex flex-col border-b border-indigo-500/20 pb-3 flex-1">
                                    <NoBg video={video} />
                                </div>
                            </>
                        )}
                        <div
                            className="px-6 py-1 transition cursor-pointer hover:opacity-70 hover:translate-x-0.5"
                            onClick={() => {
                                const thevideo = { ...video };
                                delete thevideo.id;
                                delete thevideo.createdate;
                                delete thevideo.result;
                                delete thevideo.state;
                                thevideo.name = `Copy of ${video.name}`;
                                // thevideo.type = "regenerate";
                                // navigate(`${location.pathname}`, { state: { show: "create-video", data: thevideo } });
                                navigate("/admin/generate-video", { state: { todo: "regen", data: thevideo } });
                            }}
                        >
                            {vtype === "public" ? "Duplicate" : "Edit"}
                        </div>

                        {vtype === "public" && video.owner && (
                            <div
                                className="px-6 py-1 transition cursor-pointer hover:opacity-70 hover:translate-x-0.5"
                                onClick={async () => {
                                    await handlePublic({ id: video.id, value: "false" });
                                }}
                            >
                                Set To Private
                            </div>
                        )}

                        {!vtype && (
                            <div
                                className="px-6 py-1 transition cursor-pointer hover:opacity-70 hover:translate-x-0.5"
                                onClick={async () => {
                                    await handlePublic({ id: video.id, value: "true" });
                                }}
                            >
                                Set To Public
                            </div>
                        )}
                        <div
                            className="px-6 py-1 transition cursor-pointer hover:opacity-70 hover:translate-x-0.5 hover:text-black"
                            onClick={() => handleDownload(video.result, getNameFromUrl(video.result))}
                        >
                            Download
                        </div>
                    </>
                )}

                {(vtype !== "public" || (vtype === "public" && video.owner)) && (
                    <div
                        className="px-6 transition cursor-pointer hover:opacity-70 hover:translate-x-0.5 text-rose-500"
                        onClick={() => {
                            const thevideo = { ...video };
                            thevideo.TYPE = "gen";
                            navigate(`${location.pathname}`, { state: { show: "delete", data: thevideo } });
                            // props.OnDelete(video);
                        }}
                    >
                        Delete
                    </div>
                )}
            </div>
        </div>
    );
};

export default More;
