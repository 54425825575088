import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import AdminLayout from "src/layouts/admin-layout";
import Artboard from "src/modules/admin/studio/artboard/artboard";
import Sidebar from "src/modules/admin/studio/sidebar/sidebar";
import Scenes from "src/modules/admin/studio/scene/scenes";
import { LuRectangleHorizontal } from "react-icons/lu";
import { LuRectangleVertical } from "react-icons/lu";
import { LuSquare } from "react-icons/lu";

import SidebarDetail from "src/modules/admin/studio/sidebar-detail/sidebar-detail";
import StepAdd from "src/modules/admin/studio/step/step-add";
import InputSelect from "src/modules/admin/studio/input/input-select";
import Preloader from "src/common/components/preloader/preloader";
import Popup from "src/common/components/popup/popup";
import { generateScene } from "src/modules/admin/studio/studio-funcs";
import StageContainer from "src/modules/admin/studio/artboard/stage";

const Studio = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [fetching, setFetching] = useState(false);
    const [showStepAdd, setShowStepAdd] = useState(false);
    const [showInputAdd, setShowInputAdd] = useState(false);
    const screens = [
        {
            value: "16:9",
            label: "Desktop",
            icon: <LuRectangleHorizontal className="text-lg" />,
        },
        {
            value: "9:16",
            label: "Mobile",
            icon: <LuRectangleVertical className="text-lg" />,
        },
        {
            value: "4:3",
            label: "Square",
            icon: <LuSquare className="text-lg" />,
        },
    ];

    const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
    const [selectedScene, setSelectedScene] = useState(null);
    const [selectedDetail, setSelectedDetail] = useState(null);

    // imageurl, videourl, audiourl, imageid, videoid, voiceid, voicegender
    const [project, setProject] = useState({
        id: uuidv4(),
        name: "Project 1",
        proportion: "16:9",
        width: 1280,
        height: 720,
        scenes: [],
        result: {
            //url: `https://svc.staging.squadz.live/download/OA0B346fe4abc/OA16nE19d822f/OA5Cuw44d5d46/6962336hd_1280_720_25fps_result.mp4`,
            url: null,
            show: false,
        },
    });

    // Add these refs before the useEffect
    const prevProjectRef = useRef(project);
    const prevSelectedSceneRef = useRef(selectedScene);

    useEffect(() => {
        if (!selectedScene || !project) return;
        console.log('selectedScene ::', selectedScene)
        // Sync result from project to selectedScene
        const currentSceneResult = project.scenes.find(scene => scene.id === selectedScene.id)?.result;
        if (currentSceneResult !== selectedScene.result) {
            setSelectedScene(prev => ({
                ...prev,
                result: currentSceneResult,
            }));
            return; 
        }

        // Determine update source and sync accordingly
        const existingScene = project.scenes.find(scene => scene.id === selectedScene.id);
        if (!existingScene) return;

        if (prevSelectedSceneRef.current !== selectedScene) {
            prevSelectedSceneRef.current = selectedScene;
            if (JSON.stringify(existingScene) !== JSON.stringify(selectedScene)) {
                setProject(prev => ({
                    ...prev,
                    scenes: prev.scenes.map(scene => 
                        scene.id === selectedScene.id ? selectedScene : scene
                    ),
                }));
            }
        } else if (prevProjectRef.current !== project) {
            // Update originated from project
            prevProjectRef.current = project;
            if (JSON.stringify(existingScene) !== JSON.stringify(selectedScene)) {
                setSelectedScene(existingScene);
            }
        }
    }, [selectedScene, project]); // Simplified dependency array

    useEffect(() => {
        console.log("selectedDetail :: ", selectedDetail);
        if (!selectedScene || !project || !selectedDetail) return;
        setSelectedScene((prev) => ({
            ...prev,
            [selectedDetail.detailType]: prev[selectedDetail.detailType].map((detail) => (detail.id === selectedDetail?.id ? selectedDetail : detail)),
        }));
    }, [selectedDetail]);

    useEffect(() => {
        // console.log("project :: ", project);
    }, [project]);

    return (
        <AdminLayout type="full">
            {fetching && <Preloader text={`Generating your video`} />}
            {showStepAdd && (
                <Popup
                    OnHide={() => {
                        setShowStepAdd(false);
                    }}
                >
                    <StepAdd
                        setSelectedDetail={setSelectedDetail}
                        selectedScene={selectedScene}
                        setSelectedScene={setSelectedScene}
                        setShowStepAdd={setShowStepAdd}
                    />
                </Popup>
            )}

            {showInputAdd && (
                <Popup
                    OnHide={() => {
                        setShowInputAdd(false);
                    }}
                >
                    <InputSelect setSelectedDetail={setSelectedDetail} setSelectedScene={setSelectedScene} setShowInputAdd={setShowInputAdd} />
                </Popup>
            )}
            <div className="studio-container absolute left-0 top-0 h-full w-full flex-1 flex flex-row gap-4 relative overflow-hidden">
                <Sidebar
                    screens={screens}
                    project={project}


                    setProject={setProject}
                    selectedScene={selectedScene}
                    setSelectedScene={setSelectedScene}
                    selectedDetail={selectedDetail}
                    setSelectedDetail={setSelectedDetail}
                    setShowStepAdd={setShowStepAdd}
                    setShowInputAdd={setShowInputAdd}
                    stageSize={stageSize}
                />
                <div className="main-container flex-1 flex flex-col gap-4">
                    <div className="artboard-container bg-dots bg-indigo-300/10  rounded-2xl border border-lightblue100  flex-1  flex flex-row gap-4 p-4">
                        {selectedDetail && Object.keys(selectedDetail.params).length > 0 && (
                            <SidebarDetail
                                project={project}
                                setProject={setProject}
                                stageSize={stageSize}
                                setStageSize={setStageSize}
                                selectedDetail={selectedDetail}
                                setSelectedDetail={setSelectedDetail}
                                selectedScene={selectedScene}
                                setSelectedScene={setSelectedScene}

                                setShowInputAdd={setShowInputAdd}
                            />
                        )}
                        <Artboard
                            project={project}
                            setProject={setProject}
                            selectedDetail={selectedDetail}
                            setSelectedDetail={setSelectedDetail}
                            selectedScene={selectedScene}
                            setSelectedScene={setSelectedScene}
                            stageSize={stageSize}
                            setStageSize={setStageSize}
                        />
                    </div>
                    <Scenes
                        project={project}
                        setProject={setProject}
                        selectedScene={selectedScene}
                        setSelectedScene={setSelectedScene}
                        selectedDetail={selectedDetail}
                        setSelectedDetail={setSelectedDetail}
                        stageSize={stageSize}
                    />
                </div>
            </div>
        </AdminLayout>
    );
};

export default Studio;
