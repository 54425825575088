import { useState, useEffect } from "react";
import Preloader from "src/common/components/preloader/preloader";
import { upload } from "src/common/services/utils";
import UiSelect from "../../components/ui-select";
import { IoIosArrowBack } from "react-icons/io";
import { effects } from "../data-options";
import UiDSection from "../../components/ui-dsection";
const Photo = (props) => {
    const { data, scene, photo } = props;
    const [fetching, setFetching] = useState(false);
    const [freeDuration, setFreeDuration] = useState(0);

    useEffect(() => {
        let maxDuration = scene.end - scene.start;

        let siblingsDuration = 0;
        scene.photos.forEach((p) => {
            //if(p.id !== photo.id){
            siblingsDuration = siblingsDuration + p.duration;
            // }
        });
        let freeDur = maxDuration - siblingsDuration
        //console.log('free duration::', freeDur)
        setFreeDuration(freeDur);
    }, [scene, photo]);

    const handleUpload = async (file) => {
        setFetching(true);
        try {
            const res = await upload({ file });
            //console.log("upload res :: ", res);
            if (res.data[0]?.cache) {
                photo.image = res.data[0]?.cache
                
                props.OnSuccess(res.data[0]?.cache);
            }
        } catch (error) {
            //console.log("upload error :: ", error);
        }
        setFetching(false);
    };

    return (
        <div
            className={`transiton-all bg-white flex flex-col min-w-full max-w-full min-h-full max-h-screen overflow-hidden absolute left-0 top-0 h-full w-full z-40 slideFromLeftFast`}
        >
             {fetching && (
                        <div className="absolute w-full h-full z-50 left-0 top-0 bg-white/80 flex items-center justify-center">
                            <Preloader type={`inline`} />
                        </div>
                    )}
            <div className="d-section p-6 border-b border-lightblue100 flex flex-col gap-4">
                <div
                    className="cursor-pointer flex gap-2 items-center "
                    onClick={() => {
                        props.OnClose(true);
                    }}
                >
                    <IoIosArrowBack /> {scene.scene}
                </div>
            </div>
            <UiDSection title={`Image`}>
                <div className="flex-1 flex w-full max-h-52 min-h-52 relative overflow-hidden rounded-2xl bg-lightblue100">
                   
                    <img src={photo.image} className="object-contain w-full h-full" />
                    <div className="transition-all absolute left-0 top-0 w-full h-full bg-lightblue100/50 flex items-center justify-center opacity-0 hover:opacity-100 cursor-pointer">
                        <button className="button button-small button-white px-4 text-xs">change</button>
                        <input
                            type="file"
                            title=""
                            alt=""
                            accept={`image/*`}
                            className="absolute z-30 left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                            onChange={async (e) => {
                                //console.log(e.target.files[0]);
                                await handleUpload(e.target.files[0]);
                            }}
                        />
                    </div>
                </div>
            </UiDSection>
            <UiDSection title={`Effects`}>
                <UiSelect data={effects} value={photo.effect} OnSet={(effect)=>{
                    photo.effect = effect
                    props.OnSet(photo)
                }} />
            </UiDSection>

            <UiDSection title={`Settings`}>
                <div className="flex-1 flex flex-row items-center justify-between gap-4">
                    <div className="flex w-1/2">
                        Duration <span className="opacity-40 pl-1">(sec)</span>
                    </div>

                    <div className="flex flex-row items-center gap-3 w-1/4 ">
                        <div className={`flex gap-1 items-center p-2 rounded-xl relative overflow-hidden outline-none border border-lightblue100`}>
                            <input
                                type="number"
                                min={0}
                                max={freeDuration}
                                className="w-full flex-1 outline-none text-center"
                                value={photo.duration}
                                onChange={(e) => {
                                    photo.duration = parseInt(e.target.value);
                                    props.OnSet({ ...photo });
                                }}
                            />
                            <span className="opacity-40 text-xs">sec</span>
                        </div>
                    </div>
                </div>
            </UiDSection>
        </div>
    );
};

export default Photo;
