import { useEffect, useState } from "react";

import CardImage from "../../inputed-images/card-image";
import CardVideo from "../../inputed-videos/card-video";
import { getVideo } from "src/common/services/generate";
import { getInputImage, getInputVideo } from "src/common/services/input";
import CardAudio from "../../voices/card-audio";
import { getVoice } from "src/common/services/voices";
import { TbAlertCircle } from "react-icons/tb";
import { captureFirstFrame } from "src/common/utils/utils";
import { LiaPhotoVideoSolid } from "react-icons/lia";
import { BiUserVoice } from "react-icons/bi";
import { TbScript } from "react-icons/tb";
import { TbPhotoVideo } from "react-icons/tb";
import { MdOutlineRecordVoiceOver } from "react-icons/md";

import Preloader from "src/common/components/preloader/preloader";
import CoinCard from "../../coins/coin-card";

const SummaryGenerate = (props) => {
    const { genData, voiceTab } = props;
    const [video, setVideo] = useState();
    const [image, setImage] = useState();

    const [voice, setVoice] = useState();
    const [script, setScript] = useState();
    const [fetching, setFetching] = useState(false);

    const regexName = /[^a-zA-Z0-9_\s]/g;

    useEffect(() => {
        // Normalize name
        genData.name = genData.name.replace(regexName, "");

        // VIDEO
        const handleVideoInput = () => {
            // Reset video and image states

            if (genData.videoid) {
                setFetching(true);
                getInputVideo({ id: genData.videoid }).then((res) => {
                    if (res[0]) {
                        setVideo({ ...res[0] });
                        setImage(null);
                        setFetching(false);
                    }
                });
            } else if (genData.videourl) {
                setVideo({ url: genData.videourl });
                setImage(null);
            } else if (genData.file?.type.includes("video")) {
                setVideo({
                    name: genData.file.name,
                    url: URL.createObjectURL(genData.file),
                });
                setImage(null);
            } else {
                setVideo(null);
            }
        };

        // IMAGE
        const handleImageInput = () => {
            if (genData.imageid) {
                setFetching(true);
                getInputImage({ id: genData.imageid }).then((res) => {
                    if (res[0]) {
                        setImage(res[0]);
                        setFetching(false);
                        setVideo(null);
                    }
                });
            } else if (genData.imageurl) {
                setImage({ url: genData.imageurl });
                setVideo(null);
            } else if (genData.file?.type.includes("image")) {
                setImage({
                    name: genData.file.name,
                    url: URL.createObjectURL(genData.file),
                });
                setVideo(null);
            } else {
                setImage(null);
            }
        };

        // VOICE
        const handleVoiceInput = () => {
            if (genData.voiceid) {
                getVoice({ voiceid: genData.voiceid }).then((res) => {
                    if (res[0]) {
                        setVoice(res[0]);
                    }
                });
            } else if (genData.audiourl) {
                setVoice({ audiourl: genData.audiourl });
            } else if (genData.audio) {
                setVoice({
                    name: genData.audio.name,
                    url: URL.createObjectURL(genData.audio),
                });
            } else {
                setVoice(null);
            }
        };

        // Execute the input handlers
        handleVideoInput();
        handleImageInput();
        handleVoiceInput();
    }, [genData.videoid, genData.videourl, genData.file, genData.imageid, genData.imageurl, genData.voiceid, genData.audiourl, genData.audio]);

    const canSubmit = () => {
        let can = false;
        if (
            // Media input check
            (genData.file || genData.videoid || genData.videourl || genData.imageid || genData.imageurl) &&
            // Voice input check
            (genData.audio || genData.voiceid || genData.audiourl || genData.voicegender) &&
            // Name check
            genData.name !== "" &&
            // Text requirement based on voiceTab
            (voiceTab === "direct" || (genData.text && genData.text !== ""))
        ) {
            can = true;
        }

        return can;
    };

    return (
        <div className="hidden md:flex min-w-400 max-w-400 relative flex ">
            <div className=" min-w-400 max-w-400 flex flex-col gap-5 bg-lightblue300/20 p-5 pb-0 border border-lightblue100 rounded-3xl">
                <div className="uppercase text-xs opacity-40">Generate Video</div>
                <div className="flex-1 flex flex-col gap-3">
                    <div className="bg-white border-4 border-lightblue100/80 rounded-3xl p-6 flex flex-col gap-2">
                        <div className="uppercase text-xs flex items-center justify-between min-h-8">
                            <div className="opacity-40"> Name:</div>
                            {/* {genData.name === "" && <TbAlertCircle className="text-lg text-red-500 translate-x-3 animate-bounce" />} */}
                        </div>

                        <input
                            type={"text"}
                            className="!p-0 border-b border-lightblue100 !pb-3"
                            placeholder={"Add name for your video..."}
                            value={genData.name}
                            onChange={(e) => {
                                const allowedPattern = /^[a-zA-Z0-9_\s]*$/;

                                // If the input value does not match the allowed pattern, remove the last character
                                if (!allowedPattern.test(e.target.value)) {
                                    e.target.value = e.target.value.replace(regexName, "");
                                }

                                genData.name = e.target.value;
                                props.OnSet({ ...genData });
                            }}
                        />

                        <div className="flex gap-2 items-center text-xs opacity-40">Special characters are not allowed.</div>
                    </div>

                    {/* EMPTY */}
                    {!video && !image && (
                        <div
                            className={`transition p-2 bg-lightblue100/50 h-300 border border-dashed border-blue rounded-2xl p-5 text-center flex flex-col gap-2 items-center justify-center cursor-pointer relative overflow-hidden ${
                                props.selectedTimeline?.id === "add-video" ? "opacity-100" : "opacity-40 hover:opacity-100 "
                            }`}
                            onClick={() => {
                                props.OnSelectTimeline(0);
                            }}
                        >
                            {fetching && <Preloader />}
                            <TbPhotoVideo className="text-xl text-indigo-500/80" />
                            <div className="text-indigo-500/80">Select a Video / Image</div>
                        </div>
                    )}
                    {/* SELECTED */}
                    {video && (
                        <div className="relative overflow-hidden">
                            <CardVideo
                                thumbsize={`300`}
                                thumbfit={`contain`}
                                video={video}
                                for={`thumbnail`}
                                actions={
                                    <button
                                        className="button button-small px-4 button-bordered "
                                        onClick={() => {
                                            props.OnChange("video");
                                        }}
                                    >
                                        change
                                    </button>
                                }
                            />
                        </div>
                    )}
                    {image && (
                        <div className="relative overflow-hidden">
                            <CardImage
                                thumbsize={`300`}
                                thumbfit={`contain`}
                                image={image}
                                for={`thumbnail`}
                                actions={
                                    <button
                                        className="button button-small px-4 button-bordered "
                                        onClick={() => {
                                            props.OnChange("video");
                                        }}
                                    >
                                        change
                                    </button>
                                }
                            />
                        </div>
                    )}

                    {/* EMPTY */}
                    {!voice && (
                        <div
                            className={`transition p-2 bg-lightblue100/50 h-36 border border-dashed border-blue rounded-2xl p-5 text-center flex flex-col gap-2 items-center justify-center cursor-pointer  ${
                                props.selectedTimeline?.id === "add-voice" ? "opacity-100" : "opacity-40 hover:opacity-100 "
                            }`}
                            onClick={() => {
                                props.OnSelectTimeline(1);
                            }}
                        >
                            <MdOutlineRecordVoiceOver className="text-xl text-indigo-500/80" />
                            <div className="text-indigo-500/80">Select a Voice</div>
                        </div>
                    )}
                    {/* SELECTED */}
                    {voice && (
                        <CardAudio
                            for={`thumbnail`}
                            voice={voice}
                            actions={
                                <button
                                    className="button button-small px-4 button-bordered "
                                    onClick={() => {
                                        props.OnChange("voice");
                                    }}
                                >
                                    change
                                </button>
                            }
                        />
                    )}

                    {/* EMPTY */}
                    {!genData.text && voiceTab !== "direct" && (
                        <div
                            className={`transition p-2 bg-lightblue100/50  min-h-52 max-h-52 border border-dashed border-blue rounded-2xl p-5 text-center flex-1 flex flex-col gap-2  items-center justify-center cursor-pointer  ${
                                props.selectedTimeline?.id === "add-text" ? "opacity-100" : "opacity-40 hover:opacity-100 "
                            }`}
                            onClick={() => {
                                props.OnSelectTimeline(2);
                            }}
                        >
                            <TbScript className="text-xl text-indigo-500/80" />
                            <div className="text-indigo-500/80">Add your script</div>
                        </div>
                    )}

                    {/* SELECTED */}
                    {genData.text && voiceTab !== "direct" && (
                        <div
                            className="bg-white border-4 border-lightblue100/80  min-h-52 max-h-52 rounded-3xl p-6  flex-1 flex flex-col gap-2 cursor-pointer "
                            onClick={() => {
                                props.OnSelectTimeline(2);
                            }}
                        >
                            <div className="opacity-40"> Script:</div>
                            <div className="relative overflow-hidden overflow-y-auto">{genData.text}</div>
                        </div>
                    )}
                </div>
                <div className="flex flex-col gap-3 sticky bottom-0 pb-5 z-30">
                    <button
                        disabled={!canSubmit()}
                        className="button flex-1 flex items-center justify-between gap-1"
                        onClick={() => {
                            if (canSubmit()) {
                                props.OnGenerate(true);
                            }
                        }}
                    >
                        Generate Video
                        <div className="bg-indigo-500/80 rounded-full px-1 py-1  pr-3 flex items-center gap-1 text-xs">
                            <CoinCard coins={"5"} />
                        </div>
                        {/* <CoinCard type={"price"} coins={5} /> */}
                    </button>

                    {/* <div className="flex items-center gap-1 justify-center text-xs">
                        <CoinCard coins={"5"} /> per generate
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default SummaryGenerate;
