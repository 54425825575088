import VideoPlayer from "src/common/components/video-player/video-player";
import { checkFileType } from "src/common/utils/utils";
import { IoClose } from "react-icons/io5";
import { BiErrorAlt } from "react-icons/bi";
import { useState } from "react";
import CardAudio from "src/modules/admin/voices/card-audio";

const AudioUrl = (props) => {
    const { genData } = props;
    const [val, setVal] = useState(genData.audiourl || "");
    const [error, setError] = useState("");
    const [checking, setChecking] = useState(false);
    const [displayedUrl, setDisplayedUrl] = useState(genData.audiourl || "");

    const checkMediaUrl = (url) => {
        return new Promise((resolve, reject) => {
            const fileType = checkFileType(url);

            if (fileType === "video") {
                const video = document.createElement("video");
                video.onloadedmetadata = () => resolve(true);
                video.onerror = () => reject(false);
                video.src = url;
            } else if (fileType === "audio") {
                const audio = document.createElement("audio");
                audio.onloadedmetadata = () => resolve(true);
                audio.onerror = () => reject(false);
                audio.src = url;
            } else {
                reject(false);
            }
        });
    };

    const handleSubmit = async () => {
        setError("");
        setDisplayedUrl("");
        setChecking(true);
        const fileType = checkFileType(val);

        try {
            if (fileType === "audio" || fileType === "video") {
                await checkMediaUrl(val);
                delete genData.voiceid;
                delete genData.voicegender;
                genData.audiourl = val;
            } else {
                throw new Error("Invalid file type");
            }

            setDisplayedUrl(val);
        } catch (err) {
            setError(fileType === "audio" ? "Invalid audio URL" : fileType === "video" ? "Invalid video URL" : "Invalid URL");
            setDisplayedUrl("");
            delete genData.voiceid;
            delete genData.voicegender;
            delete genData.audiourl;
        }

        setChecking(false);
        props.OnSet({ ...genData });
    };

    return (
        <div className="flex flex-1 flex-col gap-4 py-2">
            <div className="flex flex-col gap-4">
                <div className="font-semibold">Enter a url to an audio or video.</div>
                <div className="flex gap-2">
                    <div className="flex-grow border border-lightblue100 bg-lightblue50 rounded-2xl flex-1 flex items-center justify-between p-2">
                        <input
                            type="text"
                            value={val}
                            placeholder="Add an audio or video url"
                            className="w-full p-2"
                            onChange={(e) => setVal(e.target.value)}
                        />
                        <div
                            className="px-3 cursor-pointer opacity-50 hover:opacity-100"
                            onClick={() => {
                                setVal("");
                                setError("");
                                setDisplayedUrl("");
                                delete genData.voiceid;
                                delete genData.voicegender;
                                delete genData.audiourl;
                                props.OnSet({ ...genData });
                            }}
                        >
                            <IoClose className="text-lg" />
                        </div>
                    </div>
                    <button className="button bg-blue text-white rounded-2xl" onClick={handleSubmit}>
                        Check
                    </button>
                </div>
            </div>

            <div className="flex-1 flex flex-col gap-4 bg-lightblue100/40 border border-lightblue100 rounded-2xl items-center justify-center p-4">
                {checking && <div className="text-indigo-500/60 text-center loading">Please wait. Checking</div>}
                {!error && displayedUrl === "" && val === "" && <div className="text-indigo-500/40 text-center">Your audio will be displayed here</div>}
                {error && (
                    <div className="text-red-500 text-center flex flex-col items-center gap-5 bg-white rounded-2xl p-10 pb-5 shadow-xl shadow-indigo-300/10">
                        <BiErrorAlt className="text-2xl" />
                        {error}
                    </div>
                )}
                {displayedUrl && checkFileType(displayedUrl) && (
                    <div className="min-w-400 max-h-400 min-h-400 flex flex-col items-center justify-center p-4 gap-4">
                        <CardAudio
                            className={`w-full`}
                            voice={genData}
                            for={`url`}
                            actions={() => (
                                <button className="button button-small px-4 button-bordered" onClick={() => {}}>
                                    change
                                </button>
                            )}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default AudioUrl;
