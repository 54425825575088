import UiSelect from "src/modules/admin/studio/components/ui-select";
import RangeSlider from "./range-slider";
import UiDSection from "../../components/ui-dsection";

const Effect = ({ selectedDetail, setSelectedDetail }) => {
    const handleChange = (key, value) => {
        setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, [key]: value } }));
    };

    return (
        <div className="flex flex-col gap-2">
            {Object.entries(selectedDetail.params).map(([key, value]) => {
                let min = 1;
                let max = 10;
                let step = 0.01;

                if (selectedDetail.params?.range) {
                    const range = JSON.parse(selectedDetail.params.range);

                    min = range[0] !== undefined ? parseFloat(range[0]) : 1;
                    max = range[1] !== undefined ? parseFloat(range[1]) : 10;
                    step = (max - min) / 20;

                    console.log(min, max, step);
                }

                return (
                    <>
                        {key === "option" && (
                            <UiDSection key={key}>
                                <UiSelect options={["in", "out"]} value={selectedDetail.params.option} onChange={(val) => handleChange(key, val)} />
                            </UiDSection>
                        )}

                        {(key === "duration" || key === "strength") && (
                            <UiDSection key={key}>
                                <RangeSlider
                                    title={key}
                                    initialValue={selectedDetail.params[key]}
                                    min={min}
                                    max={max}
                                    step={step}
                                    OnValue={(val) => handleChange(key, val)}
                                />
                            </UiDSection>
                        )}
                    </>
                );
            })}
        </div>
    );
};

export default Effect;
