import { useEffect, useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ReactLenis, useLenis } from "lenis/react";

import Header from "src/layouts/marketing/header";
import AdminLogin from "src/modules/admin/admin-login";
import MarketingLayout from "src/layouts/marketing/marketing-layout";
import { animateTitle } from "./animation/common";
import { getStoredUser } from "src/common/components/auth/auth";
import { useNavigate } from "react-router-dom";
import MarketingLayoutMinimal from "src/layouts/marketing/marketing-layout-minimal";
gsap.registerPlugin(useGSAP);

const Login = () => {
    const navigate = useNavigate();

    const main = useRef();
    const lenis = useLenis((data) => {});

    useEffect(() => {
        if (getStoredUser() && getStoredUser().id) {
            navigate("/admin/");
        }
    }, []);

    useGSAP(
        () => {
            animateTitle({ trigger: ".section-login .g-title", scrub: false });

            gsap.from(".section-login", {
                y: "100px",
                opacity: 0,
            });
        },
        { scope: main }
    );

    return (
        <MarketingLayoutMinimal>
            <div className="flex-1 flex" ref={main}>
                <div className="section-login section flex w-full relative items-center justify-center z-30 px-6 ">
                    <div className="container max-w-400 flex-1 flex justify-center items-start ">
                        <div className="flex flex-col text-center gap-10 md:gap-20  p-5 md:p-0">
                            <div className="g-title flex flex-col gap-6 mb-10 md:mb-0">
                                <div className=" text-4xl md:text-7xl leading-tighter tracking-tighter font-semibold">
                                    <div>Sign up or Log in.</div>
                                </div>

                                <div className="subtitle text-base md:text-lg opacity-50 px-10 md:px-0">
                                    Enjoy exclusive features and many more.
                                </div>
                            </div>
                            <AdminLogin />
                        </div>
                    </div>
                </div>
            </div>
        </MarketingLayoutMinimal>
    );
};

export default Login;
