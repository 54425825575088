import Canvas from "./editor/canvas";
import Timeline from "./editor/timeline";
import { IoIosArrowBack } from "react-icons/io";
import { mockdata } from "./editor/mockdata";
import { useEffect, useState } from "react";
import "./editor.css";
import Scene from "./editor/sidebar/scene";
import OpeningPage from "./editor/sidebar/opening-page";
import Dbox from "src/common/components/dbox/dbox";
import ClosingPage from "./editor/sidebar/closing-page";

const Editor = (props) => {
    const [data, setData] = useState();
    const [selectedScene, setSelectedScene] = useState(null);

    useEffect(() => {
        setData(mockdata);
    }, []);


    useEffect(() => {
        ////console.log('selectedScene ::' , selectedScene);

        if (selectedScene) {
            //console.log('selectedScene ::' , selectedScene);
            switch (selectedScene.type) {

                
                case "scene":
                    //console.log('11');
                    const updatedScenes = data.scenes.map((scene) => (scene.id === selectedScene.id ? { ...selectedScene } : { ...scene }));
                    setData({
                        ...data,
                        scenes: updatedScenes,
                    });
                    break;

                case "opening-page":
                    setData({
                        ...data,
                        opening_page: selectedScene,
                    });
                    break;
                case "closing-page":
                    setData({
                        ...data,
                        closing_page: selectedScene,
                    });
                    break;    
                default:
                    break;
            }
        }
    }, [selectedScene]);

    return (
        <div className="editor-container absolute left-0 top-0 w-full h-screen flex flex-col p-6 gap-3 overflow-hidden">
            <div className="editor-header flex items-center ">
                <IoIosArrowBack /> Back
            </div>
            {data && (
                <div className="flex-1 flex flex-row gap-3 relative w-full h-screen  overflow-hidden">
                    <Dbox className={`flex flex-col min-w-96 max-w-96 min-h-full max-h-screen relative overflow-hidden`}>
                        <div className=" min-h-full relative overflow-auto">
                            {selectedScene && selectedScene.type === "scene" && (
                                <Scene
                                    data={data}
                                    scene={selectedScene}
                                    OnSet={(scene) => {
                                       setSelectedScene(scene);
                                    }}
                                />


                            )}

                            {selectedScene && selectedScene.type === "opening-page" && (
                                <OpeningPage
                                    data={data}
                                    scene={selectedScene}
                                    OnSet={(scene) => {
                                        setSelectedScene(scene);
                                    }}
                                />
                            )}

                            {selectedScene && selectedScene.type === "closing-page" && (
                                <ClosingPage
                                    data={data}
                                    scene={selectedScene}
                                    OnSet={(scene) => {
                                        setSelectedScene(scene);
                                    }}
                                />
                            )}
                        </div>
                    </Dbox>

                  

                    <div className="flex-1 flex flex-col gap-3 justify-between">
                        <Canvas selected={selectedScene} />
                        <Timeline
                            data={data}
                            selected={selectedScene}
                            OnSelect={(scene) => {
                                scene.FROM_TIMELINE = true
                                setSelectedScene({...scene});
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Editor;
