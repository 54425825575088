import axios from "axios";
import endpoint from "src/common/services/endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleRes, handleErr } from "src/common/services/response";

// QUERIES

export const getVoices = async ({ pageNum }) => {
    const token = getStoredUser()?.token;

    pageNum = pageNum || 1;
    const config = {
        method: "get",
        url: `${endpoint.rest}voice/all?pagenumber=${pageNum}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};



export const getVoice = async ({ voiceid }) => {
    const token = getStoredUser()?.token;

    const config = {
        method: "get",
        url: `${endpoint.rest}voice/${voiceid}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const getVoicesDefault = async () => {
    const token = getStoredUser()?.token;

    const config = {
        method: "get",
        url: `${endpoint.rest}voice/default`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

// MUTATIONS

export const createVoice = async ({ voicename, voicedescription, fileURL }) => {
    const token = getStoredUser()?.token;
    if (voicename && voicedescription && fileURL) {
        const config = {
            method: "post",
            url: `${endpoint.rest}voice`,
            data: {
               
                voicename,
                voicedescription,
                fileURL,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`,
            },
        };

        try {
            const res = await axios(config);
            return handleRes({ res, config });
        } catch (error) {
            handleErr({ error, config });
        }
    }
};

export const deleteVoice = async ({ voiceid }) => {
    const token = getStoredUser()?.token;

    const config = {
        method: "delete",
        url: `${endpoint.rest}voice/${voiceid}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({ res, config });
    } catch (error) {
        handleErr({ error, config });
    }
};

export const textToSpeech = async ({ voiceid, text }) => {
    const token = getStoredUser()?.token;
    if (voiceid && text) {
        const config = {
            method: "post",
            url: `${endpoint.rest}speech`,
            data: {
                auth: token,
                voiceid,
                text,
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `${token}`,
            },
        };

        try {
            const res = await axios(config);
            return handleRes({ res, config });
        } catch (error) {
            handleErr({ error, config });
        }
    }
};
