import { chain } from "src/common/services/studio";
import { upload } from "src/common/services/utils";
import { getVideo } from "src/common/services/generate";

export const generateScene = async ({ project, scene, stageSize }) => {
    // const isNobg = theVideo.avatar.effects.find((effect) => effect.type === "nobg");
    // const nobg = isNobg?.value === true ? "/nobg.mp4" : "";

    // const isEnhance = theVideo.avatar.effects.find((effect) => effect.type === "enhance");
    // const enhance = isEnhance?.value === true ? "/upscaled.mp4" : "";

    const sampleOverlayData = {
        canvas: {
            width: 1280,
            height: 720,
        },
        st: [
            {
                index: 1,
                action: "overlay-video",
                type: "video",
                params: {   
                    src: "https://svc.staging.squadz.live/download/OA0BH400fcf71/OA0BH400fcf71/OAACBB7b63711/bella_drive_square2.mp4",
                    x: 0,
                    y: 0,
                    width: 1280,
                    height: 720,
                },
            },
            {
                index: 3,
                action: "zoom",
                type: "effect",
                params: {
                    option: "out",
                    duration: 1,
                },
            },
        ],
    };

    try {
        const overlayData = {
            canvas: {
                width: parseInt(project.width),
                height: parseInt(project.height),
            },

            steps: [],
        };


        if (scene.background_base64) {
            const theBackgroundLayer =   {
                index: 1,
                action: "overlay-image",
               // type: "image",
                type: "base64",

                params: {
                    //src: "https://svc.staging.squadz.live/download/1/1/O99B1X94837ed/3.mp4/frame1.jpg",
                    src: scene.background_base64,
                    x: 0,
                    y: 0,
                    width: parseInt(project.width),
                    height: parseInt(project.height),
                },
            };
            overlayData.steps = [...overlayData.steps, theBackgroundLayer];
        }

        if (scene.inputs[0]) {
            const input_real_val = getRealVal({
                stageSize,
                project,
                scaledX: scene.inputs[0].params.x,
                scaledY: scene.inputs[0].params.y,
                scaledWidth: scene.inputs[0].params.width,
                scaledHeight: scene.inputs[0].params.height,
            });
            const theinputlayer = {
                index: 2,
                action: "overlay-video",
                //avatarid: theVideo.avatar.video.id,
                type: "video",
                params: {
                    src: `${scene.inputs[0].params.src}`,
                    x: Math.round(input_real_val.x),
                    y: Math.round(input_real_val.y),
                    width: Math.round(input_real_val.width),
                    height: Math.round(input_real_val.height),
                },
            };

            if (scene.inputs[0].params?.file) {
                try {
                    const input_upload = await upload({ file: scene.inputs[0].params.file });
                    console.log("input_upload :: ", input_upload);
                    theinputlayer.params.src = input_upload[0].cache;
                } catch (error) {
                    console.error("Failed to process input file:", error);
                }
            }

            overlayData.steps = [...overlayData.steps, theinputlayer];
        }

        for (const step of scene.steps) {
            if (step.params.file) {
                try {
                    const upload_res = await upload({ file: step.params.file });
                    step.params.src = upload_res[0].cache;
                } catch (error) {
                    console.error("Failed to process image file:", error);
                }
            }

            const step_real_val = getRealVal({
                stageSize,
                project,
                scaledX: step.params.x,
                scaledY: step.params.y,
                scaledWidth: step.params.width,
                scaledHeight: step.params.height,
            });

            switch (step.type) {
                case "text":
                    const thetextlayer = {
                        index: overlayData.steps.length + 1,
                        action: "overlay-image",
                        type: "base64",
                        params: {
                            src: step.base64,
                            x: Math.round(step_real_val.x),
                            y: Math.round(step_real_val.y),
                            width: Math.round(step_real_val.width),
                            height: Math.round(step_real_val.height),
                        },
                    };
                    overlayData.steps = [...overlayData.steps, thetextlayer];
                    break;
                case "video":
                    const thevideolayer = {
                        index: overlayData.steps.length + 1,
                        action: "overlay-video",
                        type: "video",
                        params: {
                            src: `${step.params.src}`,
                            x: Math.round(step_real_val.x),
                            y: Math.round(step_real_val.y),
                            width: Math.round(step_real_val.width),
                            height: Math.round(step_real_val.height),
                        },
                    };
                    overlayData.steps = [...overlayData.steps, thevideolayer];
                    break;
                case "image":
                    const theimagelayer = {
                        index: overlayData.steps.length + 1,
                        action: "overlay-image",
                        type: "image",
                        params: {
                            src: step.params.src,
                            x: Math.round(step_real_val.x),
                            y: Math.round(step_real_val.y),
                            width: Math.round(step_real_val.width),
                            height: Math.round(step_real_val.height),
                        },
                    };
                    overlayData.steps = [...overlayData.steps, theimagelayer];
                    break;
                case "audio":
                    const createAudioLayer = (audioSrc) => ({
                        index: overlayData.steps.length + 1,
                        action: "add-audio",
                        type: "audio",
                        params: {
                            src: audioSrc,
                            volume: step.params.volume,
                        },
                    });

                    try {
                        const audioSrc = step.params.file ? (await upload({ file: step.params.file }))[0].cache : step.params.src;

                        overlayData.steps = [...overlayData.steps, createAudioLayer(audioSrc)];
                    } catch (error) {
                        console.error("Failed to process audio:", error);
                    }
                    break;
                case "effect":
                    const theeffectlayer = {
                        index: overlayData.steps.length + 1,
                        action: step.action,
                        type: "effect",
                        params: {
                            ...(step.params.option && { option: step.params.option }),
                            ...(step.params.duration && { duration: Math.round(step.params.duration) }),
                            ...(step.params.strength && { strength: Math.round(step.params.strength) }),
                        },
                    };

                    overlayData.steps = [...overlayData.steps, theeffectlayer];

                    break;
                case "transition":
                    const thefunctionlayer = {
                        index: overlayData.steps.length + 1,
                        action: step.action,
                        type: "transition",
                        params: {
                            scenes: step.params.scenes.map((scene) => scene.result.src),
                            duration: Math.round(step.params.duration),
                        },
                    };

                    overlayData.steps = [...overlayData.steps, thefunctionlayer];
                    break;
                default:
                    break;
            }
        }

        console.log("generateScene input :: ", overlayData);
        const overlay_res = await chain(overlayData);
        console.log("generateScene output :: ", overlay_res);

        return overlay_res;
    } catch (error) {
        //console.log(error);
    }
};

export const handleToEdit = ({ toEdit, setTheVideo, stageSize, selScreen }) => {
    const thebg = toEdit.layers[0];
    const theavatar = toEdit.layers.find((layer) => layer.avatarid);
    const theTexts = toEdit.layers.find((layer) => layer.elements);
    const theAudio = toEdit.layers.find((layer) => layer.type === "audio");

    //console.log(theavatar);
    const avatar_scaled = getScaledVal({
        stageSize,
        selScreen,
        originalX: theavatar.x,
        originalY: theavatar.y,
        originalWidth: theavatar.width,
        originalHeight: theavatar.height,
    });

    getVideo({ id: theavatar.avatarid })
        .then((res) => {
            //console.log("1 :: ", theavatar);
            setTheVideo({
                avatar: {
                    video: res[0],
                    effects: [
                        {
                            type: "nobg",
                            value: theavatar.src.indexOf("nobg.mp4") !== -1 ? true : false,
                        },
                        {
                            type: "enhance",
                            value: theavatar.src.indexOf("upscaled.mp4") !== -1 ? true : false,
                        },
                    ],
                    x: theavatar.x,
                    y: theavatar.y,
                    height: theavatar.height,
                    width: theavatar.width,
                },
            });
        })
        .catch((error) => {
            //console.log(error);
        });
};

export const generateProject = async (project) => {
    const overlayData = {
        canvas: {
            width: project.width,
            height: project.height,
        },

        layers: [],
    };

    project.scenes.map(async (scene) => {
        if (scene.active && scene.result.url) {
            const thescenelayer = {
                index: overlayData.layers.length,
                type: "video",
                src: scene.result.url,
                x: 0,
                y: 0,
                width: project.width,
                height: project.height,
            };
            overlayData.layers = [...overlayData.layers, thescenelayer];
        }
    });
    try {
        console.log("generateProject input :: ", overlayData);
        //const overlay_res = await overlayer(sampleOverlayData);
        const overlay_res = await chain(overlayData);
        console.log("generateProject output :: ", overlay_res);
    } catch (error) {
        console.error("Failed to generate project:", error);
    }
};

///FUNCS

export const getRealVal = ({ stageSize, project, scaledX, scaledY, scaledWidth, scaledHeight }) => {
    const scaleX = stageSize.width / project.width;
    const scaleY = stageSize.height / project.height;

    const originalX = scaledX / scaleX;
    const originalY = scaledY / scaleY;

    const originalWidth = scaledWidth / scaleX; // 100 / 0.78125 = 128
    const originalHeight = scaledHeight / scaleY; // 50 / 0.694444 = 72

    return { x: originalX, y: originalY, width: originalWidth, height: originalHeight };
};

export const getScaledVal = ({ stageSize, selScreen, originalX, originalY, originalWidth, originalHeight }) => {
    const scaleX = stageSize.width / selScreen.width;
    const scaleY = stageSize.height / selScreen.height;

    const scaledX = originalX * scaleX;
    const scaledY = originalY * scaleY;

    const scaledWidth = originalWidth * scaleX;
    const scaledHeight = originalHeight * scaleY;

    return { x: scaledX, y: scaledY, width: scaledWidth, height: scaledHeight };
};

export const formatStepType = (type) => {
    if (!type) return "";
    return type.includes("-")
        ? type
              .split("-")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")
        : type;
};
