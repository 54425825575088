import { useEffect } from "react";
import { PiAlignLeftSimple } from "react-icons/pi";
import { PiAlignCenterHorizontalSimple } from "react-icons/pi";
import { PiAlignRightSimple } from "react-icons/pi";

import { PiAlignTopSimple } from "react-icons/pi";
import { PiAlignCenterVerticalSimple } from "react-icons/pi";
import { PiAlignBottomSimple } from "react-icons/pi";

const Position = (props) => {
    const { selectedDetail, setSelectedDetail, stageSize } = props;

    const positions = {
        hor: [
            {
                id: "left",
                icon: <PiAlignLeftSimple className="text-xl" />,
                label: "left",
                x: 0,
            },
            {
                id: "center",
                icon: <PiAlignCenterHorizontalSimple className="text-xl" />,
                label: "center",
                x: stageSize.width / 2 - selectedDetail.params.width / 2,
            },
            {
                id: "right",
                icon: <PiAlignRightSimple className="text-xl" />,
                label: "right",
                x: stageSize.width - selectedDetail.params.width,
            },
        ],

        vert: [
            {
                id: "top",
                icon: <PiAlignTopSimple className="text-xl" />,
                label: "top",
                y: 0,
            },
            {
                id: "middle",
                icon: <PiAlignCenterVerticalSimple className="text-xl" />,
                label: "middle",
                y: stageSize.height / 2 - selectedDetail.params.height / 2,
            },
            {
                id: "bottom",
                icon: <PiAlignBottomSimple className="text-xl" />,
                label: "bottom",
                y: stageSize.height - selectedDetail.params.height,
            },
        ],
    };

    // const  handleUpdate = ()=>{
    //     setTheVideo((prev)=> ({
    //         ...prev,
    //         avatar:{
    //             ...prev.avatar,
    //             alignment:{
    //                 hor:
    //             }
    //         }
    //     })
    // )

    return (
        <div className="flex-1  flex flex-col gap-4 justify-between">
            <div className="flex flex-row gap-2">
                <div className="flex-1  flex items-center justify-center border border-lightblue100 rounded-xl p-2">
                    <div className="flex text-nowrap mr-2 opacity-50">x :</div>
                    <input
                        type="number"
                        className="flex-1 w-full focus  bg-transparent"
                        value={Number(selectedDetail.params.x).toFixed(2)}
                        onChange={(e) => setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, x: Number(e.target.value) } }))}
                    />
                </div>
                <div className="flex-1 flex items-center justify-center border border-lightblue100 rounded-xl p-2">
                    <div className="flex text-nowrap mr-2 opacity-50">y :</div>
                    <input
                        type="number"
                        className="flex-1  w-full focus:outline-none bg-transparent"
                        value={Number(selectedDetail.params.y).toFixed(2)}
                        onChange={(e) => setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, y: Number(e.target.value) } }))}
                    />
                </div>
            </div>
            <div className="flex-1  flex flex-row gap-4 justify-between">
                <div className=" flex flex-row gap-2 justify-between ">
                    {positions.hor.map((pos, a) => (
                        <div
                            key={a}
                            className={`group relative transition p-3 flex cursor-pointer rounded-xl hover:bg-lightblue100/40`}
                            onClick={() => {
                                setSelectedDetail((prev) => ({
                                    ...prev,
                                    params: { ...prev.params, x: pos.x },
                                }));
                            }}
                        >
                            {pos.icon}
                            {/* <div className="absolute bg-white rounded-2xl -top-2/3 -translate-y-1 left-1/2 -translate-x-1/2 py-1 px-3 text-nowrap z-30 text-black text-xs shadow-xl border border-lightblue100 opacity-0 group-hover:opacity-100">
                                {pos.label}
                            </div> */}
                        </div>
                    ))}
                </div>
                <div className=" flex flex-row gap-2  justify-between ">
                    {positions.vert.map((pos, a) => (
                        <div
                            key={a}
                            className={`group relative transition p-3 flex cursor-pointer rounded-xl hover:bg-lightblue100/40`}
                            onClick={() => {
                                setSelectedDetail((prev) => ({
                                    ...prev,
                                    params: { ...prev.params, y: pos.y },
                                }));
                            }}
                        >
                            {pos.icon}
                            {/* <div className="absolute bg-white rounded-2xl -top-2/3 -translate-y-1 left-1/2 -translate-x-1/2 py-1 px-3 text-nowrap z-30 text-black text-xs shadow-xl border border-lightblue100 opacity-0 group-hover:opacity-100">
                                {pos.label}
                            </div> */}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Position;
