import { useState, useEffect } from "react";

import ListGen from "../../generated-videos/list-gen";

const AddVideo = (props) => {
    const { campData } = props;

    return (
        <div className="flex flex-1 flex-col gap-8 py-10 w-full mx-auto">
            <div className="flex flex-col gap-1">
                <div className="text-xl font-semibold">2. Select Video</div>
                <div className="opacity-50">Select a video that will drive your list.</div>
            </div>

            <ListGen
                
                selectedId={campData?.genId}
                for={"select"}
                OnUse={(video) => {
                    if (video.id) {
                        campData.genId = video.id;
                    } else {
                        delete campData.genId;
                    }

                    props.OnSet({ ...campData });
                }}
            />
        </div>
    );
};

export default AddVideo;
