import { addAudio, overlayer } from "src/common/services/generate";
import { upload } from "src/common/services/utils";
import { bgOverlay, getVideo } from "src/common/services/generate";

export const handleOverlay = async ({ theVideo, stageSize, selScreen }) => {
    const avatar_real_pos = getRealVal({
        stageSize,
        selScreen,
        scaledX: theVideo.avatar.x,
        scaledY: theVideo.avatar.y,
        scaledWidth: theVideo.avatar.width,
        scaledHeight: theVideo.avatar.height,
    });
    const bg_real_pos = getRealVal({
        stageSize,
        selScreen,
        scaledX: theVideo.bg.x,
        scaledY: theVideo.bg.y,
        scaledWidth: theVideo.bg.width,
        scaledHeight: theVideo.bg.height,
    });

    const isNobg = theVideo.avatar.effects.find((effect) => effect.type === "nobg");
    const nobg = isNobg?.value === true ? "/nobg.mp4" : "";

    const isEnhance = theVideo.avatar.effects.find((effect) => effect.type === "enhance");
    const enhance = isEnhance?.value === true ? "/upscaled.mp4" : "";

    try {
        const overlayData = {
            canvas: {
                width: selScreen.width,
                height: selScreen.height,
            },

            layers: [
                {
                    index: 1,
                    type: theVideo.bg.type === "color" ? "base64" : theVideo.bg.type,
                    src: theVideo.bg.type === "color" ? theVideo.bg.base64 : theVideo.bg.value,
                    x: theVideo.bg.type === "color" ? 0 : Math.round(bg_real_pos.x),
                    y: theVideo.bg.type === "color" ? 0 : Math.round(bg_real_pos.y),
                    width: theVideo.bg.type === "color" ? selScreen.width : Math.round(bg_real_pos.width),
                    height: theVideo.bg.type === "color" ? selScreen.height : Math.round(bg_real_pos.height),
                },
                {
                    index: 2,
                    avatarid: theVideo.avatar.video.id,
                    type: "avatar",
                    //src: theVideo.gen_output.result,
                    src: `${theVideo.avatar.video.result}${nobg}`,
                    x: Math.round(avatar_real_pos.x),
                    y: Math.round(avatar_real_pos.y),
                    width: Math.round(avatar_real_pos.width),
                    height: Math.round(avatar_real_pos.height),
                
                },
               
            ],
        };
        // TEXT
        if (theVideo.texts.length > 0) {
            const thetextlayer = {
                index: overlayData.layers.length + 1,
                type: "base64",
                src: theVideo.texts_base64,
                x: 0,
                y: 0,
                width: selScreen.width,
                height: selScreen.height,
                texts: theVideo.texts,
            };
            overlayData.layers = [...overlayData.layers, thetextlayer];
        }

        // AUDIO
        if (theVideo.audio.file) {
            try {
                const upload_res = await upload({ file: theVideo.audio.file });
                //console.log("upload_res :: ", upload_res);
                const uploaded_audio = upload_res.data[0].cache;

                const theaudiolayer = {
                    index: overlayData.layers.length + 1,
                    type: "audio",
                    src: uploaded_audio,
                    volume: 0.05,
                };
                overlayData.layers = [...overlayData.layers, theaudiolayer];
            } catch (error) {
                //console.log("error upload ::", error);
            }
        }

        //console.log("overlayData input :: ", overlayData);

        const overlay_res = await overlayer(overlayData);
        //console.log("overlayData output :: ", overlay_res);

        return overlay_res;
    } catch (error) {
        //console.log(error);
    }
};

export const handleToEdit = ({ toEdit, setTheVideo, stageSize, selScreen }) => {
    const thebg = toEdit.layers[0];
    const theavatar = toEdit.layers.find((layer) => layer.avatarid);
    const theTexts = toEdit.layers.find((layer) => layer.elements);
    const theAudio = toEdit.layers.find((layer) => layer.type === "audio");

    //console.log(theavatar);
    const avatar_scaled = getScaledVal({
        stageSize,
        selScreen,
        originalX: theavatar.x,
        originalY: theavatar.y,
        originalWidth: theavatar.width,
        originalHeight: theavatar.height,
    });

    getVideo({ id: theavatar.avatarid })
        .then((res) => {
            //console.log("1 :: ", theavatar);
            setTheVideo({
                avatar: {
                    video: res[0],
                    effects: [
                        {
                            type: "nobg",
                            value: theavatar.src.indexOf("nobg.mp4") !== -1 ? true : false,
                        },
                        {
                            type: "enhance",
                            value: theavatar.src.indexOf("upscaled.mp4") !== -1 ? true : false,
                        },
                    ],
                    x: theavatar.x,
                    y: theavatar.y,
                    height: theavatar.height,
                    width: theavatar.width,
                },
            });
        })
        .catch((error) => {
            //console.log(error);
        });
};

///FUNCS

export const getRealVal = ({ stageSize, selScreen, scaledX, scaledY, scaledWidth, scaledHeight }) => {
    const scaleX = stageSize.width / selScreen.width;
    const scaleY = stageSize.height / selScreen.height;

    const originalX = scaledX / scaleX;
    const originalY = scaledY / scaleY;

    const originalWidth = scaledWidth / scaleX; // 100 / 0.78125 = 128
    const originalHeight = scaledHeight / scaleY; // 50 / 0.694444 = 72

    return { x: originalX, y: originalY, width: originalWidth, height: originalHeight };
};

export const getScaledVal = ({ stageSize, selScreen, originalX, originalY, originalWidth, originalHeight }) => {
    const scaleX = stageSize.width / selScreen.width;
    const scaleY = stageSize.height / selScreen.height;

    const scaledX = originalX * scaleX;
    const scaledY = originalY * scaleY;

    const scaledWidth = originalWidth * scaleX;
    const scaledHeight = originalHeight * scaleY;

    return { x: scaledX, y: scaledY, width: scaledWidth, height: scaledHeight };
};
