import { useEffect, useState } from "react";
import "./form.css";
import axios from "axios";
import endpoint from "src/common/services/endpoints";
import { getStoredUser } from "../auth/auth";
import { imageMaker, upload } from "src/common/services/utils";

const FormInput = (props) => {
    const label = props.label ? props.label : null;
    const type = props.type ? props.type : "text";
    const placeholder = props.placeholder;
    const [isChecked, setIsChecked] = useState(false);
    const [theFile, setTheFile] = useState(null);

    useEffect(() => {
        if (type === "slider") {
            let thevalue = props.value;
            setIsChecked(thevalue);
        }
    }, [type, props.value]);

    const FileUpload = (props) => {
        // let fileReader;
        useEffect(() => {
            if (theFile && props.OnSelectFile) {
                props.OnSelectFile(theFile);
            }
        }, [theFile]);

        const handleUpload = async () => {
            if (props.OnUpload) {
                props.OnUpload(true);
            }

            //console.log(theFile);

            // fileReader = new FileReader();
            // //fileReader.onloadend = handleFileRead;
            // fileReader.readAsDataURL(theFile);

            let res = await upload({ file: theFile, networkid: props.directory, channel: props.segment });

            //console.log(res);

            if (res && res.data[0] && props.OnSuccess) {
                setTheFile(null);
                let fullpath = `${res.data[0].filepaths3}`;
                //console.log("fullpath :", fullpath);

                if (theFile.type.indexOf("image") !== -1) {
                    const uploadRes = await imageMaker(fullpath);
                    //console.log("uploadRes :", uploadRes);
                    props.OnSuccess(uploadRes);
                } else {
                    props.OnSuccess(fullpath);
                }
            }
        };

        return (
            <>
                {theFile && <div className="flex-1 px-3">{theFile.name}</div>}
                {!theFile ? (
                    <label>
                        <input type="file" accept={props.accept} className="input-file" onChange={(e) => setTheFile(e.target.files[0])} />
                        {props.label}
                    </label>
                ) : (
                    <button
                        className="button button-small flex-0"
                        onClick={() => {
                            handleUpload();
                        }}
                    >
                        Upload
                    </button>
                )}
            </>
        );
    };

    const handleUrlUpload = async (url) => {
        const uploadRes = await imageMaker(url);
        return uploadRes;
    };

    return (
        <div className={`form-item ${props.className ? props.className : ""}`}>
            <div className="flex flex-col flex-1 gap-5">
                {label && (
                    <div className="flex flex-col gap-1">
                        {type !== "file" && (
                            <div className="form-item-label">
                                <div className="font-semibold">{props.label}</div>
                                {props.required && <span className="required-icon">*</span>}
                            </div>
                        )}

                        {props.description && (
                            <div className="form-item-desc">
                                <div className="text-sm  opacity-40">{props.description}</div>
                            </div>
                        )}
                    </div>
                )}

                {type === "text" && (
                    <div className="form-item-input  form-item-text">
                        {props.icon && <img src={props.icon} className="icon" />}
                        <input type={type} placeholder={placeholder} value={props.value} onChange={props.onChange} onKeyDown={props.onKeyDown} />
                    </div>
                )}

                {type === "textarea" && (
                    <div className="form-item-input  form-item-textarea flex flex-1">
                        <textarea
                            placeholder={placeholder}
                            value={props.value}
                            onChange={props.onChange}
                            rows={props.rows ? props.rows : 5}
                            className="flex-1"
                        ></textarea>
                    </div>
                )}

                {type === "select" && (
                    <div className="form-item-input form-item-select">
                        <select value={props.value} onChange={props.onChange}>
                            {placeholder && (
                                <option value="" className="opacity-25">
                                    {placeholder}
                                </option>
                            )}
                            {props.options &&
                                props.options.map((option, o) => (
                                    <option
                                        key={o}
                                        value={props.valueSelector ? option[props.valueSelector] : option.id}
                                        data-path={option.path}
                                        data-modelid={option.modelid}
                                        data-description={option.description}
                                        data-prompt={option.prompt}
                                    >
                                        {option.name}
                                    </option>
                                ))}
                        </select>
                    </div>
                )}

                {type === "file" && (
                    <div className={`form-item-input form-item-file `}>
                        <FileUpload {...props} OnSuccess={props.OnSuccess} OnFile={props.OnFile} accept={props.accept} />
                    </div>
                )}

                {type === "upload-url" && (
                    <div className="form-item-input flex-row flex">
                        <input className="flex-1" type={type} placeholder={placeholder} value={props.value} onChange={props.onChange} />
                        <button
                            className={"flex-0"}
                            disabled={!props.value || props.value === ""}
                            onClick={async () => {
                                if (props.OnUpload) {
                                    props.OnUpload(true);
                                }

                                if (props.value && props.value !== "") {
                                    let resimage = await handleUrlUpload(props.value);
                                    if (props.OnSuccess) {
                                        props.OnSuccess(resimage);
                                    }
                                }
                            }}
                        >
                            Upload
                        </button>
                    </div>
                )}

                {type === "slider" && (
                    <div className="knob-switch">
                        <div className="toggler">
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={isChecked}
                                value={isChecked}
                                onChange={() => {
                                    setIsChecked(!isChecked);
                                    props.onChange(!isChecked);
                                }}
                            />
                            <label
                                className="label"
                                onClick={() => {
                                    setIsChecked(!isChecked);
                                    props.onChange(!isChecked);
                                }}
                            >
                                <div className={`knob ${isChecked ? "active" : ""}`}></div>
                            </label>
                        </div>
                    </div>
                )}

                {props.note && <div className="small opacity-50 mt-2">{props.note}</div>}
            </div>
        </div>
    );
};

export default FormInput;
