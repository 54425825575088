import { v4 as uuidv4 } from "uuid";

export default class StepClass {
    constructor(data = {}) {
        this.id = uuidv4();
        this.index = data.index || 0;
        this.action = data.action || "";
        this.type = data.type || "";
        this.name = data.name || "";
        this.description = data.description || "";

        this.detailType = "steps";

        // Initialize params object
        this.params = {};

        if (data.options) {
            this.options = data.options;
        }
        if (data.range) {
            this.range = data.range;
        }

        // Handle different types of steps
        switch (this.type) {
            case "video":
            case "image":
            case "avatar":
            case "text":
            case "base64": {
                const params = {
                    src: data.params?.src || "",
                    x: data.params?.x || null,
                    y: data.params?.y || null,
                    width: data.params?.width || null,
                    height: data.params?.height || null,
                };
                if (this.type === "avatar" && data.params?.genVidId) {
                    params.genVidId = data.params.genVidId;
                }
                if (this.type === "text") {
                    params.text = data.params.text;
                    params.font = {
                        name: data.params.font?.name,
                        style: data.params.font?.style,
                        size: data.params.font?.size,
                        color: data.params.font?.color,
                    };
                    params.align = data.params.align;
                    params.rotation = data.params.rotation;
                }
                this.params = params;
                break;
            }

            case "effect": {
                const params = {};

                if (data.params?.option !== undefined) {
                    params.option = data.params.option;
                }
                if (data.params?.duration !== undefined) {
                    params.duration = data.params.duration;
                }
                if (data.params?.strength !== undefined) {
                    params.strength = data.params.strength;
                }
                if (data.params?.range !== undefined) {
                    params.range = data.params.range;
                }

                this.params = params;
                break;
            }

            case "transition": {
                const params = {};
                if (data.params?.scenes) {
                    params.scenes = data.params.scenes;
                }
                if (data.params?.duration !== undefined) {
                    params.duration = data.params.duration;
                }
                this.params = params;
                break;
            }

            case "audio": {
                const params = {};

                params.src = data.params.src;
                params.volume = data.params.volume;
                params.file = data.params.file || null;

                this.params = params;
                break;
            }

            default:
                if (data.params?.result) {
                    this.params.result = data.params.result;
                }
                break;
        }
    }

    static overlay(data = {}) {
        return new StepClass({
            action: data.action || "overlay-video",
            type: data.type || "video",
            ...data,
        });
    }

    static audio(data = {}) {
        return new StepClass({
            action: data.action || "add-audio",
            type: "audio",
            ...data,
        });
    }

    static effect(data = {}) {
        return new StepClass({
            action: data.action || "zoom",
            type: "effect",
            ...data,
        });
    }

    static transition(data = {}) {
        return new StepClass({
            action: data.action || "fade",
            type: "transition",
            ...data,
        });
    }

    static generated(data = {}) {
        return new StepClass({
            action: "generated",
            type: "generated",
            ...data,
        });
    }
}
