import AdminLayout from "src/layouts/admin-layout";
import ListVideos from "src/modules/admin/inputed-videos/list-videos";
import { IoAddCircleOutline } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import { upload } from "src/common/services/utils";
import { useState } from "react";

const StudioGeneratedVideos = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [action, setAction] = useState();
    const [isUploading, setIsUploading] = useState(false);

    return (
        <>
            <AdminLayout
                header={(
                    <div className="flex flex-1 items-center ">
                        <div className="flex flex-1 flex-row px-0 items-center gap-6">
                            <div className="text-xl font-semibold">Your Studio Generated Videos</div>
                            
                           
                        </div>
                    </div>
                )}
            >
                <div className="container mx-auto flex  flex-col flex-1 h-full">
                    <ListVideos
                        for={`studio`}
                        action={action}
                        OnUploaded={() => {
                            setIsUploading(false);
                        }}
                        OnError={() => {
                            setIsUploading(false);
                        }}
                    />
                </div>
            </AdminLayout>
        </>
    );
};

export default StudioGeneratedVideos;
