
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export const animateWedo = () => {

    ScrollTrigger.create({
        trigger: ".section-wedo",
        pin: ".section-wedo",
        start: "top top",
        end: "+=6000",
    });

    const wedo_tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".section-wedo",
            start: `top 60%`,
            end: "+=4000",
            scrub: 1,
        },
    });
    wedo_tl.from(".section-wedo .g-title", {
        xPercent: 100,
    });

    // wedo_tl.to(".section-wedo .g-title", {
    //     scale: 1,
    // });


    const tlvids = gsap.timeline({
        defaults: {
            duration: 5,
        },
        scrollTrigger: {
            trigger: ".section-wedo",
            start: "top top",
            end: "+=4000",
            scrub: 1,
        },
    });

    tlvids.from(".section-wedo .g-vid-1", {
        y: -100,
        opacity: 0,
    });
    tlvids.from(".section-wedo .g-text-1", {
        x: 100,
        opacity: 0,
    });
    tlvids.from(".section-wedo .g-vid-2", {
        y: 100,
        opacity: 0,
    });
    tlvids.to(".section-wedo .g-vid-1", {
        y: 100,
        opacity: 0,
    });
    tlvids.to(".section-wedo .g-text-1", {
        y: -100,
        opacity: 0,
    });
    tlvids.from(".section-wedo .g-text-2", {
        x: 100,
        opacity: 0,
    });
    tlvids.from(".section-wedo .g-vid-3", {
        y: 100,
        opacity: 0,
    });
    tlvids.to(".section-wedo .g-vid-2", {
        y: 100,
        opacity: 0,
    });
    tlvids.to(".section-wedo .g-text-2", {
        x: 100,
        opacity: 0,
    });
    tlvids.from(".section-wedo .g-text-3", {
        x: -100,
        opacity: 0,
    });
    tlvids.from(".section-wedo .g-vid-4", {
        y: 100,
        opacity: 0,
    });
    tlvids.to(".section-wedo .g-text-3", {
        x: 100,
        opacity: 0,
    });
    tlvids.to(".section-wedo .g-vid-3", {
        y: 100,
        opacity: 0,
    });
    tlvids.to(".section-wedo .g-vid-3", {
        y: 100,
        opacity: 0,
    });

    // const wedoBgs = gsap.timeline({
    //     scrollTrigger: {
    //         trigger: ".section-wedo",
    //         start: "50% bottom",
    //         end: "+=2000",
    //         scrub: 1,
    //     }
    // })

    // wedoBgs.to(".g-bg-3", {
    //     backgroundColor:'#fde68a',
    // });

    // wedoBgs.to(".g-bg-2", {
    //     backgroundColor:'#fda4af',
    // });

    // wedoBgs.to(".g-bg-1", {
    //     backgroundColor:'#86efac',
    // });
}