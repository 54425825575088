import { useEffect, useRef, useState } from "react";

import { ReactLenis, useLenis } from "lenis/react";
import Logo from "src/common/components/logo/logo";
import { FaRegCheckSquare } from "react-icons/fa";
import { LuClipboardList } from "react-icons/lu";

import VideoPlayer from "./player/player";
import VideoBgRemover from "src/common/components/video-bg-remover";
import { addEmailToWaitlist } from "src/common/services/waitlist";
import iconArrowDown from "src/common/assets/images/marketing/icon-arrow-down.svg";

import girlPhoneVert from "src/common/assets/images/girl-phone-vert-opt.mp4";
import anaVert2 from "src/common/assets/images/anavert2-opt.mp4";

import girlGinger1 from "src/common/assets/images/girl-ginger-1-opt.mp4";

import femaleCurly1 from "src/common/assets/images/female-curly-1.mp4";
import femaleCurly1a from "src/common/assets/images/female-curly-1a.mp4";

import maleCap1 from "src/common/assets/images/male-cap-1.mp4";
import maleCap1a from "src/common/assets/images/male-cap-1a.mp4";
import maleCap2 from "src/common/assets/images/male-cap-2.mp4";
import ethanResult from "src/common/assets/images/ethan-result.mp4";

import girlOrange from "src/common/assets/images/girl-orange.mp4";
import lenaResult from "src/common/assets/images/lena-result.mp4";

import annaPic from "src/common/assets/images/anna.png";
import lenaPic from "src/common/assets/images/lena.png";
import ethanPic from "src/common/assets/images/ethan.png";

import { FaPlay } from "react-icons/fa";
import { AiOutlineAudio } from "react-icons/ai";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animateTitle } from "../marketing/animation/common";

import handRight from "src/common/assets/images/hand-right.png";

import { FiArrowDown } from "react-icons/fi";
import { animateHero } from "./animation/hero";
import { animateCreators } from "./animation/creators";
import { animateWedo } from "./animation/wedo";
import BlurredCircles from "src/common/components/blurred-circles";

gsap.registerPlugin(ScrollTrigger);

const LandingPage = (props) => {
    const main = useRef();
    const lenis = useLenis((data) => {});

    const circles = [
        {
            x: 50,
            y: -10,
            width: 1000,
            height: 500,
            color: "#a5f3fc",
            blur: 200,
        },
        {
            x: 10,
            y: 95,
            width: 1000,
            height: 600,
            color: "#6676FF",
            blur: 280,
        },
        {
            x: 90,
            y: 98,
            width: 1000,
            height: 500,
            color: "#50ED94",
            blur: 280,
        },
    ];

    const circles2 = [
        {
            x: 50,
            y: 50,
            width: 800,
            height: 600,
            color: "#6676FF",
            blur: 400,
        },
        {
            x: 0,
            y: 0,
            width: 1000,
            height: 800,
            color: "#a5f3fc",
            blur: 320,
        },
        {
            x: 100,
            y: 100,
            width: 1000,
            height: 300,
            color: "#FF1759",
            blur: 300,
        },
    ];

    const slides = [
        {
            id: "001",
            video: girlGinger1,
            videogen: anaVert2,
            pic: annaPic,
            name: "Anne Jennings",
            title: "Content Creator - Tiktok",
        },
        {
            id: "002",
            video: girlOrange,
            videogen: lenaResult,
            pic: lenaPic,
            name: "Lena Whitaker",
            title: "Online Marketer",
        },
        {
            id: "003",
            video: maleCap2,
            videogen: ethanResult,
            pic: ethanPic,
            name: "Ethan Calloway",
            title: "Live Streamer - Twitch",
        },
    ];
    const [selSlide, setSelSlide] = useState(slides[0]);
    const [logoType, setLogoType] = useState(null);

    useGSAP(
        () => {
            gsap.to(".section-header .logo", {
                scrollTrigger: {
                    trigger: ".section-creators",
                    start: `10% bottom`,
                    end: `bottom top`,
                    scrub: 1,
                    onEnter: () => {
                        setLogoType("white");
                    },
                    onLeave: () => {
                        setLogoType(null);
                    },
                    onEnterBack: () => {
                        setLogoType("white");
                    },
                    onLeaveBack: () => {
                        setLogoType(null);
                    },
                },
            });

            // const bg_tl = gsap.timeline({
            //     scrollTrigger: {
            //         trigger: ".section-wedo > .g-title",
            //         start: `bottom -105%`,
            //         end: `+=500`,
            //         scrub: 1,
                   
            //     },
            // });

            // bg_tl.to("#blurred1", {
            //     duration: 1,
            //     opacity: 0,
            // });
            // bg_tl.to(
            //     "#blurred2",
            //     {
            //         opacity: 1,
            //     },
            //     "-=1.5"
            // );
           

            animateHero();

            animateCreators({ slides }, (slide) => {
                setSelSlide(slide);
            });

            animateWedo();

            //SIGNUP  -----------------------------------------------------------------------------------
            animateTitle({ trigger: ".section-signup .g-title" });
        },
        { scope: main }
    );

    const [email, setEmail] = useState("");
    const [error, setError] = useState(null);
    const [fetching, setFetching] = useState(false);

    const handleWaitlist = async () => {
        if (validateEmail(email)) {
            setError(null);
            try {
                setFetching(true);
                const add_res = await addEmailToWaitlist({ email });
                //console.log(add_res);
                setEmail("");
                setFetching(false);
            } catch (error) {
                //console.log("error :: ", error);
                setFetching(false);
                if (error.message) {
                    setError(error);
                }
            }
        } else {
            setFetching(false);
            setError("Invalid Email. Please add a correct email.");
        }
    };

    function validateEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    function validateBusinessEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.(com|org|net)$/;
        const freeEmailProviders = ["gmail.com", "yahoo.com", "outlook.com", "hotmail.com"];
        const domain = email.split("@")[1];
        return regex.test(email) && !freeEmailProviders.includes(domain);
    }

    return (
        <ReactLenis root options={{ lerp: 0.05, smoothTouch: true }}>
            <div ref={main} className="relative overflow-hidden lightgray10">
                {/* BACKGROUNDS */}
                <div className="g-bgs fixed top-0 left-0 h-full w-full z-0">
                    {/* <BlurredCircles id={`blurred1`} className={`z-0 `} circles={circles} />
                    <BlurredCircles id={`blurred2`} className={`opacity-0 z-10`} circles={circles2} /> */}
                    <div
                        className="g-bg g-bg-1 bg-blur absolute rounded-full z-0 left-1/2 -translate-x-1/2 -translate-y-1/2 w-3/4 h-96 blur-7xl xl:blur-8xl"
                        style={{ background: "#a5f3fc" }}
                    ></div>

                    <div
                        className="g-bg g-bg-2 bg-blur absolute rounded-full z-0 left-1/2 translate-x-1/3 top-3/4 w-1/2 h-1/2 blur-7xl xl:blur-8xl"
                        style={{ background: "#50ED94" }}
                    ></div>

                    <div
                        className="g-bg g-bg-3 bg-blur absolute rounded-full z-0 -translate-x-1/3 top-3/4 w-1/2 h-1/2 blur-7xl xl:blur-8xl"
                        // style={{ background: "#6676FF", width: 1500, height: 500, filter: `blur(250px)` }}
                        style={{ background: "#6676FF" }}
                    ></div>

                    <div
                        className="slice absolute left-1/2 top-0 -translate-y-1/2  z-30"
                        style={{
                            transform: "rotate(20deg) scale(1.5)",
                            height: "2000px",
                            width: "800px",
                            background: `rgb(255,255,255)`,
                            background: `linear-gradient(60deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 51%, rgba(255,255,255,0.5) 100%)`,
                        }}
                    ></div>
                </div>

                <div className="container mx-auto relative z-40 ">
                    {/* SECTION HEADER */}
                    <div className="section section-header fixed top-0 left-0 w-full z-50">
                        <div className="container mx-auto flex items-center justify-between px-10 py-10 ">
                            {/* <Logo className={`!min-w-52`} type={logoType} /> */}

                            <div
                                className={`logo w-28 min-h-12 md:w-32 bg-no-repeat bg-contain !min-w-52 ${logoType === "white" ? "logo-white" : "logo"} `}
                            ></div>
                            <button
                                className="button text-base xl:text-lg py-4 xl:py-6 px-6 xl:px-10 rounded-full"
                                onClick={() => {
                                    lenis.scrollTo(".section-signup");
                                }}
                            >
                                Join our waitlist
                            </button>
                        </div>
                    </div>
                </div>
                {/* SECTION HERO */}
                <div className="section section-hero min-h-screen flex items-center justify-center relative">
                    <div className="g-title text-7xl xl:text-10xl text-center flex flex-col gap-3 xl:gap-6 leading-tighter items-center text-black">
                        <div className="g-title-a flex items-center gap-4 xl:gap-8">
                            <span className="anton uppercase">Are</span>
                            <span className="barlow font-thin italic tracking-tighter">you</span>
                            <span className="anton uppercase">ready</span>
                        </div>
                        <div className="g-title-b flex items-center gap-8">
                            <span className="barlow font-thin italic tracking-tighter">for</span> <span className="anton uppercase">your</span>
                            <div className="relative overflow-hidden rounded-full w-40 h-24 xl:w-80 xl:h-48">
                                <video src={anaVert2} loop playsInline muted autoPlay className="absolute w-full object-cover -top-12 xl:-top-32" />
                                <FaPlay className="hidden xl:block absolute text-2xl text-white right-10 top-1/2 -translate-y-1/2" />
                            </div>
                        </div>
                        <div className="g-title-cs anton uppercase">Digital U?</div>
                    </div>

                    <div className="g-arrow absolute left-0 bottom-10  w-full flex items-center justify-center pb-7 z-50">
                        <div className="w-24 h-24 flex items-center justify-center rounded-full bg-white/50 animate-bounce   shadow shadow-2xl shadow-indigo-500/30">
                            <FiArrowDown className="text-2xl text-blue" />
                        </div>
                    </div>
                </div>

                {/* SECTION CREATORS */}
                <div className="wrapper-creators relative z-10">
                    <div className="absolute bg-creators left-0 top-0 w-full h-full z-0  bg-black"></div>
                    <div
                        className="g-bg g-bg-1 bg-blur absolute rounded-full z-0 left-1/2 -translate-x-1/2  w-3/4 h-96 blur-8xl xl:blur-9xl "
                        style={{ background: "#6676FF" }}
                    ></div>

                    <div className="container mx-auto relative z-10">
                        <div className="section section-creators relative z-20 py-20">
                            <div className="g-title text-7xl xl:text-10xl text-center flex flex-col gap-3 xl:gap-6 leading-tighter items-center text-white pb-52">
                                <div className="g-title-a flex items-center gap-4 xl:gap-8">
                                    <span className="anton uppercase">The</span>
                                    <span className="anton uppercase">future</span>
                                    {/* <span className="barlow font-thin italic tracking-tighter">Future</span> */}
                                </div>
                                <div className="g-title-a flex items-center gap-4 xl:gap-8">
                                    <span className="barlow font-thin italic tracking-tighter">of</span>
                                    <span className="anton uppercase">storytelling</span>
                                </div>
                                <div className="g-title-b flex items-center  gap-4 xl:gap-8">
                                    <span className="anton uppercase">has</span>
                                    <div className="hidden xl:block relative overflow-hidden rounded-full w-40 h-24 xl:w-80 xl:h-48">
                                        <video src={femaleCurly1} loop playsInline muted autoPlay className="absolute w-full object-cover -top-20 xl:-top-40" />
                                        <FaPlay className="hidden xl:block absolute text-2xl text-white right-10 top-1/2 -translate-y-1/2" />
                                    </div>
                                    <span className="barlow font-thin italic tracking-tighter">arrived.</span>
                                    {/* <span className="anton uppercase">arrived.</span> */}
                                </div>
                            </div>

                            <div className="g-hand hidden xl:block absolute right-0 translate-x-1/3 top-72 z-50 w-912 h-912 scale-125">
                                {/* <div className="g-hand absolute left-1/2 -translate-x-1/2 top-72 z-50 w-912 h-912 scale-125"> */}
                                <div
                                    className="absolute overflow-hidden"
                                    style={{
                                        borderRadius: 32,
                                        width: 269,
                                        height: 577,
                                        left: 215,
                                        top: 38,
                                        boxShadow: "0 20px 60px 0 rgba(46,60,185,0.1)",
                                    }}
                                >
                                    <VideoPlayer src={selSlide.videogen} />
                                </div>
                                <img src={handRight} className="w-full h-full object-contain object-right-bottom" />
                            </div>
                            <div className="flex flex-col rounded-4xl relative overflow-hidden g-slides ">
                                {slides.map((slide, s) => (
                                    <div key={s} id={`g-slide-${s}`} data-id={slide.id} className="g-slide overflow-hidden w-full h-912 relative ">
                                        {window.innerWidth <= 640 ? (
                                            <VideoPlayer src={slide.videogen} poster={slide.pic} />
                                        ) : (
                                            <video
                                                src={slide.video}
                                                loop
                                                playsInline
                                                muted
                                                autoPlay
                                                className="absolute w-full h-full object-cover top-0 left-0"
                                            />
                                        )}

                                        {/* <div
                                        className="absolute w-full h-full left-0 top-0 opacity-10"
                                        style={{
                                            // background: `rgb(34,193,195)`,
                                            // background: `linear-gradient(21deg, rgba(34,193,195,1) 0%, rgba(253,187,45,0.5) 100%)`,
                                            background: `rgb(45,77,253)`,
                                            background: `linear-gradient(25deg, rgba(45,77,253,0) 0%, rgba(45,77,253,1) 100%)`,
                                        }}
                                    ></div> */}

                                        <div className="absolute top-1/2 left-24 text-left text-white p-10 z-20 pt-40 xl:pt-10 ">
                                            <div className="text-2xl font-semibold">{slide.name}</div>
                                            <div className="opacity-60 text-lg">{slide.title}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* SECTION WEDO */}
                <div className="relative z-10">
                    <div className="container mx-auto relative z-10">
                        <div className="section section-wedo min-h-screen flex items-center justify-end py-20 z-10 relative ">
                            <div className="absolute min-h-52 w-full">
                                <div className="g-vid g-vid-1 opacity-0 xl:opacity-100 absolute overflow-hidden rounded-4xl w-72 z-0  h-96 left-32 -top-96">
                                    <video src={maleCap1} loop playsInline muted autoPlay className="absolute w-full h-full object-cover top-0 left-0" />
                                </div>

                                <div className="g-vid g-vid-2 hidden xl:block absolute  overflow-hidden rounded-4xl w-72 z-20  h-96 right-32 top-32">
                                    <video src={femaleCurly1} loop playsInline muted autoPlay className="absolute w-full h-full object-cover top-0 left-0" />
                                </div>

                                <div className="g-vid g-vid-3 hidden xl:block absolute  absolute overflow-hidden rounded-4xl w-72 z-20  h-96 left-96 top-96">
                                    <video src={girlOrange} loop playsInline muted autoPlay className="absolute w-full  h-full object-cover" />
                                </div>

                                <div className="g-vid g-vid-4 hidden xl:block absolute  overflow-hidden rounded-4xl w-72 z-20  h-96 right-1/4 -top-96">
                                    <video src={girlGinger1} loop playsInline muted autoPlay className="absolute w-full  h-full object-cover" />
                                </div>

                                <div className="g-text g-text-1 absolute -top-52 xl:-top-72 left-10 xl:left-1/4 text-xl tracking-tight leading-tight flex flex-col pl-32">
                                    <div>Convert your voice </div>
                                    <div>with perfectly synced</div>
                                    <div>lip movements.</div>

                                    <div className="absolute left-0 top-3 w-20 h-20 rounded-full bg-white/60 flex items-center justify-center animate-bounce">
                                        <AiOutlineAudio className="text-blue text-2xl" />
                                    </div>
                                </div>

                                <div className="g-text g-text-2 absolute -bottom-60 right-10 xl:left-1/2 text-xl text-right tracking-tight leading-tight flex flex-col xl:flex-row flex-nowrap gap-2 xl:gap-3 items-center">
                                    <div className="text-nowrap">Skip the</div>{" "}
                                    <div className="hidden xl:block w-6 h-6 rounded-full bg-red-500  blinking"></div>
                                    <div className="">recording and start generating!</div>
                                </div>

                                <div className="g-text g-text-3 absolute -top-52 left-10 xl:right-0 text-xl tracking-tight leading-tight flex flex-col">
                                    <div>Fast, effortless, and straightforward </div>
                                    <div>— designed to save you time</div>
                                </div>
                            </div>

                            <div
                                className="g-title flex items-center justify-start gap-4 xl:gap-12 text-11xl xl:text-12xl relative z-10 px-20 text-black">
                                <span className="anton uppercase relative">Create</span>
                                <span className="barlow font-thin italic tracking-tighter">and</span>
                                <span className="anton uppercase">Modify</span>
                                {/* <span className="anton uppercase">talking</span> */}
                                <span className="barlow font-thin italic tracking-tighter">Talking</span>
                                <span className="anton uppercase">Videos</span>
                                <span className="barlow font-thin italic tracking-tighter">with</span>
                                <span className="anton uppercase">AI</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* SECTION SIGNUP */}
                <div className="wrapper-signup container mx-auto relative z-10">
                    <div className="section section-signup p-10 xl:pt-52 xl:pb-32 flex items-center justify-center bg-white/40 rounded-4xl">
                        <div className="flex flex-col p-20 max-w-1/2 gap-14">
                            <div className="g-title text-7xl xl:text-9xl flex flex-col gap-1 leading-tighter ">
                                <div className="flex items-center gap-3 xl:gap-8">
                                    <span className="anton uppercase relative">Sign up</span>
                                    <span className="barlow font-thin italic tracking-tighter">to join</span>
                                </div>

                                <div className="flex items-center gap-3 xl:gap-8">
                                    <span className="barlow font-thin italic tracking-tighter">our</span>
                                    <span className="anton uppercase">Exclusive</span>
                                </div>
                                <div className="flex items-center gap-3 xl:gap-8">
                                    <span className="anton uppercase">Waitlist.</span>
                                    <div className="relative overflow-hidden rounded-full  w-40 h-20 xl:w-80 xl:h-40">
                                        <video src={girlOrange} loop playsInline muted autoPlay className="absolute w-full object-cover scale-125" />
                                        <FaPlay className="text-2xl text-white absolute right-10 top-1/2 -translate-y-1/2" />
                                    </div>
                                </div>
                            </div>
                            <div className="text-base xl:text-xl">Be among the first to access new features and updates.</div>

                            <div className="flex flex-col justify-center items-center  md:items-start gap-3 w-full ">
                                {fetching && (
                                    <div className="transition-all bg-blue/10 rounded-2xl text-center  p-5 loading text-indigo-950/70">
                                        Adding {email} to the list. Please wait
                                    </div>
                                )}
                                {!fetching && (
                                    <div className="transition-all bg-white/80 p-2 md:p-4 rounded-2xl text-base md:text-lg flex gap-5 w-full  items-center">
                                        <input
                                            type="text"
                                            className="lg:min-w-400"
                                            placeholder="Add your email"
                                            value={email}
                                            onChange={(e) => {
                                                setError(null);
                                                setEmail(e.target.value);
                                            }}
                                        />
                                        <button
                                            disabled={!email && email === ""}
                                            className="button transition-all bg-blue p-4 rounded-2xl cursor-pointer hover:opacity-70"
                                            onClick={async () => {
                                                await handleWaitlist();
                                            }}
                                        >
                                            <FaRegCheckSquare className="text-xl text-white" />
                                        </button>
                                    </div>
                                )}
                                <div className="flex flex-col gap-3">
                                    {error && <div className="bg-rose-500/10 rounded-full p-2 px-4 text-center text-rose-500 capitalize">{error}</div>}
                                    {/* <div className="opacity-40">Only business email addresses are accepted.</div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section section-footer  py-20 text-lg text-center relative z-20">© DigitalU 2024+. All rights reserved.</div>
            </div>
        </ReactLenis>
    );
};

export default LandingPage;
