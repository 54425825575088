import { useState, useRef, useEffect } from "react";

import { IoIosArrowDown } from "react-icons/io";

const UiSelect = ({ 
    options = [], 
    value = '',
    onChange, 
    placeholder = "Select an option",
    disabled = false,
    className = '',
    optionStyle
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef(null);

    const selectedOption = options.find(option => {
        if (typeof option === 'string') {
            return String(option) === String(value);
        }
        if (typeof value === 'object' && value !== null) {
            return JSON.stringify(option.value) === JSON.stringify(value);
        }
        return String(option.value) === String(value);
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

   

    return (
        <div ref={selectRef} className={`relative w-full ${className}`}>
            <button 
                onClick={() => !disabled && setIsOpen(!isOpen)}
                disabled={disabled}
                style={!selectedOption && optionStyle ? optionStyle({ label: placeholder, value: '' }) : 
                       selectedOption && optionStyle ? optionStyle(selectedOption) : {}}
                className={`
                    w-full px-3 py-2 
                    flex justify-between items-center
                    bg-white border border-lightblue100 rounded-xl
                    ${disabled ? 'cursor-not-allowed opacity-60' : 'cursor-pointer'}
                    focus:outline-none focus:ring-2 focus:ring-blue/60 focus:border-blue/60
                `}
            >
                <span className={`text-left text-nowrap relative overflow-hidden whitespace-nowrap ${!selectedOption ? 'text-gray-400' : 'text-gray-700'}`}>
                    {selectedOption ? (typeof selectedOption === 'string' ? selectedOption : selectedOption.label) : placeholder}
                </span>
                <span className={`
                    transition-transform duration-200
                    ${isOpen ? 'rotate-180' : 'rotate-0'}
                `}>
                    <IoIosArrowDown className="text-md" />
                </span>
            </button>

            {isOpen && !disabled && (
                <div className={`
                    absolute w-full mt-1 
                    bg-white border border-lightblue100 rounded-xl
                    max-h-[200px] overflow-y-auto
                    z-50 shadow-lg
                `}>
                    {options.map((option, o) => {
                        const value = typeof option === 'object' ? option.value : option;
                        const label = typeof option === 'object' ? option.label : option;
                        
                        return (
                            <div
                                key={o}
                                onClick={() => {
                                    onChange(option.value || option);
                                    setIsOpen(false);
                                }}
                                style={optionStyle ? optionStyle(option) : {}}
                                className={`
                                    px-3 py-2 cursor-pointer
                                    ${value === selectedOption?.value ? 'bg-blue-50 text-blue-700' : 'bg-white hover:bg-lightblue100/40'}
                                `}
                            >
                                {label}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default UiSelect;
