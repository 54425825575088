import { useSortable } from "@dnd-kit/sortable";
import { GoGrabber } from "react-icons/go";
import { LuMinusCircle } from "react-icons/lu";
import { formatStepType } from "src/modules/admin/studio/studio-funcs";

const Step = ({ step, selectedScene, setSelectedScene, selectedDetail, setSelectedDetail, showActions }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: step.id });

    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition,
        zIndex: isDragging ? 1 : 0,
        boxShadow: isDragging ? "0 6px 12px rgba(111, 118, 187, 0.12)" : "none",
        backgroundColor: isDragging ? "#ffffff" : undefined,
    };

    const handleStep = ({ opr, step }) => {
        
        switch (opr) {
            case "select":
                console.log("select step :: ", step);
                setSelectedDetail(selectedDetail?.id === step.id ? null : { ...step });

                break;  
            case "delete":
                setSelectedScene({ ...selectedScene, steps: selectedScene.steps.filter((s) => s.id !== step.id) });
                if (selectedDetail?.id === step.id || selectedScene.steps.length <= 0) {
                    setSelectedDetail(null);
                }
                

                break;
        }
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={`scene-step flex items-center gap-2 p-4 pr-2 group cursor-pointer border border-lightblue100 rounded-xl bg-white ${
                isDragging ? "opacity-90" : ""
            }
            ${selectedDetail?.id === step.id ? "!border-blue/80 !border-2 text-blue" : ""}
            `}
           
        >
            {showActions && (
                <div
                    className="transition scene-step-action w-6 flex items-center items-center justify-center text-lg text-red-500/60 hover:text-red-500"
                    onClick={() => {
                        handleStep({ opr: "delete", step });
                    }}
                >
                    <LuMinusCircle className="text-base" />
                </div>
            )}
            <div  onClick={() => handleStep({ opr: "select", step })} className={`scene-step-type flex-1 capitalize ${selectedDetail?.id === step.id ? "!border-blue" : ""}`}>
                {step.name ? step.name : formatStepType(step.action)}
                <div className="text-xs text-black/50">{step.type !== step.name.toLowerCase() ? step.type : ""}</div>
            </div>
            {showActions && (
                <div className="scene-step-sort cursor-grab" {...attributes} {...listeners}>
                    <GoGrabber className="text-xl" />
                </div>
            )}
        </div>
    );
};

export default Step;
