import axios from "axios";
import endpoint from "src/common/services/endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleErr, handleRes } from "src/common/services/response";

// MUTATIONS
export const addEmailToWaitlist = async ({ email }) => {
    const token = getStoredUser()?.token;

    const config = {
        method: "post",
        url: `${endpoint.rest}waitlist`,
        data: {
            auth: "xxxx",
            email,
        },
        headers: {
            "Content-Type": "application/json",
            // Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        return handleErr({error, config , redirect: false});
    }
};
