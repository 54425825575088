import axios from "axios";
import endpoint from "./endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleRes, handleErr } from "./response";

// QUERIES

// SMS Function
export const SMS = async ({ recipient_number, content }) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}`,
        data: {
            Params: {
                sys: "share",
                act: "sendSMS",
                pagesize: "1",
            },
            Values: {
                recipient_number,
                content,
            },
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

// MMS Function
export const MMS = async ({ recipient_number, content, videourl }) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}`,
        data: {
            Params: {
                sys: "share",
                act: "sendMMS",
                pagesize: "1",
            },
            Values: {
                recipient_number,
                content,
                videourl,
            },
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

// sendEmail Function
export const sendEmail = async ({ sender_name, recipient_email, recipient_name, subject, content, attachment }) => {
    const token = getStoredUser()?.token;
    const config = {
        method: "post",
        url: `${endpoint.rest}`,
        data: {
            Params: {
                sys: "share",
                act: "sendemail",
                pagesize: "1",
            },
            Values: {
                sender_name,
                recipient_email,
                recipient_name,
                subject,
                content,
                attachment,
            },
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};
