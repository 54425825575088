export const textFonts = {
    "Lora": [
      { style: 'Regular 400', value: 'normal 400' },
      { style: 'Bold 700', value: 'normal 700' },
      { style: 'Italic 400', value: 'italic 400' },
      { style: 'Italic Bold 700', value: 'italic 700' },
    ],
    "Montserrat": [
      { style: 'Thin 100', value: 'normal 100' },
      { style: 'Regular 400', value: 'normal 400' },
      { style: 'Bold 700', value: 'normal 700' },
      { style: 'Italic Thin 100', value: 'italic 100' },
      { style: 'Italic Bold 700', value: 'italic 700' },
    ],
    'Noto Serif': [
      { style: 'Light 100', value: 'normal 100' },
      { style: 'Bold 900', value: 'normal 900' },
      { style: 'Italic Light 100', value: 'italic 100' },
      { style: 'Italic Bold 900', value: 'italic 900' },
    ],
    'Open Sans': [
      { style: 'Light 300', value: 'normal 300' },
      { style: 'Bold 800', value: 'normal 800' },
      { style: 'Italic Light 300', value: 'italic 300' },
      { style: 'Italic Bold 800', value: 'italic 800' },
    ],
    'Playfair Display': [
      { style: 'Regular 400', value: 'normal 400' },
      { style: 'Bold 900', value: 'normal 900' },
      { style: 'Italic 400', value: 'italic 400' },
      { style: 'Italic Bold 900', value: 'italic 900' },
    ],
    'Qwitcher Grypen': [
      { style: 'Regular 400', value: 'normal 400' },
      { style: 'Bold 700', value: 'normal 700' },
    ],
    "Roboto": [
      { style: 'Thin 100', value: 'normal 100' },
      { style: 'Regular 400', value: 'normal 400' },
      { style: 'Bold 700', value: 'normal 700' },
      { style: 'Black 900', value: 'normal 900' },
      { style: 'Italic Thin 100', value: 'italic 100' },
      { style: 'Italic Bold 900', value: 'italic 900' },
    ],
  }