
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animateTitle } from "src/layouts/marketing/animation/animation";
gsap.registerPlugin(ScrollTrigger);


export const animateCreators = ({slides}, callback) =>{
    animateTitle({ trigger: ".section-creators .g-title" });

    ScrollTrigger.create({
        trigger: ".section-creators",
        pin: ".g-hand",
        start: "top top",
        end: "bottom bottom",
    });

    const ghandTlStart = gsap.timeline({
        scrollTrigger: {
            trigger: ".section-creators",
            start: `top bottom`,
            end: "+=1000",
            scrub: 1,
        },
    });

    ghandTlStart.from(".section-creators .g-hand", {
        yPercent: 200,
        xPercent: 200,
    });

    const ghandTl = gsap.timeline({
        scrollTrigger: {
            trigger: ".section-creators",
            start: `bottom bottom`,
            end: "+=1000",
            scrub: 1,
        },
    });
    ghandTl.to(".section-creators .g-hand", {
        yPercent: 200,
        xPercent: 200,
    });


    const creators_end_tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".section-creators",
            start: `90% bottom`,
            end: "bottom bottom",
            scrub: 0,
          
        },
    });

    creators_end_tl.to(".bg-creators", {
        opacity: 0,
    });


    const creators_bg_tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".section-creators .g-title",
            start: `top bottom`,
            end: "bottom bottom",
            scrub: 0,
          
        },
    });
    creators_bg_tl.from(".wrapper-creators .g-bg", {
        opacity:0,
    });



    const aslides = gsap.utils.toArray(".section-creators .g-slide");
    aslides.forEach((item, i) => {
        gsap.from(item, {
            yPercent: -30,
            scrollTrigger: {
                trigger: item,
                start: `top bottom`,
                end: "bottom bottom",
                scrub: 1,
                onUpdate: ({ progress, direction, isActive }) => {
                    const prevSib = item.previousSibling?.dataset?.id;
                    const find = slides.find((slide) => slide.id === prevSib);
                    if (find) {
                        callback(find)
                       // setSelSlide(find);
                    } else {
                        callback(slides[0])
                        //setSelSlide(slides[0]);
                    }
                },

                onLeave: ({ progress, direction, isActive }) => {
                    const find = slides.find((slide) => slide.id === item.dataset.id);
                    if (find) {
                        callback(find)
                       //setSelSlide(find);
                    } else {
                        callback(slides[0])
                        //setSelSlide(slides[0]);
                    }
                },
            },
        });
    });
}