import { useEffect, useState, useRef } from "react";
import { TbAlertCircle } from "react-icons/tb";
import Dbox from "src/common/components/dbox/dbox";
import { LuBrainCog } from "react-icons/lu";

const AddText = (props) => {
    const { genData } = props;
    const textareaRef = useRef(null);
    const MAX_CHARS = 1000;

    useEffect(() => {
        // Auto focus the textarea and move cursor to end
        if (textareaRef.current) {
            textareaRef.current.focus();
            const length = textareaRef.current.value.length;
            textareaRef.current.setSelectionRange(length, length);
        }
    }, []);

    const [aiText, setAiText] = useState("");
    const [showAi, setShowAi] = useState(false);

    const defTexts = [
        {
            id: "001",
            excerpt: `What's up`,
            text: `What's up! It feels like ages since we last talked. How've you been?`,
        },
        {
            id: "002",
            excerpt: `Yo!`,
            text: `Yo! How's life treating you? We should catch up soon!`,
        },
        {
            id: "003",
            excerpt: `Catch up`,
            text: `Hey, what's new? We need to hang out and catch up sometime soon!`,
        },
    ];

    const [defText, setDefText] = useState(null);
    let storedData = { ...genData };

    return (
        <div className="flex-1 flex flex-col gap-4  md: gap-8 py-0 md:py-10">
            <div className="flex flex-row items-start gap-6">
                {/* <div className="flex items-center justify-center text-lg bg-lightblue100  rounded-full w-12 h-12 mt-2">3</div> */}
                <div className="flex flex-col gap-1">
                    <div className="text-xl font-semibold">Add Your Script</div>
                    <div className="opacity-50">Add text that your video will use. This will be converted as speech.</div>
                </div>
            </div>

            <div className="flex flex-1 flex-row gap-5">
                <Dbox className={`relative  overflow-hidden flex-1`}>
                    <div className="flex flex-1 flex-row gap-10 p-4 md:p-10">
                        <div className="flex-1 flex flex-col gap-8">
                            {/* <div>Add some instructions here...</div> */}
                            <div className="border flex flex-col  flex-1 border-lightblue100 rounded-2xl relative overflow-hidden bg-lightblue50">
                                <textarea
                                    ref={textareaRef}
                                    value={genData.text}
                                    className="flex flex-1 w-full p-8"
                                    rows={8}
                                    maxLength={MAX_CHARS}
                                    placeholder={`Add text for you audio ...`}
                                    onChange={(e) => {
                                        genData.text = e.target.value;
                                        props.OnSet({ ...genData });
                                    }}
                                />
                               
                                {showAi && (
                                    <div className="flex flex-col bg-white border border-lightblue100 rounded-2xl relative overflow-hidden m-5">
                                        <textarea
                                            value={aiText}
                                            className="flex flex-1 w-full p-8  border !border-lightblue100"
                                            rows={8}
                                            placeholder={`eg. A birthday wish for my grandma`}
                                            onChange={(e) => {
                                                setAiText(e.target.value);
                                            }}
                                        />

                                        <div className="flex items-center justify-between p-5">
                                            <button className="button button-small px-4">Generate a script</button>
                                            <button
                                                className="button button-small button-gray px-4"
                                                onClick={() => {
                                                    setShowAi(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {!showAi && (
                                    <div className="flex flex-row justify-between items-center p-4">
                                        <button
                                            className="button button-white button-small flex gap-3 py-3 px-4"
                                            onClick={() => {
                                                //setShowAi(true);
                                            }}
                                        >
                                            <LuBrainCog className="text-lg" title={"Generate script with AI"} />
                                            Generate script with AI <span className="text-xs opacity-50">(Coming soon)</span>
                                        </button>


                                        <div className="text-sm opacity-50  ">
                                    {MAX_CHARS - (genData.text?.length || 0)} characters remaining
                                </div>
                                    </div>
                                )}
                            </div>

                            <div className="flex gap-2 items-center ">
                                <div ><TbAlertCircle className="text-lg text-amber-400" /></div>
                                <span className="opacity-40">For increased realism in your avatar script, please ensure proper punctuation is used.</span>
                            </div>

                            <div className="flex flex-col gap-5 pt-5">
                                <div>Try some of our default texts : </div>
                                <div className="flex flex-row gap-4">
                                    {defTexts.map((dtext, d) => (
                                        <div
                                            key={d}
                                            className={`transition-all px-3 py-1 border rounded-full cursor-pointer ${
                                                dtext.id === defText?.id ? `bg-blue text-white border-blue ` : `border-lightblue100  hover:bg-lightblue50`
                                            }`}
                                            onClick={() => {
                                                if (dtext.id === defText?.id) {
                                                    setDefText(null);
                                                    genData.text = storedData.text;
                                                    props.OnSet({ ...genData });
                                                } else {
                                                    setDefText({ ...dtext });

                                                    genData.text = dtext.text;
                                                    props.OnSet({ ...genData });
                                                }
                                            }}
                                        >
                                            {dtext.excerpt}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </Dbox>
            </div>
        </div>
    );
};

export default AddText;
