import Dbox from "src/common/components/dbox/dbox";

const EditCsv = () => {
    //https://docs.google.com/spreadsheets/d/1AFe0st2S0ep-xpHM-tKhpj-bfrI9Cnq3MLHst_MI1oU/edit
    return (
        <div className="flex flex-1 flex-col gap-10 pt-10">
            <div className="flex flex-col gap-3 text-center">
                <div className="text-xl font-semibold">4. Edit Your CSV</div>
                <div className="opacity-50">Please ensure that the appropriate data is entered in the corresponding columns.</div>
            </div>
            <Dbox className="h-full flex flex-1 flex-col relative overflow-hidden p-3">
            {/* <div>
                        <span className="opacity-50">Please refer to our CSV template to have the correct fields. </span>
                        
                    </div> */}

                <div className="h-full w-full border border-lightblue100 relative overflow-hidden rounded-2xl">
                    <iframe
                        className="h-full w-full"
                        src="https://docs.google.com/spreadsheets/d/1SIEN7bXYNFY9kg2Q6_CCsPWOeRBoJ-zNyUZmzXsiwos/edit?usp=sharing&rm=minimal&chrome=false"
                    ></iframe>
                </div>
            </Dbox>
        </div>
    );
};

export default EditCsv;
