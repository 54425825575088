import Scene from "../../scene/scene";

const Scenes = (props) => {
    const { project, setProject, selectedDetail, setSelectedDetail, selectedScene, setSelectedScene, stageSize } = props;

    
    return (
        <div>
            <div className="flex flex-col gap-6 pb-6 justify-center items-center overflow-y-auto">
                {selectedDetail.params.scenes.map((scene) => (
                    <Scene
                        previewOnly={true}
                        key={scene.id}
                        scene={scene}
                        setProject={setProject}
                        project={project}
                        selectedScene={selectedScene}
                        setSelectedScene={setSelectedScene}
                        selectedDetail={selectedDetail}
                        setSelectedDetail={setSelectedDetail}
                        stageSize={stageSize}
                    />
                ))}
            </div>
        </div>
    );
};

export default Scenes;
