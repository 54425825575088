import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getStoredUser, logout, setStoredUser } from "src/common/components/auth/auth";

import logoIconWhite from "src/common/assets/images/logo-icon-white.svg";
import Iicon from "src/common/components/iicon/iicon";
import { RiCopperCoinLine } from "react-icons/ri";
import { getUser } from "src/common/services/user";

const UserCard = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState();

    useEffect(() => {
        if (location.state?.todo === "coin-update") {
            updateUserData();
            navigate(location.pathname, { replace: true, state: null });
        }
    }, [location]);

    useEffect(() => {
        setUser(getStoredUser());
    }, []);

    const updateUserData = async () => {
        try {
            const user_res = await getUser({ id: getStoredUser().id });
            if (user_res?.[0]?.id) {
                setUser(user_res[0]);
                setStoredUser(user_res[0]);
            }
        } catch (error) {
            console.error("Failed to fetch user data:", error);
        }
    };

    return (
        <>
            {user && user.id && (
                <div className="flex flex-row md:flex-row items-center justify-center gap-3">

                    <div className="flex gap-1 bg-lightblue100/50 rounded-full px-1 md:px-4 py-1 md:py-3 items-center  text-sm md:text-base">
                        <RiCopperCoinLine className="text-xl text font-bold text-amber-500" />
                        <div className="font-semibold pr-2 md:pr-0">{user.coins}</div>
                        <div className="font-semibold pr-2 md:pr-0 hidden md:block">{parseInt(user.coins) === 1 ? "Coin" : "Coins"}</div>
                    </div>

                    <div className="flex flex-row-reverse md:flex-row gap-3 items-center">
                        <div
                            className="w-12 h-12 rounded-2xl bg-lightblue100 relative overflow-hidden border border-lightblue100 cursor-pointer"
                        onClick={() => {
                            navigate(`/admin/settings`);
                        }}
                    >
                        {user.thumbnail !== "" ? (
                            <img className="object-cover w-full h-full" src={user.thumbnail} />
                        ) : (
                            <div className="w-full h-full bg-blue flex items-center justify-center">
                                <img className="w-1/3" src={logoIconWhite} />
                            </div>
                            )}
                        </div>
                        {user.name !== "" && <div className="flex-1">{user.name}</div>}
                    </div>

                    <div
                        className="hidden md:block border-l border-zinc-500 pl-4 ms-3 cursor-pointer opacity-40 hover:opacity-100 transition-all"
                        onClick={() => {
                            logout();
                            setUser(null);
                            navigate("/login");
                        }}
                    >
                        <Iicon icon={`logout`} />
                    </div>
                </div>
            )}
        </>
    );
};

export default UserCard;
