import { useEffect, useRef, useState } from "react";

import { ReactLenis, useLenis } from "lenis/react";
import { useNavigate } from "react-router-dom";
import Logo from "src/common/components/logo/logo";
import logo from "src/common/assets/images/logo.svg";
import { FaTiktok, FaYoutube } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
gsap.registerPlugin(useGSAP);

const MarketingLayoutMinimal = (props) => {
    const lenis = useLenis((data) => {});
    const cursorRef = useRef(null);
    const navigate = useNavigate();
    useEffect(() => {
        const cursor = cursorRef.current;

        const onMouseMove = (e) => {
            gsap.to(cursor, {
                x: e.clientX - cursor.offsetWidth / 2,
                y: e.clientY - cursor.offsetHeight / 2,
                duration: 0.1,
                ease: "none",
            });
        };

        document.addEventListener("mousemove", onMouseMove);

        return () => {
            document.removeEventListener("mousemove", onMouseMove);
        };
    }, []);

    return (
        <div className="bg-white relative overflow-hidden [&_*]:cursor-none min-h-screen flex  flex-col">
            <div
                ref={cursorRef}
                className="fixed top-0 left-0 w-10 h-10 bg-blue/80 rounded-full pointer-events-none z-[9999] mix-blend-normal text-white flex items-center justify-center text-xl hidden md:flex"
            >
                +
            </div>
            <div className="header p-5 md:p-16 gap-6 flex items-center justify-between z-50  bg-white slideFromBot">
                <a
                    href="/home"
                    onClick={(e) => {
                        e.preventDefault();
                        navigate("/home");
                    }}
                    className="flex gap-4 items-center"
                >
                    <Logo className={`md:w-40`} /> <div className="text-xs bg-lightblue100/50 rounded-full p-2 py-1  font-bold">BETA</div>
                    <div className="border-l text-sm md:text-base border-black/40 pl-4">Create your Digital U</div>
                </a>
                <div className="flex gap-8">
                    {/* <div
                        className="cursor-pointer transition-all  px-6 py-3  hover:border-blue hover:text-blue  rounded-full text-base font-semibold"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate("/login");
                        }}
                    >
                        Login
                    </div> */}

                    <div
                        className="cursor-pointer transition-all border border-black px-6 py-3 hover:bg-blue hover:border-blue hover:text-white  rounded-full text-base font-semibold"
                        onClick={(e) => {
                            e.preventDefault();
                            navigate("/login");
                        }}
                    >
                        Login
                    </div>
                </div>
            </div>

            <div className="flex-1 flex">{props.children}</div>
       

            <div className="footer p-5 md:p-16 flex items-center justify-between z-50 bg-white slideFromTop">
                <div className="flex gap-6 items-center">
                    <div className="text-sm md:text-base font-semibold">Check us out : </div>
                    <div className="flex gap-4 md:gap-6 items-center">
                        <FaTiktok className="text-lg md:text-xl cursor-pointer hover:text-blue transition-all" />
                        <PiInstagramLogoFill className="text-lg md:text-2xl cursor-pointer hover:text-blue transition-all" />
                        <FaYoutube className="text-lg md:text-2xl cursor-pointer hover:text-blue transition-all" />
                    </div>
                </div>
                <div className="relative hidden md:block">
                    <div className="spinning-button group cursor-pointer flex absolute right-0 bottom-0 w-40 h-40 hover:w-44 hover:h-44 transition-all duration-300 flex items-center justify-center">
                        <div className="absolute group-hover:border-blue right-0 bottom-0 bg-black w-full h-full rounded-full border-2 border-black border-dashed bg-white spinning z-10 "></div>
                        <div className="relative p-10 z-20 text-center text-lg font-bold group-hover:text-blue" onClick={(e) => {
                            e.preventDefault();
                            navigate("/login");
                        }}>Try for free</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarketingLayoutMinimal;
