import { useEffect, useState, useRef } from "react";
import Dbox from "src/common/components/dbox/dbox";
import { dateFormatter, shorten } from "src/common/utils/utils";
import VideoPlayer from "src/common/components/video-player/video-player";
import { RiMore2Fill } from "react-icons/ri";
import Popup from "src/common/components/popup/popup";
import { deleteInputVideo } from "src/common/services/input";
import { useLocation, useNavigate } from "react-router-dom";
import More from "./card-video-more";

import MoreStudio from "src/modules/admin/studio-videos/card-studio-more";

const CardVideo = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { video, selectedId } = props;
    const [showMore, setShowMore] = useState();

    const [the_video_url, set_the_video_url] = useState();
    const [the_video_name, set_the_video_name] = useState();
    

    useEffect(() => {
        //video
        const video_url = video?.url ?? video?.videourl ?? "";
        set_the_video_url(video_url);
        //name
        const video_name = video?.name ?? (video?.url ? video.url.split("/").pop() : "");
        set_the_video_name(video_name);
    
        
    }, [video]);

    const handleVideo = async ({ opr, videoid, video }) => {
        switch (opr) {
            case "delete":
                if (videoid) {
                    const del_res = await deleteInputVideo({ videoid });
                    //console.log("del_res :", del_res);

                    props.OnDelete(true);
                }
                break;
            case "onuse":
                if (video) {
                    const thevideo = { ...video };

                    if (thevideo?.id === selectedId) {
                        props.OnUse({});
                    } else {
                        thevideo.type = "video";
                        props.OnUse({ ...thevideo });
                    }
                }
                break;

            default:
                break;
        }
    };

    return (
        <div
            data-id={video?.id}
            className={`transition-all flex flex-col relative overflow-hidden border-4 border-lightblue100/50 rounded-3xl
                    ${props.for === "select" && selectedId !== video?.id ? "hover:border-4 hover:border-indigo-500" : ""}
                    ${props.for === "select" && selectedId === video?.id ? "border-4 !border-indigo-500" : ""}
                    ${props.for === "thumbnail" ? "border-lightblue100/80" : ""}
           `}
        >
            <div
                className={`transition-all min-h-${props.thumbsize ? props.thumbsize : "400"} flex flex-col  relative overflow-hidden rounded-2xl ${
                    props.for === "select" && selectedId === video?.id ? "border-8 border-lightblue100 rounded-3xl" : ""
                }`}
            >
                <div
                    className={`relative max-h-full flex-1 cursor-pointer ${
                        props.for === "select" && selectedId === video?.id ? "bg-blue" : " bg-zinc-950"
                    }`}
                >
                    {the_video_url && (
                        <VideoPlayer
                            image={`${video.url}/frame1.jpg`}
                            src={the_video_url}
                            className={`${props.for === "select" && selectedId === video?.id ? "opacity-50" : ""}`}
                            rounded={false}
                            fluid={true}
                            fit={props.thumbfit ? props.thumbfit : "contain"}
                            hoverPlay={true}
                            actions={false}
                            onClick={() => {
                                if (the_video_url) {
                                    {
                                        props.for === "select" && handleVideo({ opr: "onuse", video });
                                    }
                                    {
                                        !props.for && navigate(location.pathname, { state: { show: "detail-video", data: video } });
                                    }
                                }
                                // setShowPrev(true);
                            }}
                        />
                    )}
                    <div
                        className="absolute w-full h-24 top-0 left-0 z-20"
                        style={{
                            background: `rgb(0,0,0)`,
                            background: `linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%)`,
                        }}
                    ></div>
                </div>

                <div className={`info-box p-5 pr-3 bg-white flex gap-4 items-center justify-between ${props.for ? "cursor-pointer" : ""}`}>
                    <div className="flex flex-col justify-center">
                        <div>{shorten(the_video_name)} </div>
                        <div className="opacity-50 text-xs">{dateFormatter(video.uploaddate)}</div>
                    </div>

                    {(!props.for || props.for === "studio") && (
                        <div>
                            <RiMore2Fill
                                className="text-black text-xl cursor-pointer "
                                onClick={() => {
                                    setShowMore(true);
                                }}
                            />
                        </div>
                    )}

                    {!props.for && showMore && (
                        <More
                            video={video}
                            OnClose={() => {
                                setShowMore(false);
                            }}
                        />
                    )}

                    {props.for === "studio" && showMore && (
                        <MoreStudio
                            video={video}
                            OnClose={() => {
                                setShowMore(false);
                            }}
                        />
                    )}

                    {props.actions && <div>{props.actions}</div>}
                </div>
            </div>
        </div>
    );
};

export default CardVideo;
