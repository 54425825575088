import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Timeline from "src/common/components/timeline/timeline";
import TimelineNavi from "src/common/components/timeline/timeline-navi";
import AdminLayout from "src/layouts/admin-layout";
import Iicon from "src/common/components/iicon/iicon";
import Preloader from "src/common/components/preloader/preloader";

import { upload } from "src/common/services/utils";
import { generateVideo } from "src/common/services/generate";

import AddInfo from "src/modules/admin/campaigns/create-campaign/add-info";
import AddVideo from "src/modules/admin/campaigns/create-campaign/add-video";
import AddCSV from "src/modules/admin/campaigns/create-campaign/add-csv";
import EditCsv from "src/modules/admin/campaigns/create-campaign/edit-csv";

const CampaignCreate = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [fetching, setFetching] = useState(false);

    const timelineData = [
        {
            id: "details",
            name: "Campaign Details",
        },
        {
            id: "select-video",
            name: "Select Video",
        },

        {
            id: "edit-csv",
            name: "Edit CSV",
        },
    ];

    const [timeline, setTimeline] = useState(timelineData);

    const [voiceTab, setVoiceTab] = useState(null);
    const [selectedTimeline, setSelectedTimeline] = useState(timeline[0]);
    const [canNext, setCanNext] = useState(false);
    const [campData, setCampData] = useState({ name: "" });

    useEffect(() => {
        if (params.campaignid) {
            //handleGenerate({ opr: "select", id: params.campaignid });
        }
    }, [params]);

    useEffect(() => {
        const todo = location?.state?.todo;
        const data = location?.state?.data;

        //console.log("todo ::", todo, data);

        switch (todo) {
            case "create":
                setSelectedTimeline(timeline[0]);
                setCampData({ name: "", ...data });

                break;

            case "regen":
                setCampData({ ...data });
                //console.log(data);
                break;

            default:
                break;
        }
    }, [location]);

    useEffect(() => {
        // //console.log('campData :::', campData)
        handleTimelineValidation(selectedTimeline);
    }, [selectedTimeline, campData]);

    useEffect(() => {
        if (voiceTab === "direct") {
            const directTl = timelineData.filter((tl) => tl.id !== "add-text");

            setTimeline(directTl);
        } else {
            setTimeline(timelineData);
        }
    }, [voiceTab]);

    const handleTimelineValidation = () => {
        setCanNext(true);
        // switch (selectedTimeline.id) {
        //     case "details":
        //         if (campData.name !== "") {
        //             setCanNext(true);
        //         }
        //         break;
        //     case "add-video":
        //         if (campData.file || campData.videoid || campData.videourl || campData.imageid || campData.imageurl) {
        //             setCanNext(true);
        //         }
        //         break;

        //     case "add-audio":
        //         if (campData.audio || campData.voiceid || campData.audiourl || campData.voicegender) {
        //             setCanNext(true);
        //         }

        //     case "add-text":
        //         if (campData.text && campData.text !== "") {
        //             setCanNext(true);
        //         }
        //         break;

        //     default:
        //         setCanNext(false);
        //         break;
        // }
    };

    const navigateTimeline = (direction) => {
        const indexSelected = timeline.findIndex((timeline) => timeline.id === selectedTimeline.id);
        if (indexSelected) {
            switch (direction) {
                case "next":
                    if (timeline[indexSelected + 1]) {
                        setSelectedTimeline(timeline[indexSelected + 1]);
                    }
                    break;
                case "prev":
                    if (timeline[indexSelected - 1]) {
                        setSelectedTimeline(timeline[indexSelected - 1]);
                    }
                    break;

                default:
                    break;
            }
        }
    };

    const handleCreate = async () => {
        setFetching(true);
        // //console.log("campData :: ", campData);
        // imageurl, videourl, audiourl, imageid, videoid, voiceid, voicegender
        const camp_input = { ...campData };

        if (camp_input.csv) {
            try {
                const csv_res = await upload({ file: camp_input.csv });
                //console.log("csv_res :: ", csv_res);
                camp_input.csv = csv_res.data[0].cache;
            } catch (error) {
                //console.log(error);
            }
        }

        //console.log("camp_input (input) ::", camp_input);
        // const gen_res = await generateVideo(camp_input);
        // //console.log("gen_res (output) ::", gen_res);

        navigate("/admin/campaigns");

        // setFetching(false);
        //  props.OnSuccess(true);
    };

    return (
        <>
            {fetching && <Preloader />}
            <AdminLayout
                header={(
                    <div className="flex items-center">
                        <div className="text-xl font-semibold">Create Campaign</div>

                        <Timeline
                            data={timeline}
                            canNext={canNext}
                            selected={selectedTimeline}
                            OnSelect={(item) => {
                                setSelectedTimeline(item);
                            }}
                        />
                    </div>
                )}
                footer={(
                    <div className="flex justify-between gap-8 items-center">
                        <TimelineNavi
                            data={timeline}
                            selected={selectedTimeline}
                            direction={"back"}
                            onClick={(prevTimeline) => {
                                setSelectedTimeline(prevTimeline);
                            }}
                        />

                        {/* {selectedTimeline.id !== "details" ||
                            (selectedTimeline.id === "details" && campData.id && (
                                <TimelineNavi
                                    data={timeline}
                                    canNext={canNext}
                                    selected={selectedTimeline}
                                    direction={"next"}
                                    onClick={(nextTimeline) => {
                                        setSelectedTimeline(nextTimeline);
                                    }}
                                />
                            ))} */}

                        <TimelineNavi
                            data={timeline}
                            canNext={canNext}
                            selected={selectedTimeline}
                            direction={"next"}
                            onClick={(nextTimeline) => {
                                setSelectedTimeline(nextTimeline);
                            }}
                        />

                        {selectedTimeline?.id === "edit-csv" && (
                            <button
                                // disabled={(campData.csv && campData.csv === "") || !campData.csv}
                                className="button flex gap-3 pr-3 items-center"
                                onClick={async () => {
                                    // if (canSubmit()) {
                                    await handleCreate();
                                    // }
                                }}
                            >
                                <div>Finish</div>
                                <Iicon icon={"arrow-right-white"} />
                            </button>
                        )}
                    </div>
                )}
            >
                {selectedTimeline?.id === "details" && (
                    <div className="flex flex-col gap-8 py-10 w-full max-w-screen-lg mx-auto slideFromRight">
                        <AddInfo
                            campData={campData}
                            OnSet={(data) => {
                                setCampData({ ...data });
                                navigateTimeline("next");
                            }}
                        />
                    </div>
                )}

                {selectedTimeline?.id === "select-video" && (
                    <div className="flex flex-col w-full  mx-auto slideFromRight">
                        <AddVideo
                            {...props}
                            campData={campData}
                            OnSet={(data) => {
                                setCampData({ ...data });
                                // navigateTimeline("next");
                            }}
                        />
                    </div>
                )}

                {selectedTimeline?.id === "edit-csv" && (
                    <div className=" flex-1 flex flex-col gap-8 py-10 w-full slideFromRight">
                        <EditCsv
                            {...props}
                            campData={campData}
                            OnSet={(data) => {
                                setCampData({ ...data });
                            }}
                        />
                    </div>
                )}

                {/* {selectedTimeline?.id === "add-csv" && (
                    <div className=" flex-1 flex flex-col gap-8 py-10 w-full max-w-screen-lg mx-auto slideFromRight">
                        <AddCSV
                            {...props}
                            campData={campData}
                            OnSet={(data) => {
                                setCampData({ ...data });
                            }}
                        />
                    </div>
                )} */}
            </AdminLayout>
        </>
    );
};

export default CampaignCreate;
