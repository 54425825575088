import React, { useState, useRef, useEffect } from "react";
import "./slider.css"; // Import custom styles

const CustomSlider = ({ initialValue, min, max, step, OnValue }) => {
    const [value, setValue] = useState(initialValue);
    const sliderRef = useRef(null);
    const midValue = (min + max) / 2;

    useEffect(()=>{
        OnValue(value)
    },[value])

    const calculateValue = (e) => {
        const slider = sliderRef.current;
        const rect = slider.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const percentage = Math.min(Math.max(offsetX / rect.width, 0), 1);

        // Calculate decimal value with proper step
        const rawValue = min + percentage * (max - min);
        const newValue = Math.round(rawValue / step) * step;

        setValue(newValue.toFixed(2)); // Set value rounded to 2 decimal places
    };

    const handleMouseMove = (e) => {
        calculateValue(e);
    };

    const handleMouseDown = () => {
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
    };

    const handleTouchMove = (e) => {
        calculateValue(e.touches[0]);
    };

    const handleTouchStart = () => {
        document.addEventListener("touchmove", handleTouchMove);
        document.addEventListener("touchend", handleTouchEnd);
    };

    const handleTouchEnd = () => {
        document.removeEventListener("touchmove", handleTouchMove);
        document.removeEventListener("touchend", handleTouchEnd);
    };

    const thumbPosition = ((value - min) / (max - min)) * 100;

    return (
        <div className="slider-container">
            <div
                className="slider-track"
                ref={sliderRef}
                onMouseDown={handleMouseDown}
                onTouchStart={handleTouchStart}
                style={{
                    background: `linear-gradient(to right, #6676ff ${thumbPosition}%, #ddd ${thumbPosition}%)`,
                }}
            >
                <div
                    className="slider-thumb"
                    style={{
                        left: `${thumbPosition}%`,
                    }}
                />
            </div>

            {/* Slider Labels for Min, Mid, Max */}
            <div className="slider-labels">
                <span>{min.toFixed(2)}</span>
                <span>{midValue.toFixed(2)}</span>
                <span>{max.toFixed(2)}</span>
            </div>

        </div>
    );
};

export default CustomSlider;
