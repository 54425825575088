const Dbox = (props) => {
    return ( 
        <div 
            style={props.style}
            className={`flex flex-col bg-white dshadow border border-lightblue100 rounded-3xl ${props.className ? props.className : ''}`}
            onClick={(e)=>{
                if(props.onClick){
                    props.onClick(e)
                }
            }}
        >
            {props.children}
        </div>

    );
}
 
export default Dbox;