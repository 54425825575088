import { useEffect, useState, useRef } from "react";
import arrowLeft from "src/pages/tester/images/arrow-left.svg";
import arrowRight from "src/pages/tester/images/arrow-right.svg";
import iconClear from "src/pages/tester/images/icon-clear.svg";
import iconShare from "src/pages/tester/images/icon-share.svg";
import iconDownload from "src/pages/tester/images/icon-download.svg";
import ada from "src/common/assets/vids/ada.mp4";
import { useLocation, useNavigate } from "react-router-dom";
import { addToQueue, getQueue } from "src/common/services/utils";

import iconVideoMed from "src/pages/tester/images/icon-video-med.svg";
import { liveportrait, liveportraitAudio } from "src/common/services/OLD/tester";

const Video = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [fetching, setFetching] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [canGenerate, setCanGenerate] = useState(false);

    const [video, setVideo] = useState(null);
    const pollCount = useRef(0);

    useEffect(() => {
        setErrorMsg(null);
        //console.log(location);
        if (location.state && (location.state.audio || location.state.video ) && location.state.image ) {
            setCanGenerate(true);
        } else {
            navigate("/tester/character");
        }
    }, [location]);

    const pollQue = async (queueid) => {
        //console.log(pollCount.current);
        if (pollCount.current < 150) { 
            // 15  mins timeout
            await new Promise((res) => setTimeout(res, 6000));

            const que_res = await getQueue({ id: queueid }, true);
            //console.log("que_res :: ", que_res);

            if (que_res) {
                if (que_res.state === "complete") {
                    setVideo(que_res.result);
                    setFetching(false);
                } else if (que_res.state.indexOf("error-") !== -1) {
                    //HAS ERROR NEED TO MANAGE
                    setFetching(false);
                } else {
                    pollCount.current++;
                    await pollQue(queueid);
                }
            }
        }else{
            pollCount.current = 0
            setErrorMsg('Timed Out')
            setFetching(false);
        }
    };

    const handleGenerate = async ({ type }) => {
        setErrorMsg(null);
        setFetching(true);

        // const parsedImgUrl = "s3://squadz-dev-bucket-02/" + location.state.character.split("download/")[1].replace("//", "/");
        // const queInput = {
        //     modelid: "d7f8610f-99fa-453b-9d65-92abab242bd0", // luka model
        //     //  modelid:'3961c66a-31b0-459a-9757-9f28831cb840',
        //     text: location.state.text,
        //     userid: `11111111-1111-1111-1111-111111111111`,
        //     tts: "elevenlabs",
        //     overlay: "body",
        //     bg: "true",
        //     headmov: "",
        //     aud: "",
        //     img: parsedImgUrl,
        //     vid: "",
        //     appmode: "",
        // };
        // //console.log("queInput::", queInput);
        // let que_res = await addToQueue({ key: "liveportrait", value: JSON.stringify(queInput), useAdminAuth: true });
        let que_res;

        if (type === "audio") {
            que_res = await liveportraitAudio("d7f8610f-99fa-453b-9d65-92abab242bd0", location.state.image, location.state.audio);
        } else if (type === "video") {
            const driver = (location.state.video) ? location.state.video : `https://svc.staging.squadz.live/download/5c4c6b88-fc88-4fb9-8fe9-8de502f14e74/2266805418/output.mp4`
            que_res = await liveportrait("d7f8610f-99fa-453b-9d65-92abab242bd0", location.state.image, driver);
        }

        //console.log(que_res);
        if (que_res && que_res.id) {
            await pollQue(que_res.id);
        } else {
            setFetching(false);
        }
    };

    return (
        <div className="bg-zinc-950 min-h-full w-full text-white flex  text-base">
            {fetching && (
                <div className="pre-loader bg-zinc-950/50 absolute w-full h-full top-0 left-0 z-40 flex items-center justify-center backdrop-blur-sm">
                    <div className="flex flex-row gap-1 items-center justify-center">
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Generating video...
                    </div>
                </div>
            )}
            <div className="container container-sm flex flex-col flex-1 mx-auto p-10 max-w-3xl relative slideFromLeft">
                {errorMsg && (
                    <div className="absolute left-0 top-5 w-full bg-rose-500/50 backdrop-blur p-2 rounded-2xl text-center flex flex-row">
                        <div className="flex-1">{errorMsg}</div>
                        <img
                            src={iconClear}
                            className="cursor-pointer"
                            onClick={() => {
                                setErrorMsg(null);
                            }}
                        />
                    </div>
                )}
                {/* HEADER */}
                <div className="header flex flex-row py-5 items-center">
                    <div
                        className="w-32 opacity-30 cursor-pointer hover:opacity-80 flex flex-row gap-5"
                        onClick={() => {
                            navigate("/tester/audio",{
                                state: {
                                    audio: location.state?.audio,
                                    image: location.state?.image,
                                    video: location.state?.video,
                                },
                            });
                        }}
                    >
                        <img src={arrowLeft} />
                        Previous
                    </div>
                    <div className="flex-1 text-center text-xl">Video</div>
                    <div className="w-32 opacity-30 cursor-pointer hover:opacity-80 flex flex-row gap-5 justify-end">
                        {/* Next
                        <img src={arrowRight} /> */}
                    </div>
                </div>

                {/* BODY */}
                <div className="body flex-1 flex flex-col  flex-1">
                    <div className="flex-1 flex flex-col items-center justify-center gap-10  ">
                        {video === null && (
                            <div className="aspect-square  min-w-full max-h-600 rounded-4xl relative overflow-hidden border border-dashed border-zinc-500/30 flex items-center justify-center">
                                <div className="opacity-30 flex flex-col items-center justify-center gap-10">
                                    <img src={iconVideoMed} />
                                    Your video will appear here
                                </div>
                            </div>
                        )}

                        {video && (
                            <video
                                src={video}
                                controls
                                playsInline
                                muted
                                autoPlay
                                className="aspect-square max-h-600 rounded-3xl relative overflow-hidden object-cover"
                            />
                        )}
                        <div className="flex flex-col relative w-full overflow-hidden">
                            {video !== null && (
                                <div className="flex w-full items-center justify-center p-6 gap-20">
                                    <a
                                        href={video}
                                        target="_blank"
                                        download={video}
                                        className="btn cursor-pointer rounded-3xl py-3 px-6 border-2 border-orange-500 hover:translate-y-1 flex gap-4 items-center"
                                    >
                                        <img src={iconDownload} className="w-5" />
                                        Download
                                    </a>
                                    <div className="btn cursor-pointer rounded-3xl py-3 px-6 border-2 border-orange-500 hover:translate-y-1 flex gap-4 items-center">
                                        <img src={iconShare} className="w-5" />
                                        Share
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* FOOTER */}
                <div className="footer flex flex-row py-5 gap-10">
                    <button
                        className={`transition-all flex-1 btn rounded-3xl py-6 px-20 text-white text-center bg-gradient-to-r from-red-500 to-amber-500 ${
                            canGenerate ? "opacity-100  hover:translate-y-1 cursor-pointer " : "opacity-40 cursor-not-allowed"
                        }`}
                        onClick={() => {
                            if (canGenerate) {
                                handleGenerate({type:'audio'});
                            }
                        }}
                    >
                        Generate With Audio
                    </button>


                    <button
                        className={`transition-all flex-1 btn rounded-3xl py-6 px-20 text-white text-center bg-gradient-to-r from-red-500 to-amber-500 ${
                            canGenerate ? "opacity-100  hover:translate-y-1 cursor-pointer " : "opacity-40 cursor-not-allowed"
                        }`}
                        onClick={() => {
                            if (canGenerate) {
                                handleGenerate({type:'video'});
                            }
                        }}
                    >
                        Generate With Video
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Video;
