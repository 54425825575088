import { HiOutlineSpeakerWave } from "react-icons/hi2";
import VideoPlayer from "src/common/components/video-player/video-player";
import CardAudio from "src/modules/admin/voices/card-audio";
import { GrUploadOption } from "react-icons/gr";

const AudioUpload = (props) => {
    const { genData } = props;
    return (
        <div className="flex flex-1">
            <div className="flex w-full min-h-24 border border-dashed border-blue rounded-2xl bg-lightblue80 items-center justify-center  relative overflow-hidden">
                {genData.audio && genData.audio.name && (
                    <div className="min-w-full md:max-h-400 md:min-h-400 flex flex-col items-center justify-center p-4 gap-4">
                        {/* <div className="bg-white rounded-full p-6">
                            <HiOutlineSpeakerWave className="text-xl" />
                        </div> */}

                        {genData.audio.name && (
                            <CardAudio
                                className={`w-full`}
                                voice={{
                                    name: genData.audio.name,
                                    url: URL.createObjectURL(genData.audio),
                                }}
                                fo="thumbnail"
                            />
                            // <VideoPlayer
                            //     src={URL.createObjectURL(genData.audio)}
                            //     fluid={true}
                            //     className="object-contain min-w-14 min-h-14 rounded-2xl relative overflow-hidden bg-black"
                            // />
                        )}
                        {/* <video src={URL.createObjectURL(genData.audio)} playsInline muted className="max-h-full rounded-2xl relative overflow-hidden" /> */}
                        <div className="flex flex-row items-center gap-5">
                            <div>{genData.audio.name}</div>
                            <button
                                className="button button-small button-white px-3 py-1 text-xs"
                                onClick={() => {
                                    delete genData.audio;
                                    delete genData.voiceid;
                                    delete genData.audiourl;
                                    delete genData.voicegender;

                                    props.OnSet({ ...genData });
                                }}
                            >
                                remove
                            </button>
                        </div>
                    </div>
                )}
                <label className="relative cursor-pointer">
                    {!genData.audio && (
                        <div className="flex flex-col gap-4 items-center justify-center">
                            <button className="relative z-10 button-white px-3 py-3 pr-5 rounded-3xl cursor-pointer flex  items-center gap-4 ">
                                <GrUploadOption className="text-xl" />
                                Upload Video or Audio
                            </button>

                            <div className="opacity-50">Accepts video and audio types.</div>
                        </div>
                    )}
                    <input
                        type="file"
                        title=""
                        value={""}
                        accept={`video/*, audio/*`}
                        className="absolute z-50 left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                        onChange={async (e) => {
                            //console.log(e.target.files[0]);

                            genData.audio = e.target.files[0];
                            delete genData.voiceid;
                            delete genData.audiourl;
                            delete genData.voicegender;

                            props.OnSet({ ...genData });
                        }}
                    />
                </label>
            </div>
        </div>
    );
};

export default AudioUpload;
