import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";

import { MdOutlineEdit } from "react-icons/md";
import { LuMinusCircle } from "react-icons/lu";
import { GoGrabber } from "react-icons/go";
import { IoIosArrowBack } from "react-icons/io";
import { IoClose } from "react-icons/io5";

import Project from "src/modules/admin/studio/sidebar/project/project";
import UiDSection from "src/modules/admin/studio/components/ui-dsection";
import Input from "src/modules/admin/studio/input/input";
import Step from "src/modules/admin/studio/step/step";
import UiColorPicker from "../components/ui-color-picker";
import { generateScene } from "../studio-funcs";



const Sidebar = (props) => {
    const navigate = useNavigate();
    const { screens, project, setProject, selectedScene, setSelectedScene, selectedDetail, setSelectedDetail, setShowStepAdd, stageSize ,showInputAdd,setShowInputAdd} = props;
    const [showActions, setShowActions] = useState(false);

    const [errorMsg, setErrorMsg] = useState(null);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleScene = async ({ opr, scene, input }) => {
        switch (opr) {
            case "update":
                setSelectedScene((prev) => ({ ...prev, ...input }));
                setProject((prev) => ({
                    ...prev,
                    scenes: prev.scenes.map((s) => (s.id === selectedScene.id ? { ...s, ...input } : s)),
                }));
                break;

            case "generate":
                setSelectedScene((prev) => ({ ...prev, generating: true }));
                setProject((prev) => ({
                    ...prev,
                    scenes: prev.scenes.map((s) => (s.id === scene.id ? { ...s, generating: true } : s)),
                }));
                try {
                    let sceneBase64 = null;
                    // convert background to base64
                    if (scene.backgroundRef) {
                        sceneBase64 = scene.backgroundRef.toDataURL({
                            pixelRatio: 1,
                            width: project.width,
                            height: project.height,
                        });
                    }

                    // convert text steps to base64
                    const stepsWithBase64 = scene.steps.map((step) => {
                        if (step.params.textRef) {
                            return {
                                ...step,
                                base64: step.params.textRef.toDataURL({
                                    pixelRatio: 1,
                                    width: step.params.width,
                                    height: step.params.height,
                                }),
                            };
                        }
                        return step;
                    });

                    const gen_res = await generateScene({
                        project,
                        scene: {
                            ...scene,
                            steps: stepsWithBase64,
                            sceneBase64,
                        },
                        stageSize,
                    });
                    console.log("gen_res :: ", gen_res);
                    if (gen_res[0]?.result) {
                        // const generatedStep = StepClass.generated({
                        //     name: "Generated",
                        //     description: "Generated scene",
                        //     params: {
                        //         result: gen_res[0].result,
                        //     },
                        // });

                        const scene_res = {
                            sceneId: scene.id,
                            src: gen_res[0].result,
                        };

                        console.log("scene.id :: ", scene.id);
                        // if (scene.id === selectedScene.id) {
                        //     setSelectedScene((prev) => ({
                        //         ...prev,
                        //         result: scene_res,
                        //     }));
                        // } else {
                        //     setProject((prev) => ({
                        //         ...prev,
                        //         scenes: prev.scenes.map((s) => (s.id === scene.id ? { ...s, result: scene_res } : s)),
                        //     }));
                        // }
                        
                        setProject((prev) => ({
                            ...prev,
                            scenes: prev.scenes.map((s) => (s.id === scene.id ? { ...s, result: scene_res } : s)),
                        }));

                    } else {
                        setErrorMsg("Failed to generate scene");
                    }
                } catch (error) {
                    setErrorMsg("Failed to generate scene");
                    console.log("error :: ", error);
                } finally {
                    setSelectedScene((prev) => ({ ...prev, generating: false }));
                    setProject((prev) => ({
                        ...prev,
                        scenes: prev.scenes.map((s) => (s.id === scene.id ? { ...s, generating: false } : s)),
                    }));
                }
                break;
            default:
                break;
        }
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over.id) {
            setSelectedScene((prevScene) => {
                const oldIndex = prevScene.steps.findIndex((step) => step.id === active.id);
                const newIndex = prevScene.steps.findIndex((step) => step.id === over.id);
                const newSteps = arrayMove(prevScene.steps, oldIndex, newIndex);
                return { ...prevScene, steps: newSteps };
            });
        }
    };

    return (
        <>
            {errorMsg && (
                <div className="error-msg absolute top-0 left-0 w-full z-50 p-2 rounded-2xl">
                    <div className="slideFromBot error-msg p-2 bg-amber-100 text-amber-600 border border-amber-300 z-50 rounded-2xl text-center flex items-center justify-center">
                        <div className="flex-1">{errorMsg}</div>
                        <IoClose className="w-5 h-5  cursor-pointer" onClick={() => setErrorMsg(null)} />
                    </div>
                </div>
            )}
            <div className="sidebar-container relative flex flex-col w-300 h-full gap-4 bg-white border border-lightblue100 rounded-2xl ">
                <div className="absolute top-0 left-0 w-full h-full overflow-y-auto overflow-x-hidden flex flex-col">
                    {!selectedScene && <Project screens={screens} project={project} setProject={setProject} />}
                    {selectedScene && (
                        <div className="scene-detail h-full flex-1 flex flex-col relative">
                            <UiDSection>
                                <div className="flex flex-row gap-2 items-center">
                                    <div
                                        className="cursor-pointer opacity-50 hover:opacity-100 -translate-x-2"
                                        onClick={() => {
                                            setSelectedScene(null);
                                            setSelectedDetail(null);
                                        }}
                                    >
                                        <IoIosArrowBack className="text-md" />
                                    </div>
                                    <input
                                        type="text"
                                        className="!p-0 w-full"
                                        placeholder="Add Scene Name"
                                        value={selectedScene.name}
                                        onChange={(e) => handleScene({ opr: "update", input: { name: e.target.value } })}
                                    />
                                    <MdOutlineEdit className="text-lg opacity-40 cursor-pointer" />
                                </div>
                            </UiDSection>

                            {(!selectedScene?.steps[0] || selectedScene?.steps[0].type !== "transition") && (
                                <Input
                                   
                                    showInputAdd={showInputAdd}
                                    setShowInputAdd={setShowInputAdd}
                                    selectedScene={selectedScene}
                                    setSelectedScene={setSelectedScene}
                                    selectedDetail={selectedDetail}
                                    setSelectedDetail={setSelectedDetail}
                                />
                            )}

                            {selectedScene.steps.length > 0 && (
                                <div className="scene-steps-actions flex gap-3 text-sm p-5 pb-0">
                                    <div
                                        className={` cursor-pointer ${showActions ? "text-blue" : "text-black/30 hover:text-black"}`}
                                        onClick={() => setShowActions(!showActions)}
                                    >
                                        {showActions ? "Done" : "Edit"}
                                    </div>
                                </div>
                            )}

                            <UiDSection className="flex-1 flex flex-col">
                                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                                    <SortableContext items={selectedScene.steps.map((step) => step.id)} strategy={verticalListSortingStrategy}>
                                        <div className="scene-steps-container flex-1 flex flex-col gap-3 relative">
                                            <div className="scene-steps flex flex-col gap-3 relative">
                                                {selectedScene.steps.length > 1 && (
                                                    <div className="timeliner absolute top-0 left-1/2 -translate-x-1/2 w-[1px] h-full bg-indigo-800/30 "></div>
                                                )}
                                                {selectedScene.steps.map((step) => (
                                                    <Step
                                                        key={step.id}
                                                        step={step}
                                                        selectedScene={selectedScene}
                                                        setSelectedScene={setSelectedScene}
                                                        selectedDetail={selectedDetail}
                                                        setSelectedDetail={setSelectedDetail}
                                                        showActions={showActions}
                                                    />
                                                ))}
                                            </div>
                                            {selectedScene.inputs.length > 0 && (
                                                <div
                                                    className="transition scene-step-add flex items-center gap-2 p-3 group border border-dashed border-indigo-800/30 rounded-xl cursor-pointer hover:border-blue hover:bg-blue/10 text-black/30 hover:text-blue items-center justify-center"
                                                    onClick={() => setShowStepAdd(true)}
                                                >
                                                    <div className="scene-step-action w-6 flex items-center items-center justify-center text-lg">+</div>
                                                    <div className="scene-step-add-type flex-1">Add</div>
                                                </div>
                                            )}
                                        </div>
                                    </SortableContext>
                                </DndContext>
                            </UiDSection>

                            <UiDSection title="Design" className="!border-b !border-transparent">
                                <UiColorPicker
                                    pickerPos="top"
                                    showDefaultColors={false}
                                    title="Background"
                                    value={selectedScene.background}
                                    setValue={(val) => handleScene({ opr: "update", input: { background: val } })}
                                />
                            </UiDSection>

                            {(selectedScene.steps.length > 0 || selectedScene.inputs.length > 0) && (
                                <div className="sticky bottom-0 bg-white border-t border-lightblue100 rounded-b-2xl p-5">
                                    <button
                                        disabled={selectedScene.generating}
                                        className={`button px-4 py-4 rounded-xl w-full ${selectedScene.generating ? "loading cursor-not-allowed" : ""}`}
                                        onClick={() => {
                                            if (!selectedScene.generating) {
                                                handleScene({ opr: "generate", scene: selectedScene });
                                            }
                                        }}
                                    >
                                        {selectedScene.generating ? "Generating" : "Generate Scene"}
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Sidebar;
