import Slider from "src/common/components/slider/slider";

const RangeSlider = ({ title, initialValue, min, max, step, OnValue }) => {
    return (
        <div className="flex flex-col gap-4">

            <div className="flex flex-row items-center justify-between pb-5">
                <div className="text-sm capitalize">{title}</div>
                <div>{initialValue}</div>
            </div>
            <Slider initialValue={initialValue} min={min} max={max} step={step} OnValue={OnValue} />
        </div>
    );
};

export default RangeSlider;   