import { textFonts } from "src/common/data/fonts";
import UiSelect from "../../components/ui-select";
import UiColorPicker from "../../components/ui-color-picker";

const Font = (props) => {
    const { selectedDetail, setSelectedDetail } = props;
    // Create font family options
    const fontFamilyOptions = Object.keys(textFonts).map((font) => ({
        label: font,
        value: font,
        style: { fontFamily: font }
    }));

    const fontSizes = [10, 11, 12, 13, 14, 15, 16, 20, 24, 32, 36, 40, 48, 64, 96, 128];
    const fontSizeOptions = fontSizes.map(size => ({
        label: `${size}px`,
        value: `${size}`,
    }));

    // Create font style options based on selected font family
    const getFontStyleOptions = (selectedFont) => {
        if (!selectedFont) return [];
        return textFonts[selectedFont].map((style) => ({
            label: style.style,
            value: style.value,
        }));
    };

    return (
        <div className="font-controls flex flex-col gap-4">
            <div className="flex flex-row items-center gap-2">
                <UiSelect
                    label="Font Family"
                    options={fontFamilyOptions}
                    value={selectedDetail?.params?.font?.name}
                    optionStyle={(option) => option.style}
                    onChange={(value) => setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, font: { ...prev.params.font, name: value } } }))}
                />
                <UiSelect
                    label="Font Style"
                    options={getFontStyleOptions(selectedDetail?.params?.font?.name)}
                    value={selectedDetail?.params?.font?.style || getFontStyleOptions(selectedDetail?.params?.font?.name)[0]?.value}
                    onChange={(value) => setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, font: { ...prev.params.font, style: value } } }))}
                />
            </div>
            <div className="flex flex-row items-center gap-2">
                <UiSelect
                    label="Font Size"
                    options={fontSizeOptions}
                    value={selectedDetail?.params?.font?.size}
                    onChange={(value) => setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, font: { ...prev.params.font, size: value } } }))}
                />
                <UiColorPicker
                    pickerPos="bottom"
                    label="Font Color"
                    showDefaultColors={false}
                    value={selectedDetail?.params?.font?.color}
                    setValue={(value) => setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, font: { ...prev.params.font, color: value } } }))}
                
                />
            </div>
        </div>
    );
};

export default Font;
