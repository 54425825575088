import { useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { handleDownload, getNameFromUrl } from "../../../common/utils/utils";

const More = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const ref = useRef(null);
    const { image } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            // event.stopPropagation();
            if (ref.current && !ref.current.contains(event.target)) {
                props.OnClose(true);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="more-box absolute top-0 left-0 w-full h-full z-30 slideFromLeftFast">
            <div
                ref={ref}
                className="absolute bottom-4 py-4 min-w-52 right-4 bg-white shadow-lg shadow-indigo-600/10 border border-zinc-500/20 rounded-xl z-20 flex flex-col gap-2"
            >
                <div
                    className="px-6 py-1 transition cursor-pointer hover:opacity-70 hover:translate-x-0.5"
                    onClick={() => {
                        const theimage = {
                            imageid: image.id,
                            name: getNameFromUrl(image.url, true),
                        };

                        navigate("/admin/generate-video", { state: { todo: "create", data: theimage } });
                    }}
                >
                    Generate Video
                </div>

                <div
                    className="px-6 py-1 transition cursor-pointer hover:opacity-70 hover:translate-x-0.5 hover:text-black"
                    onClick={() => handleDownload(image.url, getNameFromUrl(image.url))}
                >
                    Download
                </div>

                <div
                    className="px-6 transition cursor-pointer hover:opacity-70 hover:translate-x-0.5 text-rose-500"
                    onClick={() => {
                        const theimage = { ...image };
                        theimage.TYPE = "image";
                        navigate(`${location.pathname}`, { state: { show: "delete", data: theimage } });
                        // props.OnDelete(image);
                    }}
                >
                    Delete
                </div>
            </div>
        </div>
    );
};

export default More;
