import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";

import FormInput from "../form/input";
import FormPhone from "../form/phone";
import { FaRegCircleCheck } from "react-icons/fa6";
import { MMS } from "src/common/services/share";
import Preloader from "../preloader/preloader";
import VideoPlayer from "../video-player/video-player";

const ShareMMS = (props) => {
    const {video}=props;
    const [onSent, setOnSent] = useState(null);
    const [fetching, setFetching] = useState(false);

    const [smsData, setSmsData] = useState({
        recipient_number: "",
        content: `Hey! Check this out. ${video?.result} ` + "\n" + `Got it from www.digitalu.live`,
        videourl: video?.result,
    });

    useEffect(() => {
        if (onSent === true) {
            const timeout = setTimeout(() => {
                props.OnClose(true);
                clearTimeout(timeout);
            }, 1500);
        }
    }, [onSent]);

    const sendMessage = async () => {
        if (smsData && smsData.recipient_number != "") {
            setFetching(true);
            try {
                const send_res = await MMS(smsData);
                //console.log(send_res);
                setOnSent(true);
            } catch (error) {
                //console.log("error:", error);
            }
            setFetching(false);
        }
    };
    return (
        <>
            {fetching && <Preloader />}
            {!onSent && (
                <div className="flex flex-col gap-10 p-10">
                    <div className="flex items-start justify-between gap-4 mb-4">
                        <div className="text-xl font-medium tracking-snug">Share via MMS</div>
                        <IoMdClose
                            className="text-xl opacity-40 hover:opacity-100 cursor-pointer"
                            onClick={() => {
                                props.OnClose(true);
                            }}
                        />
                    </div>

                    <div className="flex flex-col gap-5">
                        {/* <div className="font-semibold">Recipient Number : </div> */}
                        <FormPhone
                            OnChange={(phone) => {
                                smsData.recipient_number = phone;
                                setSmsData({ ...smsData });
                            }}
                        />

                        <div className="font-semibold">Message : </div>
                        <div className="flex gap-3">
                           
                            <div className="aspect-squre w-1/3 relative rounded-2xl overflow-hidden">
                                <VideoPlayer src={video?.result} image={video?.result+`/frame1.jpg`} actions={false} hoverPlay={true} fluid={true}/>
                            </div>
                            <FormInput
                                type={"textarea"}
                                className={`flex-1`}
                                // label={"Message :"}
                                value={smsData.content}
                                onChange={(e) => {
                                    smsData.content = e.target.value;
                                    setSmsData({ ...smsData });
                                }}
                            />
                        </div>
                        <button className="button" disabled={smsData.recipient_number === ""} onClick={async () => await sendMessage()}>
                            Send MMS
                        </button>
                    </div>
                </div>
            )}

            {onSent && (
                <div className="flex-1 min-h-72 flex flex-col items-center justify-center gap-10">
                    <FaRegCircleCheck className="text-teal-500 text-6xl" />
                    <div>Message sent successfully.</div>
                </div>
            )}
        </>
    );
};

export default ShareMMS;
