import React, { useState, useEffect } from "react";
import Preloader from "src/common/components/preloader/preloader";
import { loginUser } from "src/common/services/user";
import { useLocation, useNavigate } from "react-router-dom";

// Helper function to validate and fix JSON
const validateAndFixJson = (jsonString, setErrorCallback) => {
    if (!jsonString) {
        setErrorCallback("");
        return true;
    }
    try {
        JSON.parse(jsonString);
        setErrorCallback("");
        return true;
    } catch (error) {
        let fixedJson = jsonString.replace(/,\s*([\]}])/g, "$1");
        fixedJson = fixedJson.replace(/([{,]\s*)([a-zA-Z0-9_]+)\s*:/g, '$1"$2":');
        try {
            JSON.parse(fixedJson);
            setErrorCallback("JSON was auto-corrected");
            return fixedJson;
        } catch (secondError) {
            setErrorCallback("Invalid JSON format");
            return null;
        }
    }
};

// JSONForm component to handle individual form logic
const JSONForm = ({
    jsonData,
    setJsonData,
    response,
    setResponse,
    fetching,
    setFetching,
    token,
    jsonError,
    setJsonError,
    onFirstFormResponse,
    autoSubmit,
    setAutoSubmit,
}) => {
    const handleJsonChange = (e) => {
        const input = e.target.value;
        setJsonData(input);
        validateAndFixJson(input, setJsonError);
    };

    const submitForm = async (e) => {
        e?.preventDefault();
        setResponse("");

        const validJson = validateAndFixJson(jsonData, setJsonError);
        if (!validJson) return;

        try {
            setFetching(true);
            const url = "https://svc.staging.squadz.live/api/";

            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`,
                },
                body: jsonData,
            });

            if (response.ok) {
                const jsonResponse = await response.json();
                setResponse(JSON.stringify(jsonResponse, null, 2));

                if (onFirstFormResponse && jsonResponse.result && jsonResponse.result[0]?.id) {
                    onFirstFormResponse(jsonResponse);
                }
            } else {
                setResponse(`Error: ${response.status}`);
            }
        } catch (error) {
            setResponse("There was an error making the request.");
        } finally {
            setFetching(false);
        }

        setAutoSubmit("submitted");
    };

    useEffect(() => {
        if (autoSubmit === true && jsonData && !jsonError) {
            submitForm(); // Automatically submit the form if autoSubmit is true
        }
    }, [autoSubmit, jsonData, jsonError]);

    return (
        <form className="flex flex-1 flex-row " onSubmit={submitForm}>
            <div className="flex-1 flex flex-col gap-4 bg-lightblue100/40 border border-lightblue100 rounded-xl rounded-e-none border-r-0 p-5 flex-1">
                <label htmlFor="json" className="uppercase opacity-60 text-xs">
                    JSON:
                </label>
                <textarea className="tracking-wide leading-loose flex-1 p-5" id="json" name="json" value={jsonData} onChange={handleJsonChange} />
                <div className="flex flex-row justify-between items-center">
                    {jsonError && <span style={{ color: jsonError === "JSON was auto-corrected" ? "orange" : "red" }}>{jsonError}</span>}
                    <div className="flex-1 flex justify-end">
                        {!fetching ? (
                            <input className="button bg-blue p-3 px-4" type="submit" value="Submit" disabled={!token || jsonError || !jsonData} />
                        ) : (
                            <div className="p-3 loading">Please wait</div>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex-1 flex flex-col gap-4 border border-lightblue100 rounded-xl  rounded-s-none  p-5 flex-1 relative">
                <label htmlFor="response" className="uppercase opacity-60  text-xs">
                    Server Response:
                </label>
                {fetching && <Preloader />}
                <textarea id="response" className="tracking-wide leading-loose flex-1 p-5" name="response" value={response} readOnly />
            </div>
        </form>
    );
};

// Main TesterApi component
const TesterApi = () => {
    const navigate = useNavigate();
    const [jsonData1, setJsonData1] = useState("");
    const [response1, setResponse1] = useState("");
    const [jsonData2, setJsonData2] = useState(""); // For second form
    const [response2, setResponse2] = useState(""); // For second form
    const [token, setToken] = useState(null);
    const [fetching1, setFetching1] = useState(false);
    const [fetching2, setFetching2] = useState(false); // For second form
    const [jsonError1, setJsonError1] = useState("");
    const [jsonError2, setJsonError2] = useState(""); // For second form
    const [autoSubmit, setAutoSubmit] = useState(false); // Track if auto-submit is needed

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const queryParam = params.get("query");

    useEffect(() => {
        if (queryParam && token) {
            setJsonData1(queryParam);
            validateAndFixJson(queryParam, setJsonError1);
            setAutoSubmit(true); // Set auto-submit to true if query param is present
        }
    }, [location.search, token]);

    const createToken = async () => {
        const login_res = await loginUser("0000001111");
        const loginRes = login_res[0];

        if (loginRes && loginRes.token) {
            localStorage.setItem("DIGITALU_token", loginRes.token);
            setToken(loginRes.token);
        }
    };

    useEffect(() => {
        const storedToken = localStorage.getItem("DIGITALU_token");
        if (!storedToken) {
            navigate("/login", { state: { redirect: "/tester-api" } });
            // createToken();
        } else {
            setToken(storedToken);
        }
    }, []);

    // Handler to populate the second form when first form's response contains "id"
    const handleFirstFormResponse = (response) => {
        // Check if the response structure is exactly as expected
        if (response && Array.isArray(response.result) && response.result.length === 1 && response.result[0].id) {
            const id = response.result[0]?.id;

            const populatedJson = JSON.stringify(
                {
                    Params: {
                        sys: "queue",
                        act: "select",
                    },
                    Where: {
                        id: id,
                    },
                },
                null,
                2
            );

            setJsonData2(populatedJson);
        } else {
            console.warn("First form response does not match the expected structure.");
        }
    };

    return (
        <div className="flex-1 flex flex-col min-h-screen p-20 gap-10">
            {/* First form */}
            <JSONForm
                jsonData={jsonData1}
                setJsonData={setJsonData1}
                response={response1}
                setResponse={setResponse1}
                fetching={fetching1}
                setFetching={setFetching1}
                token={token}
                jsonError={jsonError1}
                setJsonError={setJsonError1}
                onFirstFormResponse={handleFirstFormResponse} // Pass handler to update second form
                autoSubmit={autoSubmit} // Pass autoSubmit flag
                setAutoSubmit={setAutoSubmit}
            />

            {/* Second form */}
            <JSONForm
                jsonData={jsonData2}
                setJsonData={setJsonData2}
                response={response2}
                setResponse={setResponse2}
                fetching={fetching2}
                setFetching={setFetching2}
                token={token}
                jsonError={jsonError2}
                setJsonError={setJsonError2}
            />
        </div>
    );
};

export default TesterApi;
