import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AdminLayout from "src/layouts/admin-layout";
import ListCampaigns from "src/modules/admin/campaigns/list-campaigns";
import { FaRegCheckSquare } from "react-icons/fa";
import gCampaign from "src/common/assets/images/g-campaigns.png";
import { addEmailToWaitlist } from "src/common/services/waitlist";
import { getStoredUser } from "src/common/components/auth/auth";

const Campaigns = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const storedUser = getStoredUser();
    const [email, setEmail] = useState(storedUser?.email || "");
    const [error, setError] = useState(null);
    const [fetching, setFetching] = useState(false);

    const handleWaitlist = async () => {
        if (validateEmail(email)) {
            setError(null);
            try {
                setFetching(true);
                await addEmailToWaitlist({ email });
                setEmail("");
                setFetching(false);
            } catch (error) {
                setFetching(false);
                if (error.message) {
                    setError(error);
                }
            }
        } else {
            setFetching(false);
            setError("Invalid Email. Please add a correct email.");
        }
    };

    function validateEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }
    return (
        <>
            <AdminLayout
                header={
                    <div className="flex flex-1 flex-col ">
                        <div className="flex flex-1 flex-row px-0 items-center gap-6">
                            <div className="flex items-center gap-5">
                                <div className="flex-1 text-xl font-semibold">Your Campaigns</div>
                            </div>

                            {/* <div
                                className={`relative overflow-hidden transition-all bg-white border rounded-full p-2 px-5 flex items-center gap-2 opacity-60 hover:opacity-100 hover:text-blue hover:border-blue border-zinc-500/40 cursor-pointer`}
                                onClick={() => {
                                    navigate("/admin/campaigns/create");
                                }}
                            >
                                <div>Create Campaign</div>
                            </div> */}
                        </div>
                    </div>
                }
            >
                <div className="container mx-auto flex  flex-col flex-1 h-full">
                    <div className="flex-1 flex flex-col md:flex-row gap-6 items-center  bg-white rounded-xl p-10 border border-lightblue100 ">
                        <div className="flex-1 flex h-full flex-col  rounded-2xl gap-6 flex items-center justify-center">
                            <img src={gCampaign} alt="g-campaign" className="w-100" />
                        </div>
                        <div className="flex-1 flex flex-col gap-6 items-start">
                            <div className="text-2xl md:text-5xl font-semibold tracking-tight flex items-center gap-3">
                                Campaigns <div className="text-sm md:text-lg bg-blue/20 text-blue/80 rounded-full px-4 py-2">Coming Soon</div>
                            </div>
                            <div className=" w-auto md:w-600 text-base  opacity-60">
                                Automate your AI generation to save time and streamline your workflow. With automation, you can generate high-quality AI outputs
                                quickly and consistently, reducing the need for repetitive tasks.
                            </div>

                            <div className="flex flex-col gap-4 items-start w-full max-w-xl mt-20">
                                <div className="text-lg font-semibold">Join the waitlist</div>
                                {fetching && (
                                    <div className="transition-all bg-blue/10 rounded-2xl text-left p-5 loading text-indigo-950/70">
                                        Adding {email} to the list. Please wait
                                    </div>
                                )}
                                {!fetching && (
                                    <div className="transition-all w-full bg-white/80 p-2 rounded-2xl text-base flex gap-5 border border-lightblue100">
                                        <input
                                            type="text"
                                            className=" flex-1 w-full flex"
                                            placeholder="Add your email"
                                            value={email}
                                            onChange={(e) => {
                                                setError(null);
                                                setEmail(e.target.value);
                                            }}
                                        />
                                        <button
                                            disabled={!email}
                                            className="button transition-all bg-blue p-4 rounded-2xl cursor-pointer hover:opacity-70"
                                            onClick={handleWaitlist}
                                        >
                                            <FaRegCheckSquare className="text-xl text-white" />
                                        </button>
                                    </div>
                                )}
                                {error && <div className="bg-rose-500/10 rounded-full p-2 px-4 text-center text-rose-500 capitalize">{error}</div>}
                            </div>
                        </div>
                    </div>
                    {/* <ListCampaigns /> */}
                </div>
            </AdminLayout>
        </>
    );
};

export default Campaigns;
