import { useEffect, useState } from "react";
import Dbox from "src/common/components/dbox/dbox";
import AudioUpload from "./audio-direct-upload";
import AudioUrl from "./audio-direct-url";
import CardAudio from "src/modules/admin/voices/card-audio";
import { TbAlertCircle } from "react-icons/tb";
const AudioDirect = (props) => {
    const { genData } = props;

    const audioTabs = [
        {
            id: "upload",
            label: "Upload",
        },
        {
            id: "url",
            label: "URL",
        },
    ];
    const [audioTab, setAudioTab] = useState(audioTabs[0]?.id);

    useEffect(() => {
        if (genData.audiourl) {
            setAudioTab(audioTabs[1]?.id);
        }
    }, [genData]);

    return (
        <Dbox className={`flex-col flex-1 gap-8 p-4 md:p-20 `}>
            <div className="flex flex-1 flex-col gap-5  mx-auto">
                <div className="flex gap-2 items-center hidden md:flex">
                    <div><TbAlertCircle className="text-lg text-amber-400" /></div>
                    <span className="opacity-40 ">
                        The audio file you provide will serve as the voiceover for your avatar video.
                    </span>
                </div>

                <div className="flex flex-row items-center justify-center text-center bg-lightblue80 0 rounded-2xl p-2">
                    {audioTabs.map((tab, t) => (
                        <div
                            key={t}
                            className={`transition flex-1 cursor-pointer ${
                                audioTab === tab.id ? "opacity-100 font-semibold bg-white rounded-2xl py-2" : "opacity-50"
                            }`}
                            onClick={() => {
                                // delete genData.audio;
                                // delete genData.audiourl;
                                // props.OnSet({ ...genData });
                                setAudioTab(tab.id);
                            }}
                        >
                            {tab.label}
                        </div>
                    ))}
                </div>

                {/* {genData.audiourl && genData.voiceid && (
                    <CardAudio
                        voice={genData}
                        for={`url`}
                        actions={() => {
                            <button className="button button-small px-4 button-bordered " onClick={() => {}}>
                                change
                            </button>;
                        }}
                    />
                )} */}

                {audioTab === "upload" && (
                    <AudioUpload
                        genData={genData}
                        OnSet={(data) => {
                            props.OnSet({ ...data });
                        }}
                    />
                )}

                {audioTab === "url" && (
                    <AudioUrl
                        genData={genData}
                        OnSet={(data) => {
                            props.OnSet({ ...data });
                        }}
                    />
                )}
            </div>
        </Dbox>
    );
};

export default AudioDirect;
