import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { animateTitle } from "src/layouts/marketing/animation/animation";
gsap.registerPlugin(ScrollTrigger);

export const animateHero = () => {
    animateTitle({ trigger: ".section-hero .g-title", scrub: false });

    // //HERO -----------------------------------------------------------------------------------
    // gsap.to(".section-hero > .g-title", {
    //     scale: 0.9,
    //     y: 100,
    //     scrollTrigger: {
    //         trigger: ".section-creators",
    //         start: "top 60%",
    //         end: "+=100",
    //         scrub: 1,
    //     },
    // });

    ScrollTrigger.create({
        trigger: ".section-hero",
        pin: ".section-hero",
        start: "top top",
        end: "bottom top",
        // end: "+=3000",
    });

    const hero_tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".section-hero > .g-title",
            start: "top 1px",
            end: "bottom top",
            // end: "+=3000",
            scrub: 0,
        },
    });

    hero_tl.to(".section-hero .g-arrow", {
        opacity: 0,
        duration: 0.05,
    });

    hero_tl.to(".section-hero > .g-title", {
        ease: "power3.in",
        duration:5,
        scale: 100,
        xPercent: -55,
    });

    gsap.to(".section-hero > .g-title", {
        opacity: 0,
        scrollTrigger: {
            trigger: ".section-creators",
            start: "50% 20%",
            end: "+=300",
            scrub: 1,
        },
    });
};
