export const countries = [
    { name: "Australia", id: "AU", flag: "🇦🇺", code: "+61", phoneDigits: 9 },
    { name: "Austria", id: "AT", flag: "🇦🇹", code: "+43", phoneDigits: 10 },
    { name: "Belgium", id: "BE", flag: "🇧🇪", code: "+32", phoneDigits: 9 },
    { name: "Brazil", id: "BR", flag: "🇧🇷", code: "+55", phoneDigits: 11 },
    { name: "Bulgaria", id: "BG", flag: "🇧🇬", code: "+359", phoneDigits: 9 },
    { name: "Canada", id: "CA", flag: "🇨🇦", code: "+1", phoneDigits: 10 },
    { name: "China", id: "CN", flag: "🇨🇳", code: "+86", phoneDigits: 11 },
    { name: "Croatia", id: "HR", flag: "🇭🇷", code: "+385", phoneDigits: 9 },
    { name: "Czech Republic", id: "CZ", flag: "🇨🇿", code: "+420", phoneDigits: 9 },
    { name: "Denmark", id: "DK", flag: "🇩🇰", code: "+45", phoneDigits: 8 },
    { name: "Estonia", id: "EE", flag: "🇪🇪", code: "+372", phoneDigits: 8 },
    { name: "Finland", id: "FI", flag: "🇫🇮", code: "+358", phoneDigits: 10 },
    { name: "France", id: "FR", flag: "🇫🇷", code: "+33", phoneDigits: 9 },
    { name: "Germany", id: "DE", flag: "🇩🇪", code: "+49", phoneDigits: 11 },
    { name: "Greece", id: "GR", flag: "🇬🇷", code: "+30", phoneDigits: 10 },
    { name: "Hungary", id: "HU", flag: "🇭🇺", code: "+36", phoneDigits: 9 },
    { name: "Iceland", id: "IS", flag: "🇮🇸", code: "+354", phoneDigits: 7 },
    { name: "India", id: "IN", flag: "🇮🇳", code: "+91", phoneDigits: 10 },
    { name: "Ireland", id: "IE", flag: "🇮🇪", code: "+353", phoneDigits: 9 },
    { name: "Italy", id: "IT", flag: "🇮🇹", code: "+39", phoneDigits: 9 },
    { name: "Japan", id: "JP", flag: "🇯🇵", code: "+81", phoneDigits: 10 },
    { name: "Latvia", id: "LV", flag: "🇱🇻", code: "+371", phoneDigits: 8 },
    { name: "Lithuania", id: "LT", flag: "🇱🇹", code: "+370", phoneDigits: 8 },
    { name: "Luxembourg", id: "LU", flag: "🇱🇺", code: "+352", phoneDigits: 9 },
    { name: "Mexico", id: "MX", flag: "🇲🇽", code: "+52", phoneDigits: 10 },
    { name: "Montenegro", id: "ME", flag: "🇲🇪", code: "+382", phoneDigits: 8 },
    { name: "Netherlands", id: "NL", flag: "🇳🇱", code: "+31", phoneDigits: 9 },
    { name: "New Zealand", id: "NZ", flag: "🇳🇿", code: "+64", phoneDigits: 9 },
    { name: "Norway", id: "NO", flag: "🇳🇴", code: "+47", phoneDigits: 8 },
    { name: "North Macedonia", id: "MK", flag: "🇲🇰", code: "+389", phoneDigits: 8 },
    { name: "Philippines", id: "PH", flag: "🇵🇭", code: "+63", phoneDigits: 10 },
    { name: "Poland", id: "PL", flag: "🇵🇱", code: "+48", phoneDigits: 9 },
    { name: "Portugal", id: "PT", flag: "🇵🇹", code: "+351", phoneDigits: 9 },
    { name: "Serbia", id: "RS", flag: "🇷🇸", code: "+381", phoneDigits: 9 },
    { name: "Singapore", id: "SG", flag: "🇸🇬", code: "+65", phoneDigits: 8 },
    { name: "Slovakia", id: "SK", flag: "🇸🇰", code: "+421", phoneDigits: 9 },
    { name: "Slovenia", id: "SI", flag: "🇸🇮", code: "+386", phoneDigits: 8 },
    { name: "South Africa", id: "ZA", flag: "🇿🇦", code: "+27", phoneDigits: 9 },
    { name: "South Korea", id: "KR", flag: "🇰🇷", code: "+82", phoneDigits: 10 },
    { name: "Spain", id: "ES", flag: "🇪🇸", code: "+34", phoneDigits: 9 },
    { name: "Sweden", id: "SE", flag: "🇸🇪", code: "+46", phoneDigits: 9 },
    { name: "Switzerland", id: "CH", flag: "🇨🇭", code: "+41", phoneDigits: 9 },
    { name: "Ukraine", id: "UA", flag: "🇺🇦", code: "+380", phoneDigits: 9 },
    { name: "United Kingdom", id: "UK", flag: "🇬🇧", code: "+44", phoneDigits: 10 },
    { name: "United States", id: "US", flag: "🇺🇸", code: "+1", phoneDigits: 10 }
];
