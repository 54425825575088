import { useEffect, useState } from "react";
import Dbox from "src/common/components/dbox/dbox";
import VideoPlayer from "src/common/components/video-player/video-player";
import { checkFileType } from "src/common/utils/utils";
import { IoClose } from "react-icons/io5";
import { BiErrorAlt } from "react-icons/bi";

const VideoUrl = (props) => {
    const { genData } = props;

    const [val, setVal] = useState("");
    const [displayedUrl, setDisplayedUrl] = useState("");
    const [error, setError] = useState("");
    const [checking, setChecking] = useState(false);

    useEffect(() => {
        if (genData.videourl) {
            setVal(genData.videourl);
            setDisplayedUrl(genData.videourl);
        } else if (genData.imageurl) {
            setVal(genData.imageurl);
            setDisplayedUrl(genData.imageurl);
        } else if (genData.URL) {
            setVal(genData.URL);
            setDisplayedUrl(genData.URL);
        }
    }, [genData]);

    const checkImageUrl = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => reject(false);
            img.src = url;
        });
    };

    const checkVideoUrl = (url) => {
        return new Promise((resolve, reject) => {
            const video = document.createElement("video");
            video.onloadedmetadata = () => resolve(true);
            video.onerror = () => reject(false);
            video.src = url;
        });
    };

    const handleSubmit = async () => {
        setError("");
        setDisplayedUrl("");
        setChecking(true);
        const fileType = checkFileType(val);

        try {
            if (fileType === "image") {
                await checkImageUrl(val);
                delete genData.videoid;
                delete genData.videourl;
                delete genData.imageid;
                delete genData.file;
                genData.imageurl = val;
            } else if (fileType === "video") {
                await checkVideoUrl(val);
                delete genData.videoid;
                genData.videourl = val;
                delete genData.imageid;
                delete genData.imageurl;
                delete genData.file;
            } else {
                throw new Error("Invalid file type");
            }

            setDisplayedUrl(val);
        } catch (err) {
            setError(fileType === "image" ? "Invalid image URL" : fileType === "video" ? "Invalid video URL" : "Invalid URL");
            setDisplayedUrl("");
            delete genData.videoid;
            delete genData.videourl;
            delete genData.imageid;
            delete genData.file;
            delete genData.imageurl;
        }

        setChecking(false);
        props.OnSet({ ...genData });
    };

    return (
        <Dbox className="p-4 md:p-10 flex-1">
            <div className="flex-1 flex flex-col gap-6 p-5 md:py-2 md:px-5">
                <div className="flex flex-col gap-4">
                    <div className="font-semibold">Enter a url to a video or image.</div>
                    <div className="flex gap-2">
                        <div className="flex-grow border border-lightblue100 bg-lightblue50 rounded-2xl flex-1 flex items-center justify-between p-2">
                            <input
                                type="text"
                                value={val}
                                placeholder="Add a video or image url"
                                className="w-full p-2"
                                onChange={(e) => setVal(e.target.value)}
                            />

                            <div
                                className="px-3 cursor-pointer opacity-50 hover:opacity-100"
                                onClick={() => {
                                    setVal("");
                                    setError("");
                                    setDisplayedUrl("");
                                    delete genData.videoid;
                                    delete genData.videourl;
                                    delete genData.imageid;
                                    delete genData.imageurl;
                                    delete genData.file;
                                    props.OnSet({ ...genData });
                                }}
                            >
                                <IoClose className="text-lg" />
                            </div>
                        </div>
                        <button className="button bg-blue text-white rounded-2xl" onClick={handleSubmit}>
                            Check
                        </button>
                    </div>
                </div>

                <div className="flex-1 flex flex-col gap-4 bg-lightblue100/40 border border-lightblue100 rounded-2xl items-center justify-center">
                    {checking && <div className="text-indigo-500/60 text-center loading">Please wait. Checking</div>}
                    {!error && displayedUrl === "" && val === "" && (
                        <div className="text-indigo-500/40 text-center">Your video or image will be displayed here</div>
                    )}
                    {error && (
                        <div className="text-red-500 text-center flex flex-col items-center gap-5 bg-white rounded-2xl p-10 pb-5 shadow-xl shadow-indigo-300/10">
                            <BiErrorAlt className="text-2xl" />
                            {error}
                        </div>
                    )}
                    {displayedUrl && genData.imageurl && (
                        <div className="flex-1  min-h-400  max-h-400 relative overflow-hidden bg-black mx-auto rounded-2xl">
                            <img src={displayedUrl} alt="Uploaded image" className="object-contain w-full h-full" />
                        </div>
                    )}
                    {displayedUrl && genData.videourl && (
                        <div className="flex-1 w-600 max-h-400  min-h-400 relative overflow-hidden bg-black mx-auto rounded-2xl">
                            <VideoPlayer src={displayedUrl} className={`max-h-400  min-h-400`} fluid={true} />
                        </div>
                    )}
                </div>
            </div>
        </Dbox>
    );
};

export default VideoUrl;
