import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Timeline from "src/common/components/timeline/timeline";
import TimelineNavi from "src/common/components/timeline/timeline-navi";
import AdminLayout from "src/layouts/admin-layout";
import Iicon from "src/common/components/iicon/iicon";
import Preloader from "src/common/components/preloader/preloader";
import AddInfo from "src/modules/admin/generated-videos/generate-video/add-info";
import AddVideo from "src/modules/admin/generated-videos/generate-video/add-video";

import { upload } from "src/common/services/utils";
import { generateVideo } from "src/common/services/generate";
import AddAudio from "src/modules/admin/generated-videos/generate-video/add-audio";
import AddText from "src/modules/admin/generated-videos/generate-video/add-text";
import SummaryGenerate from "src/modules/admin/generated-videos/generate-video/summary";

const GenerateVideo = (props) => {
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [fetching, setFetching] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const regexName = /[^a-zA-Z0-9_\s]/g;
    const timelineData = [
        // {
        //     id: "details",
        //     name: "Video Details",
        // },
        {
            id: "add-video",
            name: "Select Video / Image",
        },
        {
            id: "add-voice",
            name: "Select Voice",
        },
        {
            id: "add-text",
            name: "Create Script",
        },
    ];

    const [timeline, setTimeline] = useState(timelineData);

    const [voiceTab, setVoiceTab] = useState(null);
    const [selectedTimeline, setSelectedTimeline] = useState(timeline[0]);
    const [canNext, setCanNext] = useState(false);
    const [genData, setGenData] = useState({ name: "" });

    useEffect(() => {
        if (params.campaignid) {
            //handleGenerate({ opr: "select", id: params.campaignid });
        }
    }, [params]);

    useEffect(() => {
        const todo = location?.state?.todo;
        const data = location?.state?.data;

        //console.log("todo ::", todo, data);

        switch (todo) {
            case "create":
                setSelectedTimeline(timeline[0]);
                setGenData({ name: "", ...data });

                break;

            case "regen":
                // data.name = `${data.name}`;
                setGenData({ ...data });
                setSelectedTimeline(timeline[timeline.length - 1]);

                break;

            default:
                break;
        }
    }, [location]);

    useEffect(() => {
        // //console.log('genData :::', genData)
        handleTimelineValidation(selectedTimeline);
    }, [selectedTimeline, genData]);

    useEffect(() => {
        if (voiceTab === "direct") {
            const directTl = timelineData.filter((tl) => tl.id !== "add-text");

            setTimeline(directTl);
        } else {
            setTimeline(timelineData);
        }
    }, [voiceTab]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleTimelineValidation = () => {
        setCanNext(false);
        switch (selectedTimeline.id) {
            // case "details":
            //     if (genData.name !== "") {
            //         setCanNext(true);
            //     }
            //     break;
            case "add-video":
                if (genData.file || genData.videoid || genData.videourl || genData.imageid || genData.imageurl) {
                    setCanNext(true);
                }
                break;

            case "add-voice":
                if (genData.audio || genData.voiceid || genData.audiourl || genData.voicegender) {
                    setCanNext(true);
                }

            case "add-text":
                if (genData.name !== "" && genData.text && genData.text !== "") {
                    setCanNext(true);
                }
                break;

            default:
                setCanNext(false);
                break;
        }
    };

    const navigateTimeline = (direction) => {
        const indexSelected = timeline.findIndex((timeline) => timeline.id === selectedTimeline.id);
        if (indexSelected) {
            switch (direction) {
                case "next":
                    if (timeline[indexSelected + 1]) {
                        setSelectedTimeline(timeline[indexSelected + 1]);
                    }
                    break;
                case "prev":
                    if (timeline[indexSelected - 1]) {
                        setSelectedTimeline(timeline[indexSelected - 1]);
                    }
                    break;

                default:
                    break;
            }
        }
    };

    const handleGenerate = async () => {
        setFetching(true);
        // //console.log("genData :: ", genData);
        // imageurl, videourl, audiourl, imageid, videoid, voiceid, voicegender
        const gen_input = { ...genData };

        if (gen_input.file) {
            try {
                const file_res = await upload({ file: gen_input.file });
                //console.log("file_res :: ", file_res);

                if (gen_input.file.type.includes("video")) {
                    gen_input.videourl = file_res[0].cache;
                } else if (gen_input.file.type.includes("image")) {
                    gen_input.imageurl = file_res[0].cache;
                }
                delete gen_input.file;
            } catch (error) {
                //console.log(error);
            }
        }

        if (gen_input.audio) {
            try {
                const audio_res = await upload({ file: gen_input.audio });
                //console.log("audio_res :: ", audio_res);

                delete gen_input.audio;
                gen_input.audiourl = audio_res[0].cache;
            } catch (error) {
                //console.log(error);
            }
        }

        if (gen_input.videourl || gen_input.videoid) {
            gen_input.type = "video";
        } else if (gen_input.imageurl || gen_input.imageid) {
            gen_input.type = "image";
        }

        delete gen_input.URL;
        try {
            //console.log("gen_input (input) ::", gen_input);
            const gen_res = await generateVideo(gen_input);
            //console.log("gen_res (output) ::", gen_res);

            navigate("/admin/generated-videos", { state: { todo: "coin-update" } });
        } catch (error) {
            //console.log(error);
        }
        setFetching(false);
        // setFetching(false);
        //  props.OnSuccess(true);
    };

    const canSubmit = () => {
        if (genData.name !== "") {
            switch (voiceTab) {
                case "direct":
                    if (
                        (genData.file || genData.videoid || genData.videourl || genData.imageid || genData.imageurl) &&
                        (genData.audio || genData.voiceid || genData.audiourl)
                    ) {
                        return true;
                    }
                    break;

                case "default":
                    if ((genData.text !== "" && genData.file) || genData.videoid || genData.videourl || genData.imageid || genData.imageurl) {
                        return true;
                    }
                    break;

                case "fromId":
                    if (
                        genData.text !== "" &&
                        (genData.file || genData.videoid || genData.videourl || genData.imageid || genData.imageurl) &&
                        genData.voiceid
                    ) {
                        return true;
                    }
                    break;
                default:
                    return false;
            }
        } else {
            return false;
        }
    };

    return (
        <>
            {fetching && <Preloader />}
            <AdminLayout
                className={`gap-4 md:gap-10 flex-col md:flex-row`}
                header={
                    !isMobile ? null : (
                        <div className="flex items-center">
                            <div className="text-lg md:text-xl font-semibold">Generate Video</div>
                            {/* 
                        <Timeline
                            data={timeline}
                            canNext={canNext}
                            selected={selectedTimeline}
                            OnSelect={(item) => {
                                setSelectedTimeline(item);
                            }}
                        /> */}
                        </div>
                    )
                }
                footer={
                    !isMobile ? null : (
                        <div className="flex justify-between gap-8 items-center">
                            <TimelineNavi
                                data={timeline}
                                selected={selectedTimeline}
                                direction={"back"}
                                onClick={(prevTimeline) => {
                                    setSelectedTimeline(prevTimeline);
                                }}
                            />

                            {/* {selectedTimeline.id !== "details" ||
                            (selectedTimeline.id === "details" && genData.id && (
                                <TimelineNavi
                                    data={timeline}
                                    canNext={canNext}
                                    selected={selectedTimeline}
                                    direction={"next"}
                                    onClick={(nextTimeline) => {
                                        setSelectedTimeline(nextTimeline);
                                    }}
                                />
                            ))} */}

                            <TimelineNavi
                                data={timeline}
                                canNext={canNext}
                                selected={selectedTimeline}
                                direction={"next"}
                                onClick={(nextTimeline) => {
                                    setSelectedTimeline(nextTimeline);
                                }}
                            />

                            {selectedTimeline?.id === "add-text" && (
                                <button
                                    disabled={(genData.text && genData.text === "") || !genData.text}
                                    className="button flex gap-3 pr-3 items-center"
                                    onClick={async () => {
                                        // if (canSubmit()) {
                                        await handleGenerate();
                                        // }
                                    }}
                                >
                                    <div>Finish</div>
                                    <Iicon icon={"arrow-right-white"} />
                                </button>
                            )}

                            {selectedTimeline?.id === "add-voice" && voiceTab === "direct" && (
                                <button
                                    disabled={!genData.audio && !genData.audiourl}
                                    className="button flex gap-3 pr-3 items-center"
                                    onClick={async () => {
                                        // if (canSubmit()) {
                                        await handleGenerate();
                                        // }
                                    }}
                                >
                                    <div>Finish</div>
                                    <Iicon icon={"arrow-right-white"} />
                                </button>
                            )}
                        </div>
                    )
                }
            >
                {/*                 
                {selectedTimeline?.id === "details" && (
                    <div className="flex flex-col gap-8 py-10 w-full max-w-screen-lg mx-auto slideFromRight">
                        <AddInfo
                            genData={genData}
                            OnSet={(data) => {
                                setGenData({ ...data });
                                navigateTimeline("next");
                            }}
                        />
                    </div>
                )} */}

                <div className="md:hidden p-6 bg-white relative overflow-hidden border border-lightblue100 rounded-2xl gap-8 flex flex-col">
                    <div className=" flex flex-col gap-2 py-3">
                        {/* <div className="flex flex-row items-start gap-6">
                        <div className="flex flex-col gap-1">
                            <div className="text-lg md:text-xl font-semibold"> Add Name</div>
                        </div>
                    </div> */}

                        <input
                            type={"text"}
                            className="!p-0 border-b border-lightblue100 !pb-3"
                            placeholder={"Add name for your video..."}
                            value={genData.name}
                            onChange={(e) => {
                                const allowedPattern = /^[a-zA-Z0-9_\s]*$/;

                                if (!allowedPattern.test(e.target.value)) {
                                    e.target.value = e.target.value.replace(regexName, "");
                                }

                                genData.name = e.target.value;
                                setGenData({ ...genData });
                            }}
                        />

                        <div className="flex gap-2 items-center text-xs opacity-30">Special characters are not allowed.</div>
                    </div>
                </div>
                <div className="p-6 py-8 md:p-0 md:py-0 bg-white md:bg-transparent relative overflow-hidden border border-lightblue100 md:border-0 rounded-2xl gap-8 flex-1 flex flex-col md:flex-row">
                    {selectedTimeline?.id === "add-video" && (
                        <div className="flex flex-1  flex-col w-full  mx-auto slideFromRight">
                            <AddVideo
                                {...props}
                                genData={genData}
                                OnSet={(data) => {
                                    setGenData({ ...data });
                                    // navigateTimeline("next");
                                }}
                            />
                        </div>
                    )}

                    {selectedTimeline?.id === "add-voice" && (
                        <div className="flex flex-1 flex-col w-full  mx-auto slideFromRight">
                            <AddAudio
                                {...props}
                                genData={genData}
                                OnSet={(data) => {
                                    setGenData({ ...data });
                                }}
                                OnVoiceTab={(tab) => {
                                    setVoiceTab(tab.id);
                                }}
                            />
                        </div>
                    )}

                    {selectedTimeline?.id === "add-text" && (
                        <div className="flex-1 flex flex-col w-full  mx-auto slideFromRight">
                            <AddText
                                {...props}
                                genData={genData}
                                OnSet={(data) => {
                                    setGenData({ ...data });
                                }}
                                // OnChange={(data) => {
                                //     if (data === "video") {
                                //         setSelectedTimeline(timeline[1]);
                                //     } else if (data === "voice") {
                                //         setSelectedTimeline(timeline[2]);
                                //     }
                                // }}
                            />
                        </div>
                    )}

                    <SummaryGenerate
                        {...props}
                        genData={genData}
                        voiceTab={voiceTab}
                        selectedTimeline={selectedTimeline}
                        OnSet={(data) => {
                            setGenData({ ...data });
                        }}
                        OnChange={(data) => {
                            if (data === "video") {
                                setSelectedTimeline(timeline[0]);
                            } else if (data === "voice") {
                                setSelectedTimeline(timeline[1]);
                            }
                        }}
                        OnSelectTimeline={(timelineIndex) => {
                            setSelectedTimeline(timeline[timelineIndex]);
                        }}
                        OnGenerate={async () => {
                            await handleGenerate();
                        }}
                    />
                </div>
            </AdminLayout>
        </>
    );
};

export default GenerateVideo;
