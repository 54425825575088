import { useState, useRef, useEffect } from "react";
import { GrUploadOption } from "react-icons/gr";
import VideoPlayer from "src/common/components/video-player/video-player";
const Media = (props) => {
    const fileInputRef = useRef(null);
    const { selectedDetail, setSelectedDetail, setShowInputAdd } = props;

    useEffect(() => {
        if (selectedDetail.params.src === "" && (selectedDetail.type === "image" || selectedDetail.type === "video")) {
            fileInputRef.current?.click();
        }
    }, [selectedDetail]); // Empty dependency array means this runs once on mount

    const UploadFile = ({ type }) => {
        return (
            <div
                className="transition min-h-52 max-h-52 min-w-full flex-1 flex items-center gap-2 p-3 group border border-dashed border-indigo-800/30 rounded-2xl cursor-pointer hover:border-blue bg-blue/10 text-black/30 hover:text-blue items-center justify-center"
                onClick={() => fileInputRef.current?.click()}
            >
                <button className="relative z-10 button-white p-2 pr-5 rounded-3xl cursor-pointer flex items-center gap-2 capitalize">
                    <GrUploadOption className="text-md" />
                    Upload {type}
                </button>
            </div>
        );
    };
    return (
        <>
            <input
                type="file"
                accept={selectedDetail?.detailType === "inputs" ? "image/*,video/*,audio/*" : `${selectedDetail?.type}/*`}
                className="hidden"
                ref={fileInputRef}
                onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                        setSelectedDetail((prev) => ({
                            ...prev,
                            params: {
                                ...prev.params,
                                src: URL.createObjectURL(e.target.files[0]),
                                file: e.target.files[0],
                                width: null,
                                height: null,
                            },
                            type: e.target.files[0].type.split("/")[0],
                        }));
                    }
                }}
            />
            {selectedDetail.params.src === "" && (selectedDetail.type === "image" || selectedDetail.type === "video") && (
                <UploadFile type={selectedDetail.type} />
            )}

            {selectedDetail.params.src && (selectedDetail.type === "image" || selectedDetail.type === "video") && (
                <div className="bg-black rounded-2xl w-full h-52 relative overflow-hidden border border-lightblue100">
                    {selectedDetail.type === "image" && <img src={selectedDetail.params.src} alt="uploaded image" className="w-full h-full object-contain" />}
                    {selectedDetail.type === "video" && (
                        <VideoPlayer src={selectedDetail.params.src} fluid={true} hoverPlay={true} actions={false} fit={`contain`} />
                    )}

                    <div className="absolute bottom-0 right-0 p-2 flex items-center gap-2">
                        <button
                            className="button-white button-small py-1 px-3 rounded-full text-xs"
                            onClick={() => {
                                setShowInputAdd(true);
                            }}
                        >
                            change
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Media;
