import { countries } from "src/common/data/countries";
import { useState, useEffect } from "react";

const FormPhone = (props) => {
    const [errorMsg, setErrorMsg] = useState(null);
    const [number, setNumber] = useState("");

    const [country, setCountry] = useState();

    useEffect(() => {
        const findUS = countries.find((country) => country.id === "US");
        if (findUS) {
            setCountry(findUS);
        }
    }, []);

    // useEffect(() => {
    //     if (areaCode) {
    //         const find = countries.find((cntry) => cntry.code === areaCode);
    //         if (find) {
    //             setCountry({ ...find });

    //             const regex = new RegExp(`^\\d{0,${country?.phoneDigits}}$`);
    //             if (regex.test(number)) {
    //                 setNumber(number.slice(0,country?.phoneDigits ))

    //             }
    //         }
    //     }
    // }, [areaCode]);

    useEffect(() => {
        if (country && number) {
            props.OnChange(`${country.code}${number}`);
        }
    }, [country, number]);

    const handlePhoneNumberChange = (event) => {
        setErrorMsg(false);
        const inputValue = event.target.value;
        const regex = new RegExp(`^\\d{0,${country?.phoneDigits}}$`);

        if (regex.test(inputValue)) {
            setNumber(inputValue);
        }
    };
    return (
        <div className="flex-1 flex flex-row gap-2">
            <div className="form-item-input flex items-center !w-auto p-5 pr-4 gap-2">
                {country && <div className="">{country.flag}</div>}
                <select
                    className="!w-1 !px-2 !py-0"
                    value={country?.id}
                    onChange={(e) => {
                        const selectedOption = e.target.selectedOptions[0];
                        const selectedCountry = JSON.parse(selectedOption.getAttribute("data-country"));
                        setCountry({ ...selectedCountry });
                    }}
                >
                    {countries.map((country, c) => (
                        <option key={c} value={country.id} data-id={country.id} data-country={JSON.stringify(country)}>
                            {country.name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="form-item-input flex-1">
                {/* <div className="max-w-16 pl-6 py-6">{country?.code} </div> */}
                <input
                    type="text"
                    className="text-center "
                    style={{ letterSpacing: "2px", fontSize:'14px' }}
                    value={number}
                    placeholder={`${"888888123456789".substring(0, country?.phoneDigits)}`}
                    onChange={(e) => {
                        handlePhoneNumberChange(e);
                        // setNumber(e.target.value);
                    }}
                    onKeyDown={async (e) => {
                        // if (e.key === "Enter" && e.target.value !== "") {
                        //     signIn();
                        // }
                    }}
                />
            </div>
            {errorMsg === true && <div className="text-rose-500 mt-4">Please add a valid phone number.</div>}
        </div>
    );
};

export default FormPhone;
