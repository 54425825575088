import { useState, useEffect } from "react";
import AudioUpload from "./tabs/audio-direct-upload";
import AudioUrl from "./tabs/audio-direct-url";
import AudioTTA from "./tabs/audio-tta";
import Dbox from "src/common/components/dbox/dbox";
import ListVoices from "../../voices/list-voices";
import AudioDirect from "./tabs/audio-direct";
import AudioDefault from "./tabs/audio-default";

const AddAudio = (props) => {
    const { genData } = props;
    const voiceTabs = [
        { id: "fromId", label: "Your Voices", desc: "Select from voices you have created." },
        // { id: "default", label: "Premade", desc: "Select from our premade voices." },
        { id: "direct", label: "Direct", desc: "Upload or add a URL to an audio file." },
    ];
    const [voiceTab, setVoiceTab] = useState(voiceTabs[0]);

    useEffect(() => {
        if (genData && genData.usevoice) {
            const selTab = voiceTabs.find((tab) => tab.id === genData.usevoice);
            if (selTab) {
                setVoiceTab(selTab);
            }
        }
    }, []);

    useEffect(() => {
        genData.usevoice = voiceTab.id;

        if (voiceTab.id === "direct") {
            delete genData.text;
        }

        props.OnSet({ ...genData });
        props.OnVoiceTab(voiceTab);
    }, [voiceTab]);

    return (
        <div className="flex flex-1 flex-col gap-4  md:gap-8 py-0 md:py-10 w-full mx-auto">
            <div className="flex flex-row items-start gap-6">
                {/* <div className="flex items-center justify-center text-lg bg-lightblue100  rounded-full w-12 h-12 mt-2">2</div> */}
                <div className="flex flex-col gap-1">
                    <div className="text-lg md:text-xl font-semibold">Select Voice</div>
                    <div className="opacity-50">Select a voice for your video or upload your own voice.</div>
                </div>
            </div>

            <div className="flex flex-col  gap-5">
                <div className="flex flex-row items-center justify-center text-center bg-lightblue80 rounded-2xl p-2">
                    {voiceTabs.map((tab, t) => (
                        <div
                            key={t}
                            className={`transition flex-1 cursor-pointer ${voiceTab.id === tab.id ? "opacity-100  bg-white rounded-2xl py-4" : "opacity-50"}`}
                            onClick={() => {
                                // delete genData.voiceid;
                                // delete genData.audiourl;
                                // delete genData.voicegender;

                                //props.OnSelectTab(tab.id);
                                setVoiceTab(tab);
                            }}
                        >
                            <div className="font-semibold">{tab.label}</div>
                            <div className="opacity-40 hidden md:block">{tab.desc}</div>
                        </div>
                    ))}
                </div>
            </div>



            

            {voiceTab.id === "default" && (
                <AudioDefault
                    genData={genData}
                    OnSet={(data) => {
                        props.OnSet({ ...data });
                    }}
                />
            )}

            {voiceTab.id === "direct" && (
                <AudioDirect
                    genData={genData}
                    OnSet={(data) => {
                       
                        props.OnSet({ ...data });
                    }}
                />
            )}
            {voiceTab.id === "fromId" && (
                <ListVoices
                    selectedId={genData?.voiceid}
                    genData={genData}
                    for={"select"}
                    OnUse={(voice) => {
                        if (voice.voiceid) {
                            genData.voiceid = voice.voiceid;
                        } else {
                            delete genData.voiceid;
                        }
                        delete genData.voicegender;
                        delete genData.audiourl;

                        props.OnSet({ ...genData });
                    }}
                />
            )}

            {/* {voiceTab.id !== "direct" && (
                    <div className="border flex  flex-1 border-lightblue100 rounded-2xl relative overflow-hidden">
                        <textarea
                            value={genData.text}
                            className="flex flex-1 w-full p-8 bg-lightblue50"
                            rows={3}
                            placeholder={`Add text for you audio ...`}
                            onChange={(e) => {
                                genData.text = e.target.value;
                                props.OnSet({ ...genData });
                            }}
                        />
                    </div>
                )} */}
        </div>
    );
};

export default AddAudio;
