import VideoPlayer from "src/common/components/video-player/video-player";
import { FaRegTrashAlt } from "react-icons/fa";

const getSceneDimensions = (project) => ({
    width: (120 * project.width) / project.height,
    height: 120,
});

const SceneProject = ({ project, setProject, setSelectedDetail, setSelectedScene, editMode, stageSize }) => {
   
    // Calculate scale factor based on the container dimensions
    const containerDims = getSceneDimensions(project);
    const scale = Math.min(containerDims.width / stageSize.width, containerDims.height / stageSize.height);

    return (
        <div className={`scene-item flex flex-col gap-2 rounded-2xl items-center justify-center`}>
            <div
                className={`transition-all scene-video  overflow-hidden relative group flex items-center justify-center`}
                style={getSceneDimensions(project)}
            >
                <div
                    className={`w-full h-full bg-white cursor-pointer rounded-2xl relative overflow-hidden ${project.result.show ? "border-2 border-blue" : ""}`}
                    onClick={() => {
                        setSelectedDetail(null);
                        setSelectedScene(null);
                        setProject((prev) => ({
                            ...prev,
                            result: { ...prev.result, show: !prev.result.show },
                        }));
                        //setSelectedDetail(scene.inputs?.length > 0 ? scene.inputs[0] : null);
                        //setSelectedScene((prev) => (prev?.id === scene.id ? null : scene));
                    }}
                >
                    {/* <StageContainer project={project} stageSize={stageSize} selectedScene={scene} scaleX={scale} scaleY={scale} /> */}
                    <VideoPlayer src={project.result.url} fluid={true} hoverPlay={true} actions={false} fit="contain" muted={true} />

                   
                </div>

             

                <div className={`scene-video-actions absolute top-0 right-0 p-2 h-full z-40 flex flex-col ${editMode ? "visible" : "hidden"}`}>
                    <div className="flex flex-1 flex-col gap-2 bg-white rounded-lg p-2 items-center justify-between">
                        <div className="flex flex-row gap-2 cursor-pointer" onClick={() =>{}}>
                            <FaRegTrashAlt className="text-lg w-4 h-4 text-black" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="scene-name text-xs text-center">{project.name.length > 10 ? project.name.substring(0, 10) + "..." : project.name}</div>
        </div>
    );
};

export default SceneProject;
