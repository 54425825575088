import gsap from "gsap";

import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export const animateTitle = ({ trigger, target, scrub }) => {
    if (!target) {
        target = trigger;
    }

    if (scrub === false) {
        scrub = false;
    } else if (scrub === undefined) {
        scrub = 1;
    }
    const divs = gsap.utils.toArray(`${target} > div`);

    divs.forEach((div, d) => {
        gsap.from(div, {
            y: "-50px",
            // skewX:'10deg',
            opacity: 0,
            delay: scrub === false ? d / 7 : d,
       
            scrollTrigger: {
                trigger: div,
                start: "130% bottom", // when the top of the trigger hits the top of the viewport
                end: "+=100", // end after scrolling 500px beyond the start
                scrub: scrub,
            },
        });
    });
};

export const toggleHeader = ({ trigger, start, type }) => {
    if (!start) {
        start = "10%";
    }
    const header = document.querySelector("#header");
    const container = document.querySelector("#header > .container");
    const logo = document.querySelector("#header .logo");
    const button = document.querySelector("#header .button");
    const menuLinks = document.querySelectorAll("#header a");
    const light = () => {
        if (type === "full") {
            container.classList.add("bg-white/90");
        } else {
            logo.classList.add("logo-white");
            button.classList.add("button-white");
            menuLinks.forEach((link) => {
                link.classList.add("text-white");
            });
        }
    };
    const dark = () => {
        if (type === "full") {
            container.classList.remove("bg-white/90");
        } else {
            logo.classList.remove("logo-white");
            button.classList.remove("button-white");
            menuLinks.forEach((link) => {
                link.classList.remove("text-white");
            });
        }
    };
    gsap.to(header, {
        scrollTrigger: {
            trigger: trigger,
            start: `top ${start}`,
            end: "100% top",
            // end: "+=100",
            scrub: 0,
            onEnter: () => light(),
            onLeave: () => dark(),
            onEnterBack: () => light(),
            onLeaveBack: () => dark(),
        },
    });
};

export const horizontalLoop = (items, config) => {
    items = gsap.utils.toArray(items);
    config = config || {};
    let tl = gsap.timeline({ repeat: config.repeat, paused: config.paused, defaults: { ease: "none" } }),
        length = items.length,
        startX = items[0].offsetLeft,
        times = [],
        widths = [],
        xPercents = [],
        curIndex = 0,
        pixelsPerSecond = (config.speed || 1) * 100,
        snap = config.snap === false ? (v) => v : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
        totalWidth,
        curX,
        distanceToStart,
        distanceToLoop,
        item,
        i;
    gsap.set(items, {
        // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
        xPercent: (i, el) => {
            let w = (widths[i] = parseFloat(gsap.getProperty(el, "width", "px")));
            xPercents[i] = snap((parseFloat(gsap.getProperty(el, "x", "px")) / w) * 100 + gsap.getProperty(el, "xPercent"));
            return xPercents[i];
        },
    });
    gsap.set(items, { x: 0 });
    totalWidth =
        items[length - 1].offsetLeft +
        (xPercents[length - 1] / 100) * widths[length - 1] -
        startX +
        items[length - 1].offsetWidth * gsap.getProperty(items[length - 1], "scaleX") +
        (parseFloat(config.paddingRight) || 0);
    for (i = 0; i < length; i++) {
        item = items[i];
        curX = (xPercents[i] / 100) * widths[i];
        distanceToStart = item.offsetLeft + curX - startX;
        distanceToLoop = distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
        tl.to(item, { xPercent: snap(((curX - distanceToLoop) / widths[i]) * 100), duration: distanceToLoop / pixelsPerSecond }, 0)
            .fromTo(
                item,
                { xPercent: snap(((curX - distanceToLoop + totalWidth) / widths[i]) * 100) },
                { xPercent: xPercents[i], duration: (curX - distanceToLoop + totalWidth - curX) / pixelsPerSecond, immediateRender: false },
                distanceToLoop / pixelsPerSecond
            )
            .add("label" + i, distanceToStart / pixelsPerSecond);
        times[i] = distanceToStart / pixelsPerSecond;
    }
    function toIndex(index, vars) {
        vars = vars || {};
        Math.abs(index - curIndex) > length / 2 && (index += index > curIndex ? -length : length); // always go in the shortest direction
        let newIndex = gsap.utils.wrap(0, length, index),
            time = times[newIndex];
        if (time > tl.time() !== index > curIndex) {
            // if we're wrapping the timeline's playhead, make the proper adjustments
            vars.modifiers = { time: gsap.utils.wrap(0, tl.duration()) };
            time += tl.duration() * (index > curIndex ? 1 : -1);
        }
        curIndex = newIndex;
        vars.overwrite = true;
        return tl.tweenTo(time, vars);
    }
    tl.next = (vars) => toIndex(curIndex + 1, vars);
    tl.previous = (vars) => toIndex(curIndex - 1, vars);
    tl.current = () => curIndex;
    tl.toIndex = (index, vars) => toIndex(index, vars);
    tl.times = times;
    return tl;
};


///SECTIONS

