import { useEffect, useState } from "react";
import { getStoredUser } from "src/common/components/auth/auth";

import { copyToClipboard } from "../utils/utils";
import { FaRegCopy } from "react-icons/fa";
import { LuCheckCircle } from "react-icons/lu";
import Dbox from "./dbox/dbox";
import { IoClose } from "react-icons/io5";

const ApiBox = (props) => {
    const { video, data } = props;
    const [copied, setCopied] = useState(false);
    const [copiedResult, setCopiedResult] = useState(false);
    const [show, setShow] = useState(false);

    const thevideo = { ...video };
    delete thevideo.id;
    delete thevideo.createdate;
    delete thevideo.result;
    delete thevideo.state;

    useEffect(() => {
        if (copied) {
            const timeout = setTimeout(() => {
                setCopied(false);
                clearTimeout(timeout);
            }, 2000);
        }
    }, [copied]);

    useEffect(() => {
        if (copiedResult) {
            const timeout = setTimeout(() => {
                setCopiedResult(false);
                clearTimeout(timeout);
            }, 2000);
        }
    }, [copiedResult]);

    const [selected, setSelected] = useState();

    useEffect(() => {
        if (data) {
            setSelected(data[0]);
        }
    }, [data]);

    return (
        <>
            <div className="flex-1 flex flex-col gap-6">
                <div className="flex-1 flex flex-col relative gap-3">
                    {/* <div className="">Query :</div> */}
                    <div className="flex-1 flex flex-col border border-lightblue100 rounded-2xl bg-lightblue50 relative">
                        <div className="flex flex-row gap-2 p-3">
                            {data &&
                                data.map((api, a) => (
                                    <div
                                        key={a}
                                        className={`px-4 py-1 cursor-pointer ${
                                            selected && selected.id === api.id ? "bg-white rounded-full shadow-lg shadow-indigo-500/10" : "opacity-40"
                                        }`}
                                        onClick={() => {
                                            setSelected(api);
                                        }}
                                    >
                                        {api.id}
                                    </div>
                                ))}
                        </div>
                        {selected && (
                            <>
                                <div
                                    className="transition-all absolute bottom-4 right-4 bg-white rounded-full p-4 shadow-lg shadow-indigo-500/30 cursor-pointer hover:opacity-50 z-50 "
                                    onClick={() => {
                                        setCopied(copyToClipboard(selected.query));
                                        //setCopied(copyToClipboard(localStorage.getItem("api-curl")));
                                    }}
                                >
                                    {!copied ? <FaRegCopy className="text-lg" /> : <LuCheckCircle className="text-lg text-emerald-500" />}
                                </div>

                                <div className="flex-1 relative overflow-hidden">
                                    <div className="absolute p-6 left-0 top-0 w-full h-full overflow-auto">
                                        <pre className="text-wrap">{selected.query}</pre>
                                        {/* <pre className="text-wrap">{localStorage.getItem("api-curl")}</pre> */}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {/* <div className="flex-1 flex flex-col  relative gap-3">
                    <div className="flex-1 flex flex-col border border-lightblue100 rounded-2xl bg-lightblue50 relative">
                        <div className="p-3 opacity-40">Sample Result :</div>
                        <div
                            className="transition-all absolute bottom-4 right-4 bg-white rounded-full p-4 shadow-lg shadow-indigo-500/30 cursor-pointer hover:opacity-50 z-50 "
                            onClick={() => {
                                setCopied(copyToClipboard(localStorage.getItem("api-result")));
                            }}
                        >
                            {!copiedResult ? <FaRegCopy className="text-lg" /> : <LuCheckCircle className="text-lg text-emerald-500" />}
                        </div>

                        <div className="flex-1 relative overflow-hidden">
                            <div className="absolute p-6 left-0 top-0 w-full h-full overflow-auto">
                                <pre className="text-nowrap">{`[
  {
    "createdate": "2024-09-19 11:53:58.207 +0000 +0000",
    "id": "O8IBrw3475f78",
    "name": "girl",
    "result": "https://svc.staging.squadz.live/download/O8I82Sc8ec571/O8I82S57b13e0/O8IBsWf05d9cc/girl_result.mp4",
    "state": "complete",
    "text": "What's up! It feels like ages since we last talked. How've you been?",
    "usevoice": "fromId",
    "videourl": "https://svc.staging.squadz.live/download/O8I82Sc8ec571/O8IBrw3064ece/O8IBrwbef2591/girl_video.mp4",
    "voiceid": "O8IBc4de045a1"
  }
]`}</pre>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};

export default ApiBox;
