import { Stage, Layer, Rect } from "react-konva";
import { useEffect, useRef, useState } from "react";
import ImageLayer from "./layers/image";
import TextLayer from "./layers/text";
import PreviewLayer from "./layers/preview";
import { Group, Image } from "react-konva";
import TransitionLayer from "./layers/transition";
import VideoPlayer from "src/common/components/video-player/video-player";

const StageContainer = (props) => {
    const {
        previewOnly,
        containerRef,
        project,
        setProject,
        stageSize,
        setStageSize,
        selectedScene,
        setSelectedScene,
        selectedDetail,
        setSelectedDetail,
        scaleX,
        scaleY,
    } = props;

    const backgroundRef = useRef(null);
    const stageRef = useRef(null);
    const [stageMode, setStageMode] = useState("edit");
    useEffect(() => {
      setStageMode("edit")
    }, [selectedScene])

    useEffect(() => {
        updateStageSize();
        window.addEventListener("resize", updateStageSize);
        return () => window.removeEventListener("resize", updateStageSize);
    }, [project]);

    const updateStageSize = () => {
        if (containerRef?.current && setStageSize) {
            const { offsetWidth, offsetHeight } = containerRef.current;
            const containerAspectRatio = offsetWidth / offsetHeight;
            const screenAspectRatio = project.width / project.height;

            let newWidth, newHeight;
            if (containerAspectRatio > screenAspectRatio) {
                // Fit by height
                newHeight = offsetHeight;
                newWidth = newHeight * screenAspectRatio;
            } else {
                // Fit by width
                newWidth = offsetWidth;
                newHeight = newWidth / screenAspectRatio;
            }
            setStageSize({
                width: newWidth,
                height: newHeight,
            });
        }
    };

    useEffect(() => {
        if (backgroundRef.current) {
            const layerToImage = backgroundRef.current.toDataURL({
                width: project.width, // Optional: desired width
                height: project.height, // Optional: desired height
                pixelRatio: 1, // Optional: for higher quality rendering
            });
            selectedScene.background_base64 = layerToImage;
        }
    }, [selectedDetail]);

    useEffect(() => {
        if (setSelectedScene && selectedScene && backgroundRef.current && !selectedScene.backgroundRef) {
            setSelectedScene((prev) => ({ ...prev, backgroundRef: backgroundRef.current, stageRef: stageRef.current }));
        }
    }, [selectedScene]);

    return (
        <>
            {stageMode === "preview" && selectedScene?.result?.src && (
                <div className="absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-full h-full z-20 flex items-center justify-center" style={{ width: stageSize.width, height: stageSize.height, transform: `scale(${scaleX}, ${scaleY})` }}>
                  
                        <VideoPlayer src={selectedScene.result.src} fluid={true} actions={true} fit={"contain"} />
                    
                </div>
            )}

            {stageMode === "edit" && (
                <Stage ref={stageRef} width={stageSize.width} height={stageSize.height} scaleX={scaleX} scaleY={scaleY}>
                    <Layer>
                        <Rect width={stageSize.width} height={stageSize.height} fill={"#ffffff"} />
                    </Layer>

                    {/* {stageMode === "preview" && selectedScene?.result?.src && (
                    <Layer>
                       
                        <PreviewLayer selectedScene={selectedScene} setSelectedScene={setSelectedScene} stageSize={stageSize} />
                    </Layer>
                )} */}

                    <>
                        {selectedScene?.background && (
                            <Layer ref={backgroundRef}>
                                <Rect width={project.width} height={project.height} fill={selectedScene.background} />
                            </Layer>
                        )}
                        <Layer>
                            {selectedScene?.inputs.map((input, i) => (
                                <ImageLayer
                                    key={i}
                                    detail={input}
                                    previewOnly={previewOnly}
                                    selectedDetail={selectedDetail}
                                    setSelectedDetail={setSelectedDetail}
                                    stageSize={stageSize}
                                />
                            ))}
                        </Layer>

                        <Layer>
                            {selectedScene?.steps.map((step, s) => {
                                switch (step.type) {
                                    case "image":
                                    case "video":
                                        return (
                                            <ImageLayer
                                                key={s}
                                                detail={step}
                                                previewOnly={previewOnly}
                                                selectedDetail={selectedDetail}
                                                setSelectedDetail={setSelectedDetail}
                                                stageSize={stageSize}
                                            />
                                        );
                                    case "text":
                                        return (
                                            <TextLayer
                                                key={s}
                                                detail={step}
                                                previewOnly={previewOnly}
                                                selectedDetail={selectedDetail}
                                                setSelectedDetail={setSelectedDetail}
                                                stageSize={stageSize}
                                            />
                                        );
                                    case "transition":
                                        return <TransitionLayer key={s} step={step} selectedScene={selectedScene} index={s} project={project} />;
                                    default:
                                        return null;
                                }
                            })}
                        </Layer>
                    </>
                </Stage>
            )}
            {!previewOnly && selectedScene?.result?.src && (
                <div className="flex bg-white rounded-full border-2 border-lightblue100/90 absolute left-0 top-0 z-20 text-sm overflow-hidden">
                    <div
                        className={`flex p-4 py-2 cursor-pointer hover:bg-lightblue100/20 ${stageMode === "edit" ? "" : "text-black/30 bg-lightblue100/50"}`}
                        onClick={() => {
                            setStageMode("edit");
                        }}
                    >
                        Edit
                    </div>
                    <div
                        className={`flex p-4 py-2 cursor-pointer border-l border-lightblue100 hover:bg-lightblue100/20 ${
                            stageMode === "preview" ? "" : "text-black/30 bg-lightblue100/50"
                        }`}
                        onClick={() => {
                            setStageMode("preview");
                        }}
                    >
                        Generated
                    </div>
                </div>
            )}
        </>
    );
};

export default StageContainer;
