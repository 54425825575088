import { useState, useRef } from "react";
import { formatStepType } from "src/modules/admin/studio/studio-funcs";

import Position from "src/modules/admin/studio/sidebar-detail/components/position";
import Fit from "src/modules/admin/studio/sidebar-detail/components/fit";
import Font from "src/modules/admin/studio/sidebar-detail/components/font";
import UiDSection from "src/modules/admin/studio/components/ui-dsection";
import Media from "src/modules/admin/studio/sidebar-detail/components/media";
import Audio from "src/modules/admin/studio/sidebar-detail/components/audio";
import RangeSlider from "src/modules/admin/studio/sidebar-detail/components/range-slider";
import Effect from "src/modules/admin/studio/sidebar-detail/components/effect";
import UiSelect from "../components/ui-select";

import Scenes from "./components/scenes";
import VideoPlayer from "src/common/components/video-player/video-player";
import { FaRegTrashAlt } from "react-icons/fa";
const SidebarDetail = (props) => {
    const { project, setProject, selectedDetail, setSelectedDetail, stageSize, selectedScene, setSelectedScene, setShowInputAdd } = props;

    const handleStep = ({ opr, step }) => {
        switch (opr) {
            case "delete":
                setSelectedScene((prev) => ({ ...prev, steps: prev.steps.filter((s) => s.id !== step.id) }));
                setSelectedDetail(null);
                break;
        }
    };
    return (
        <div className="sidebar-detail bg-white border border-lightblue100 rounded-2xl w-300 relative flex flex-col">
            <div className="flex flex-col relative flex-1">
                <div className="step-name p-5 border-b border-lightblue100/80 flex items-center justify-between gap-2 capitalize">
                    {selectedDetail?.name ? selectedDetail.name : formatStepType(selectedDetail?.action)}
                    {selectedDetail.detailType === "steps" && (
                        <FaRegTrashAlt
                            className="text-lg w-4 h-4 text-black opacity-50 hover:opacity-100 cursor-pointer"
                            onClick={() => handleStep({ opr: "delete", step: selectedDetail })}
                        />
                    )}
                </div>

                {/* Loop through all fields */}
                <div className="flex-1 relative h-full overflow-hidden">
                    <div className="absolute top-0 left-0 w-full h-full overflow-y-auto overflow-x-hidden flex flex-col">
                        {selectedDetail && (
                            <>
                                <div className="flex-1 flex flex-col relative">
                                    {(selectedDetail.type === "image" || selectedDetail.type === "video") && (
                                        <UiDSection>
                                            <Media setShowInputAdd={setShowInputAdd} selectedDetail={selectedDetail} setSelectedDetail={setSelectedDetail} />
                                        </UiDSection>
                                    )}

                                    {selectedDetail.type === "text" && (
                                        <>
                                            <UiDSection title="Text">
                                                <div className="!border !border-lightblue100 rounded-xl p-2 w-full">
                                                    <textarea
                                                        value={selectedDetail.params.text}
                                                        onChange={(e) =>
                                                            setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, text: e.target.value } }))
                                                        }
                                                    />
                                                </div>
                                            </UiDSection>

                                            <UiDSection title="Font">
                                                <Font selectedDetail={selectedDetail} setSelectedDetail={setSelectedDetail} />
                                            </UiDSection>
                                        </>
                                    )}

                                    {(selectedDetail.type === "image" || selectedDetail.type === "video" || selectedDetail.type === "text") && (
                                        <UiDSection title="Position">
                                            <Position stageSize={stageSize} selectedDetail={selectedDetail} setSelectedDetail={setSelectedDetail} />
                                        </UiDSection>
                                    )}

                                    {(selectedDetail.type === "image" || selectedDetail.type === "video") && (
                                        <UiDSection title="Fit">
                                            <Fit stageSize={stageSize} selectedDetail={selectedDetail} setSelectedDetail={setSelectedDetail} />
                                        </UiDSection>
                                    )}

                                    {selectedDetail.type === "audio" && (
                                        <UiDSection className="flex-1">
                                            <Audio selectedDetail={selectedDetail} setSelectedDetail={setSelectedDetail} />
                                        </UiDSection>
                                    )}

                                    {selectedDetail.type === "audio" && selectedDetail.params.volume && (
                                        <UiDSection>
                                            <RangeSlider
                                                title="Volume"
                                                initialValue={selectedDetail.params.volume}
                                                min={0.01}
                                                max={10}
                                                step={0.01}
                                                OnValue={(val) => setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, volume: val } }))}
                                            />
                                        </UiDSection>
                                    )}

                                    {selectedDetail.type === "generated" && (
                                        <UiDSection>
                                            <div className="w-full h-52 relative overflow-hidden rounded-xl">
                                                <VideoPlayer src={selectedDetail.params.result} fluid={true} hoverPlay={true} actions={false} fit="contain" />
                                            </div>
                                        </UiDSection>
                                    )}

                                    {/* {selectedDetail.options && selectedDetail.options.length > 0 && (
                                        <UiDSection title="Options">
                                            <UiSelect
                                                options={selectedDetail.options}
                                                value={selectedDetail.value}
                                                onChange={(val) => {
                                                    setSelectedDetail((prev) => ({ ...prev, value: val }));
                                                }}
                                            />
                                        </UiDSection>
                                    )} */}

                                    {selectedDetail.type === "effect" && <Effect selectedDetail={selectedDetail} setSelectedDetail={setSelectedDetail} />}

                                    {selectedDetail.type === "transition" && (
                                        <>
                                            <UiDSection title="Transition">
                                                {/* <UiSelect
                                                options={["none", "fade", "wipeleft", "wiperight"]}
                                                value={selectedDetail.params.action}
                                                onChange={(val) => setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, action: val } }))}
                                            /> */}

                                                <RangeSlider
                                                    title="Duration"
                                                    initialValue={selectedDetail.params.duration}
                                                    min={1}
                                                    max={10}
                                                    step={0.1}
                                                    OnValue={(val) => setSelectedDetail((prev) => ({ ...prev, params: { ...prev.params, duration: val } }))}
                                                />
                                            </UiDSection>
                                            <UiDSection title="Scenes" padded={false}>
                                                <Scenes
                                                    project={project}
                                                    setProject={setProject}
                                                    selectedDetail={selectedDetail}
                                                    setSelectedDetail={setSelectedDetail}
                                                    stageSize={stageSize}
                                                    selectedScene={selectedScene}
                                                    setSelectedScene={setSelectedScene}
                                                />
                                            </UiDSection>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SidebarDetail;
