import axios from "axios";
export const dateFormatter = (dateString) => {
    if (!dateString) return "";

    // Parse the input date string as UTC
    const inputDate = new Date(dateString);
    const now = new Date();

    // Convert UTC date to local date
    const localDate = new Date(inputDate.getTime() );
     

    // Get the time difference in milliseconds
    const timeDiff = Math.max(0, now - localDate);
    const diffSeconds = Math.floor(timeDiff / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);

    // If the date is within today
    if (diffDays === 0) {
        if (diffHours >= 1) {
            return `${diffHours} hour${diffHours > 1 ? "s" : ""} ago`;
        } else if (diffMinutes >= 1) {
            return `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""} ago`;
        } else if (diffSeconds >= 30) {
            return `${diffSeconds} seconds ago`;
        } else {
            return `Just now`;
        }
    }
    // If the date is yesterday
    else if (diffDays === 1) {
        return "Yesterday";
    }
    // If the date is 2 days ago
    else if (diffDays === 2) {
        return "2 days ago";
    }

    // For dates older than 2 days, format it as a standard date
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const year = localDate.getFullYear();
    const month = months[localDate.getMonth()];
    const day = localDate.getDate();
    const hours = localDate.getHours().toString().padStart(2, "0");
    const minutes = localDate.getMinutes().toString().padStart(2, "0");

    return `${month} ${day}, ${year} at ${hours}:${minutes}`;
};

export const isVideoMoreThanOneMinute = (file, callback) => {
    const video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = function () {
        const duration = video.duration;
        if (duration > 60) {
            callback(true);
        } else {
            callback(false);
        }
    };

    const reader = new FileReader();
    reader.onload = function () {
        video.src = URL.createObjectURL(file);
    };
    reader.readAsDataURL(file);
};

export const validateMinMax = (input) => {
    let value = parseInt(input.value);
    if (value < input.min) {
        input.value = input.min;
    } else if (value > input.max) {
        input.value = input.max;
    }
};

export const toEpoch = (thedate) => {
    if (thedate) {
        const dateStr = thedate;
        const cleanDateStr = dateStr.replace(" +0000 +0000", "+0000");
        const date = new Date(cleanDateStr);
        const epochTime = date.getTime();

        return epochTime;
    }
};

export const checkFileType = (url) => {
    // Check if the URL starts with https://
    if (!url.startsWith("https://")) {
        return "invalid URL (must use https)";
    }

    // Define the extensions for images and videos
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];
    const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv", "webm", "mkv"];

    // Extract the file extension from the URL
    const extension = url.split(".").pop().toLowerCase();

    // Check if the extension matches image or video types
    if (imageExtensions.includes(extension)) {
        return "image";
    } else if (videoExtensions.includes(extension)) {
        return "video";
    } else {
        return "unknown file type";
    }
};

export const copyToClipboard = (text) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            //console.log("Text copied to clipboard");
            return true;
        })
        .catch((err) => {
            console.error("Failed to copy text: ", err);
            return false;
        });
};

export const pollApi = ({ url, method, data, setData, setLoading, setError, intervalTime = 10000 }) => {
    const token = localStorage.getItem("DIGITALU_token");
    const fetchData = async () => {
        try {
            const config = {
                method: method ? method : "post",
                url: `${url}`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`,
                },
            };

            if (data) {
                config.data = data;
            }

            const response = await axios(config);
            setData(response.data);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
        }
    };

    // Fetch data initially
    fetchData();

    // Set up polling
    const interval = setInterval(() => {
        fetchData();
    }, intervalTime);

    // Return a cleanup function to stop polling
    return () => clearInterval(interval);
};

export const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

export const getNameFromUrl = (url, nameOnly) => {
    if (!url) {
        return;
    }
    let thename = url.split("/")[url.split("/").length - 1];

    if (nameOnly && thename.indexOf(".") !== -1) {
        thename = thename.split(".")[0];
    }
    return thename;
};

export const shorten = (text) => {
    let result = text;
    if (text && text.length > 25) {
        const firstPart = text.slice(0, 15);
        const lastPart = text.slice(-6);
        result = `${firstPart}...${lastPart}`;
    }

    return result;
};

export const captureFirstFrame = (blobUrl, callback) => {
    // Create a video element
    const video = document.createElement('video');
    video.src = blobUrl;
    video.crossOrigin = 'anonymous'; // Optional, in case of cross-origin issues
  
    // Set up the canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
  
    // Load metadata to get video dimensions
    video.addEventListener('loadedmetadata', function () {
      // Set canvas size to video size
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
  
      // Seek to the first frame (0 seconds)
      video.currentTime = 0;
    });
  
    // Once the video seeks to 0 seconds, capture the frame
    video.addEventListener('seeked', function () {
      // Draw the first frame onto the canvas
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  
      // Convert the canvas content to a data URL (image/png format)
      const imageDataUrl = canvas.toDataURL('image/png');
  
      // Call the callback with the captured image
      callback(imageDataUrl);
  
      // Cleanup the video element after capture
      video.pause();
      video.src = '';
    });
  
    // Error handling
    video.onerror = function () {
      callback(null, new Error('Failed to load video'));
    };
  };



  export const handleDownload = async (url, filename) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error('Download failed:', error);
    }
};


export const processUrl = (url) => {
    const isVideo = /\.(mp4|mov|avi|wmv|flv|webm)$/i.test(url);
    const isImage = /\.(jpg|jpeg|png|gif|webp|bmp)$/i.test(url);
    return isVideo ? url + '/frame1.jpg' : isImage ? url : url;
};