import { useState, useEffect, useRef } from "react";

import { FaPlay, FaPause } from "react-icons/fa";
import CardAudio from "src/modules/admin/voices/card-audio";

const Audio = (props) => {
    const { selectedDetail, setSelectedDetail } = props;
    const [songs, setSongs] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [playingSong, setPlayingSong] = useState(null);
    const audioRef = useRef(null);

    const audioTabs = [
        {
            name: "Library",
            id: "library",
        },
        {
            name: "Upload",
            id: "upload",
        },
    ];
    const [selectedTab, setSelectedTab] = useState("library");

    useEffect(() => {
        const fetchSongs = async () => {
            try {
                const response = await fetch(
                    "https://raw.githubusercontent.com/saulamsal/apple-music-sheet-ui/df59ca6ae24e252241b399046259bc8a9646dcdf/data/songs.json"
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch songs");
                }
                const data = await response.json();
                const songsArray = data.songs || Object.values(data);
                setSongs(songsArray);
            } catch (err) {
                console.error("Fetch error:", err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSongs();
    }, []);

    const handlePlayPause = (song, e) => {
        e.stopPropagation();

        if (playingSong === song.id) {
            audioRef.current.pause();
            setPlayingSong(null);
        } else {
            if (playingSong) {
                audioRef.current.pause();
            }
            audioRef.current.src = song.mp4_link;
            audioRef.current    
                .play()
                .then(() => {
                    setPlayingSong(song.id);
                })
                .catch((error) => {
                    console.log("error playing audio :", error);
                });
        }
    };

    const filteredSongs = songs.filter(
        (song) => song.title.toLowerCase().includes(searchTerm.toLowerCase()) || song.artist.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (loading) {
        return (
            <div className="p-4 flex justify-center items-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
            </div>
        );
    }

    if (error) {
        return <div className="p-4 text-red-500">Error: {error}</div>;
    }

    if (!Array.isArray(songs)) {
        return <div className="p-4 text-red-500">Error: Invalid data format</div>;
    }

    const handleSong = ({ opr, song }) => {
        switch (opr) {
            case "select":
                setSelectedDetail((prev) => ({
                    ...prev,
                    params: {
                        ...prev.params,
                        id: song.id,
                        src: song.mp4_link,
                        file: null,
                    },
                }));
                break;
        }
    };

    const Library = () => {
        return (
            <>
                <audio ref={audioRef} className="hidden absolute" />
                <div className="f flex audio-search border border-lightblue100 rounded-xl">
                    <input type="text" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                </div>
                <div className="flex min-h-[300px] flex-col gap-4 relative overflow-hidden">
                    <div className="absolute top-0 left-0 w-full h-full flex flex-col gap-4 overflow-y-auto p-1">
                        {filteredSongs.map((song) => (
                            <div
                                key={song.id}
                                className={`flex items-center space-x-4 p-3 rounded-lg cursor-pointer transition-colors duration-200
                            ${selectedDetail.params?.id === song.id ? "bg-blue-50 ring-2 ring-blue" : "hover:bg-gray-100"}`}
                                style={{ backgroundColor: selectedDetail.params?.id !== song.id ? `${song.artwork_bg_color}10` : undefined }}
                                onClick={() => handleSong({ opr: "select", song })}
                            >
                                <img src={song.artwork} alt={song.title} className="w-12 h-12 rounded-md object-cover shadow-sm" />
                                <div className="flex-1">
                                    <div className="line-clamp-1">{song.title}</div>
                                    <div className="text-sm text-gray-500 line-clamp-1">{song.artist}</div>
                                </div>
                                <button onClick={(e) => handlePlayPause(song, e)} className="p-2 hover:bg-gray-200 rounded-full transition-colors duration-200">
                                    {playingSong === song.id ? <FaPause className="w-3 h-3 text-gray-600" /> : <FaPlay className="w-3 h-3 text-gray-600" />}
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    };

    const Upload = () => {
        return (
            <div>
                {selectedDetail.params.file && (
                    <div className={`relative overflow-hidden rounded-xl w-full`} onClick={() => {}}>
                        <CardAudio voice={{ url: selectedDetail.params.src }} name={selectedDetail.params.file.name} />
                    </div>
                )}
                {!selectedDetail.params.file && (
                    <div
                        className="transition w-full h-52 scene-step-add flex flex-col items-center justify-center gap-2 p-3 group border border-dashed border-blue bg-blue/10 text-blue rounded-xl cursor-pointer"
                        onClick={() => document.getElementById("mediaInput").click()}
                    >
                        <div className="scene-step-action w-6 flex items-center items-center justify-center text-lg">+</div>
                        <div className="scene-step-add-type">Add Audio</div>
                        <input
                            type="file"
                            id="mediaInput"
                            className="hidden"
                            accept="audio/*"
                            onChange={(e) => {
                                setSelectedDetail((prev) => ({
                                    ...prev,
                                    params: {
                                        ...prev.params,
                                        src: URL.createObjectURL(e.target.files[0]),
                                        file: e.target.files[0],
                                    },
                                }));
                            }}
                        />
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="flex-1 flex flex-col gap-4 overflow-hidden">
            <div className="flex gap-4">
                {audioTabs.map((tab) => (
                    <div
                        key={tab.id}
                        className={`cursor-pointer ${selectedTab === tab.id ? "text-blue" : "text-black/30"}`}
                        onClick={() => setSelectedTab(tab.id)}
                    >
                        {tab.name}
                    </div>
                ))}
            </div>
            {selectedTab === "library" && <Library />}
            {selectedTab === "upload" && <Upload />}
        </div>
    );
};

export default Audio;
