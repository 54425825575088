import { useEffect, useState } from "react";
import axios from "axios";
import { MdOutlineFileDownload } from "react-icons/md";
import { TbRotateClockwise2 } from "react-icons/tb";
import { genNobg } from "src/common/services/generate";
import { handleDownload } from "src/common/utils/utils";
const NoBg = ({ video }) => {
    const [nobg, setNobg] = useState("checking");
    const initialLink = {
        href: `${video.result}/nobg.mp4`,
        target: null,
        label: (
            <>
                Remove Background{" "}
                <div className="text-amber-500 text-xs w-6 h-6 rounded-full flex items-center justify-center border-2 border-amber-500 font-semibold">5</div>
            </>
        ),
        className: "",
    };

    const [link, setLink] = useState(initialLink);

    useEffect(() => {
        handleNoBg();
    }, []);

    useEffect(() => {
        switch (nobg) {
            case "initial":
                setLink(initialLink);
                break;

            case "checking":
                link.label = "Checking";
                link.className = "text-amber-500 loading";
                setLink({ ...link });
                break;

            case "processing":
                link.label = (
                    <>
                        Removing Background <TbRotateClockwise2 className="text-lg animate-spin" />
                    </>
                );
                link.className = "text-amber-500";

                setLink({ ...link });
                break;

            case "done":
                link.label = (
                    <>
                        Background Removed <MdOutlineFileDownload className="text-lg" />
                    </>
                );
                link.className = "text-teal-500";
                link.target = "_blank";

                setLink({ ...link });
                break;
            default:
                break;
        }
    }, [nobg]);

    const handleNoBg = async () => {
        let theopr = "get";

        if (nobg === "initial") {
            theopr = "set";
        }

        try {
            setNobg("checking");

            const up_res = await genNobg({ opr: theopr, video });
            //console.log("get res: ", up_res);
            if (up_res && up_res[0]?.message) {
                const message = up_res[0]?.message;
                switch (true) {
                    case message === "no nobg video found":
                        setNobg("initial");
                        break;
                    case message === "in processing":
                        setNobg("processing");
                        break;
                    case message !== "no nobg video found" && message !== "in processing" && message !== "":
                        setNobg("done");
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            //console.log(error);
        }
    };

    return (
        <a
            href={link.href}
            target={link.target}
            //    download={nobg === "done" ? `${video.result}/nobg.mp4` : "#"}
            className="px-6 py-1 transition cursor-pointer hover:opacity-70 hover:translate-x-0.5 flex-1  "
            onClick={async (e) => {
                e.preventDefault();
                if (nobg === "done") {
                    handleDownload(video.result + "/nobg.mp4", video.name);
                }else{
                    await handleNoBg();
                }

             
            }}
        >
            <div className={`flex-1 flex flex-row items-center justify-between gap-4 ${link.className}`}>{link.label}</div>
        </a>
    );
};

export default NoBg;
