import VideoPlayer from "src/common/components/video-player/video-player";
import { GrUploadOption } from "react-icons/gr";

const VideoUpload = (props) => {
    const { genData } = props;

    return (
        <div className="flex-1 flex h-full">
            <div className="flex-1 flex w-full min-h-32  border border-dashed border-blue rounded-2xl bg-lightblue80 items-center justify-center  relative overflow-hidden">
                {genData.file && genData.file.name && (
                    <div className="max-h-400 min-h-400 max-w-600 min-w-400 aspect-square flex flex-col items-center justify-center p-4 gap-4">
                        {genData.file.type.indexOf("video") !== -1 && (
                            <VideoPlayer
                                src={URL.createObjectURL(genData.file)}
                                fluid={true}
                                className="object-contain min-w-14 min-h-14 rounded-2xl relative overflow-hidden bg-black"
                            />
                        )}

                        {genData.file.type.indexOf("image") !== -1 && (
                            <img
                                src={URL.createObjectURL(genData.file)}
                                className="object-contain min-w-14 min-h-14 max-h-96 rounded-2xl relative overflow-hidden bg-black"
                            />
                        )}

                        <div className="flex flex-row items-center gap-5">
                            <div>{genData.file.name}</div>
                            <button
                                className="button button-small button-white px-3 py-1 text-xs"
                                onClick={() => {
                                    delete genData.file;
                                    delete genData.videoid;
                                    delete genData.videourl;
                                    delete genData.imageid;
                                    delete genData.imageurl;

                                    props.OnSet({...genData});
                                    // setGenData({...genData})
                                }}
                            >
                                remove
                            </button>
                        </div>
                    </div>
                )}
                <label className="relative cursor-pointer">
                    {!genData.file &&
                    
                    <div className="flex flex-col gap-4 items-center justify-center">
                    <button className="relative z-10 button-white px-3 py-3 pr-5 rounded-3xl cursor-pointer flex  items-center gap-4 ">
                        <GrUploadOption className="text-xl" />
                        Upload Video or Image
                    </button>

                    <div className="opacity-50">Accepts video and image types.</div>
                </div>
                    
                        
                    }
                    <input
                        type="file"
                        title=""
                        value={''}
                        accept={`video/*, image/*`}
                        className="absolute z-50 left-0 top-0 opacity-0 w-full h-full cursor-pointer"
                        onChange={async (e) => {
                         
                            genData.file = e.target.files[0];

                            // delete genData.videoid;
                            // delete genData.videourl;
                            // delete genData.imageid;
                            // delete genData.imageurl;

                            props.OnSet({...genData});
                            // setGenData({ ...genData });
                        }}
                    />
                </label>
            </div>
        </div>
    );
};

export default VideoUpload;
