import { useEffect, useRef, useState, useCallback } from "react";
import gsap from "gsap";
import Logo from "src/common/components/logo/logo";
import React from "react";
import vid1 from "src/common/assets/vids/richard2_result.mp4";

import vid2 from "src/common/assets/vids/richard3_result.mp4";
import { FaTiktok, FaPlay, FaPause, FaInstagram, FaYoutube } from "react-icons/fa";
import { FiYoutube } from "react-icons/fi";
import intro from "src/common/assets/vids/intro.mp4";
import introVert from "src/common/assets/vids/intro-vert.mp4";
import logo from "src/common/assets/images/logo.svg";
import { PiInstagramLogoFill } from "react-icons/pi";
import MarketingLayoutMinimal from "src/layouts/marketing/marketing-layout-minimal";

import creator1 from "src/common/assets/vids/creator1.mp4";
import vlogger1 from "src/common/assets/vids/vlogger1.mp4";
import artist1 from "src/common/assets/vids/artist1.mp4";
import gamer1 from "src/common/assets/vids/gamer1.mp4";
import influencer1 from "src/common/assets/vids/influencer1.mp4";
import agent1 from "src/common/assets/vids/agent1.mp4";
import marketer1 from "src/common/assets/vids/marketer1.mp4";

import { HiMiniSpeakerWave } from "react-icons/hi2";
import { HiMiniSpeakerXMark } from "react-icons/hi2";

const HomePage = () => {
    const textRef = useRef(null);
    const [translateY, setTranslateY] = useState(-360);
    const fieldsWrapperRef = useRef(null);

    const [fields, setFields] = useState([
        { name: "Marketer", active: false, vid: marketer1 },
        { name: "Gamer", active: false, vid: gamer1 },
        { name: "Vlogger", active: false, vid: vlogger1 },
        { name: "U", active: true, vid: creator1 },
        { name: "Influencer", active: false, vid: influencer1 },
        { name: "Agent", active: false, vid: agent1 },
        { name: "Artist", active: false, vid: artist1 },
    ]);

    const [activeVideo, setActiveVideo] = useState(null);

    // Add a new state to track animation completion
    const [animationComplete, setAnimationComplete] = useState(false);
    const [preloading, setPreloading] = useState(true);
    const logoRef = useRef(null);

    // Add new ref for video element
    const videoRef = useRef(null);
    // Add state to track playing status
    const [isPlaying, setIsPlaying] = useState(false);

    // Add new ref for intro video
    const introVideoRef = useRef(null);


    const [showVideo, setShowVideo] = useState(false);

    const cursorRef = useRef(null);

    // Add this near the top with other state declarations
    const [isMuted, setIsMuted] = useState(true);

    // Add new state for tracking video loading
    const [videosLoaded, setVideosLoaded] = useState(false);
    const videoElements = useRef({});

    // Add this near your other state/ref declarations
    const videoWrapperRefs = useRef({});

    // Add this near other state declarations
    const [isMobile, setIsMobile] = useState(false);

    // Add this useEffect to detect mobile screens
    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768); // You can adjust this breakpoint
        };

        checkMobile(); // Initial check
        window.addEventListener("resize", checkMobile);

        return () => window.removeEventListener("resize", checkMobile);
    }, []);

    // Add preload function
    useEffect(() => {
        const videoUrls = [creator1, vlogger1, artist1, gamer1, influencer1, agent1, marketer1];
        let loadedCount = 0;

        // Create and preload all videos
        videoUrls.forEach((url) => {
            const video = document.createElement("video");
            video.src = url;
            video.preload = "auto";

            // Store reference to video element
            videoElements.current[url] = video;

            video.onloadeddata = () => {
                loadedCount++;
                if (loadedCount === videoUrls.length) {
                    setVideosLoaded(true);
                }
            };
        });
    }, []);

    // Modify the video rendering to use preloaded videos
    useEffect(() => {
        if (videoRef.current && activeVideo && videoElements.current[activeVideo]) {
            videoRef.current.src = videoElements.current[activeVideo].src;
        }
    }, [activeVideo]);

    useEffect(() => {
        // Add loading animation
        const tl = gsap.timeline();
        tl.fromTo(
            logoRef.current,
            { opacity: 0, scale: 1.2 },
            {
                opacity: 1,
                duration: 1.5,
                scale: 1,
                ease: "power3.out",
                onComplete: () => {
                    setShowVideo(true);
                    if (introVideoRef.current) {
                        introVideoRef.current.play();
                    }
                },
            }
        );
    }, []);

    useEffect(() => {
        if (!preloading) {
            // Set initial position of fields wrapper
            gsap.set(fieldsWrapperRef.current, {
                y: -360,
            });

            gsap.set(".anim-wrapper", {
                x: 220,
            });

            gsap.set(".field-item", {
                opacity: 0,
            });

            const tl = gsap.timeline();

            // Only run text animation if not on mobile
            if (!isMobile) {
                const text = textRef.current;
                const chars = text.innerText.split("").map((char) => (char === " " ? "\u00A0" : char));
                text.innerHTML = chars.map((char) => `<span>${char}</span>`).join("");

                // Add delay before starting letter animations
                tl.to({}, { duration: 0.7 }); // 0.5 second delay

                // Fade in letters one by one
                tl.from(text.children, {
                    opacity: 0,
                    duration: 0.01,
                    stagger: 0.1,
                    ease: "none",
                });
            }

            // Fade in only the active field (initially "Creator")
            tl.to(
                ".field-item.text-active",
                {
                    opacity: 1,
                    duration: 0.01,
                    ease: "none",
                },
                "+=0.01"
            );

            tl.to(".anim-wrapper", {
                x: 0,
                duration: 1,
                ease: "power3.out",
            });

            // Add new animation to change "U" to "Creator"
            tl.to(".field-item.text-active", {
                opacity: 0,
                duration: 0.2,
                onComplete: () => {
                    setFields((prev) => {
                        const updatedFields = prev.map((field) => (field.active ? { ...field, name: "Creator" } : field));
                        // Find the active field and set its video
                        const activeField = updatedFields.find((field) => field.active);
                        setActiveVideo(activeField?.vid || vid1);
                        return updatedFields;
                    });
                },
            });

            tl.to(".field-item.text-active", {
                opacity: 1,
                duration: 0.3,
            });

            tl.to(
                ".field-item",
                {
                    opacity: 1,
                    duration: 0.5,
                    ease: "power1.out",
                    onComplete: () => setAnimationComplete(true),
                },
                "+=0.01"
            );

            tl.to(
                textRef.current,
                {
                    opacity: 0,
                    x: -100,
                    duration: 0.5,
                    ease: "power1.out",
                },
                "+=0.5"
            );

            tl.from(
                ".hero-video",
                {
                    opacity: 0,
                    x: -100,
                    duration: 0.5,
                    ease: "power1.out",
                    onComplete: () => {
                        if (videoRef.current) {
                            videoRef.current.play();
                            setIsPlaying(true);
                        }
                    },
                },
                "-=0.3"
            );
        }
    }, [preloading]);

    const handleWheel = useCallback(
        (e) => {
            // if (!animationComplete) return;

            e.preventDefault();
            const direction = e.deltaY > 0 ? -1 : 1;
            const newTranslate = translateY + direction * 120;
            const maxScroll = -(fields.length - 1) * 120;

            if (direction === 1 && translateY === 0) return;
            if (direction === -1 && translateY === maxScroll) return;

            setTranslateY(newTranslate);
            gsap.to(fieldsWrapperRef.current, {
                y: newTranslate,
                duration: 0.1,
                ease: "power1.out",
            });

            // Get the new active video
            const newActiveVideo = fields[Math.abs(newTranslate / 120)].vid;
            setActiveVideo(newActiveVideo);
            setIsPlaying(false);

            if (videoRef.current) {
                // Reset the video element
                videoRef.current.pause();
                videoRef.current.currentTime = 0;

                // Wait for the new video to load before playing
                videoRef.current.onloadeddata = () => {
                    videoRef.current
                        .play()
                        .then(() => {
                            setIsPlaying(true);
                        })
                        .catch((error) => {
                            //console.log("error playing video:", error);
                            setIsPlaying(false);
                        });
                };
            }

            setFields((prevFields) => {
                const activeIndex = Math.abs(newTranslate / 120);
                return prevFields.map((field, index) => ({
                    ...field,
                    active: index === activeIndex,
                }));
            });
        },
        [translateY, fields.length]
    );

    useEffect(() => {
        if (!preloading) {
            const wrapper = document.querySelector(".anim-wrapper");
            wrapper.addEventListener("wheel", handleWheel, { passive: false });

            return () => {
                wrapper.removeEventListener("wheel", handleWheel);
            };
        }
    }, [handleWheel]);

    const playNextVideo = useCallback(() => {
        // Find current active index
        const currentIndex = fields.findIndex((field) => field.active);
        // Calculate next index (go back to 0 if at end)
        const nextIndex = currentIndex === fields.length - 1 ? 0 : currentIndex + 1;

        // Create a timeline for synchronized animations
        const tl = gsap.timeline();

        // First, pause the current video
        if (videoRef.current) {
            videoRef.current.pause();
        }

        // Animate the fields wrapper with a smooth scroll
        tl.to(fieldsWrapperRef.current, {
            y: nextIndex * -120,
            duration: 0.8,
            ease: "power1.out",
            onComplete: () => {
                setTranslateY(nextIndex * -120);
                setFields((prev) =>
                    prev.map((field, index) => ({
                        ...field,
                        active: index === nextIndex,
                    }))
                );
                setActiveVideo(fields[nextIndex].vid);

                // Add a slight delay before playing the new video
                setTimeout(() => {
                    if (videoRef.current) {
                        videoRef.current.currentTime = 0;
                        videoRef.current
                            .play()
                            .then(() => setIsPlaying(true))
                            .catch((error) => {
                                //console.log("error playing video:", error);
                                setIsPlaying(false);
                            });
                    }
                }, 200); // 200ms delay after scroll completes
            },
        });
    }, [fields]);

    // Inside the HomePage component, add this effect to handle video transitions
    useEffect(() => {
        if (activeVideo && videoWrapperRefs.current) {
            // Create a timeline for synchronized animations
            const tl = gsap.timeline();

            // Start fading in the active video slightly before the others fade out
            const activeWrapper = videoWrapperRefs.current[activeVideo];
            if (activeWrapper) {
                tl.to(
                    activeWrapper,
                    {
                        opacity: 1,
                        scale: 1,
                        duration: 0.8,
                        ease: "power1.out",
                    },
                    0
                ); // Start at 0 seconds
            }

            // Fade out non-active videos with a slight delay
            fields.forEach((field) => {
                const wrapper = videoWrapperRefs.current[field.vid];
                if (field.vid !== activeVideo && wrapper) {
                    tl.to(
                        wrapper,
                        {
                            opacity: 0,
                            scale: 1.1,
                            duration: 0.8,
                            ease: "power1.out",
                        },
                        0.2
                    ); // Start at 0.2 seconds, creating a 0.2s overlap
                }
            });
        }
    }, [activeVideo, fields]);

    const [showPlayPrompt, setShowPlayPrompt] = useState(false);

    useEffect(() => {
        if (introVideoRef.current) {
            const timer = setTimeout(() => {
                if (introVideoRef.current?.paused) {
                    setShowPlayPrompt(true);
                }
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, []);

    return (
        <>
            {preloading ? (
                <div className="relative overflow-hidden flex items-center justify-center h-screen">
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                        <div className="relative overflow-hidden h-full md:p-10 ">
                            <video
                                ref={introVideoRef}
                                src={isMobile ? introVert : intro}
                                className={`w-full h-full object-contain transition-opacity duration-700 ease-in-out relative overflow-hidden rounded-3xl ${
                                    showVideo ? "opacity-100" : "opacity-0"
                                }`}
                                onEnded={() => setPreloading(false)}
                                onPlay={() => setShowPlayPrompt(false)}
                                muted={isMuted}
                                autoPlay
                                playsInline
                            ></video>

                            {showPlayPrompt && (
                                <div className="absolute inset-0 flex items-center justify-center bg-black/50 z-50">
                                    <button 
                                        className="bg-white text-black px-6 py-3 rounded-full font-medium hover:bg-gray-100 cursor-pointer flex items-center gap-2"
                                        onClick={() => {
                                            introVideoRef.current?.play();
                                            setShowPlayPrompt(false);
                                        }}
                                    >
                                       <FaPlay className="text-xs" />  Play Video 
                                    </button>
                                </div>
                            )}

                            <div className="absolute bottom-5 md:bottom-24 left-0 z-20 flex gap-4 w-full flex items-center justify-between">
                                <button
                                    className={`ml-5 md:ml-24 text-xl text-white bg-black/60 p-3 rounded-full cursor-pointer transition-opacity duration-700 ease-in-out ${
                                        showVideo ? "opacity-100" : "opacity-0"
                                    }`}
                                    onClick={() => setIsMuted(!isMuted)}
                                >
                                    {isMuted ? <HiMiniSpeakerXMark /> : <HiMiniSpeakerWave />}
                                </button>

                                <button
                                    className={`mr-5 md:mr-24 flex items-center gap-2 text-white bg-black/60 px-6 py-2 rounded-full cursor-pointer transition-opacity duration-700 ease-in-out ${
                                        showVideo ? "opacity-100" : "opacity-0"
                                    }`}
                                    onClick={() => setPreloading(false)}
                                >
                                    Skip intro <FaPlay className="text-xs" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div
                        className={`flex flex-col gap-20 items-center justify-center relative z-10 transition-opacity duration-700 ease-in-out ${
                            showVideo ? "opacity-0" : "opacity-100"
                        }`}
                    >
                        <img ref={logoRef} src={logo} className="w-200 md:w-400 opacity-0" />
                        {/* <div
                            className="start-text cursor-pointer text-base font-semibold flex items-center gap-2 hover:opacity-80 opacity-0 "
                            onClick={handleStartClick}
                        >
                            Click to start <FaPlay className="text-xs" />
                        </div> */}
                    </div>
                </div>
            ) : (
                <MarketingLayoutMinimal>
                    <div className="container mx-auto hero flex-1 flex items-center justify-center relative overflow-hidden md:overflow-visible">
                        <div className="anim-wrapper z-40 md:z-10 absolute left-0 top-1/2  md:top-0  md:relative flex md:gap-5 gap-2 max-h-120px  flex-col md:flex-row px-5 md:px-0">
                            <div
                                ref={textRef}
                                className="hidden md:flex text-4xl md:text-8xl h-120px max-h-[120px] font-bold relative  items-center tracking-tighter text-nowrap"
                            >
                                Create your Digital
                            </div>
                            <div ref={fieldsWrapperRef} className="flex flex-col fields-wrapper transition-all">
                                {fields.map((field, index) => (
                                    <div
                                        key={index}
                                        id={`field-${index}`}
                                        className={`text-7xl md:text-8xl font-bold tracking-tighter field-item h-120px flex items-center text-nowrap ${
                                            field.active ? "text-active text-white md:text-black" : "text-white/20 md:text-black/20"
                                        }`}
                                    >
                                        {field.name}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="hidden md:block absolute bottom-0 left-0 right-0 h-[100px] md:h-[200px] w-full bg-gradient-to-t from-white z-20"></div>
                        <div className="hidden md:block absolute top-0 left-0 right-0 h-[100px] md:h-[200px] w-full bg-gradient-to-b from-white z-20"></div>

                        <div className="bg-black md:bg-transparent hero-video flex h-full md:h-[90%] w-600 md:w-[1500px] absolute md:top-0 bottom-0 md:right-[40%] right-0 overflow-hidden md:rounded-full md:top-1/2 md:-translate-y-1/2 z-30">
                            {fields.map((field, index) => (
                                <div
                                    key={index}
                                    ref={(el) => (videoWrapperRefs.current[field.vid] = el)}
                                    className="absolute inset-0 bg-black"
                                    style={{
                                        opacity: field.vid === activeVideo ? 1 : 0,
                                        transform: "scale(1.1)",
                                    }}
                                >
                                    <video
                                        ref={(el) => {
                                            if (el) {
                                                videoElements.current[field.vid] = el;
                                                if (field.vid === activeVideo) {
                                                    videoRef.current = el;
                                                } else {
                                                    el.pause();
                                                    el.currentTime = 0;
                                                }
                                            }
                                        }}
                                        src={field.vid}
                                        className="w-full h-full object-cover opacity-70 md:opacity-100"
                                        onEnded={playNextVideo}
                                        muted={isMuted}
                                        autoPlay
                                        playsInline
                                    />
                                </div>
                            ))}

                            <div
                                onClick={() => setIsMuted(!isMuted)}
                                className="absolute bottom-10 md:top-1/2 md:-translate-y-1/2 right-5 md:right-20 flex items-center justify-center gap-2 text-white gap-3 text-lg cursor-pointer transition-all hover:opacity-80 hover:pr-3 z-10"
                            >
                                {isMuted ? (
                                    <>
                                        <HiMiniSpeakerXMark className="text-2xl" /> unmute
                                    </>
                                ) : (
                                    <>
                                        <HiMiniSpeakerWave className="text-2xl" /> mute
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </MarketingLayoutMinimal>
            )}
        </>
    );
};

export default HomePage;
