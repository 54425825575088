import { useState, useRef, useEffect } from "react";
import { HexColorPicker, RgbaColorPicker } from "react-colorful";
import "./ui-color-picker.css";
import { LuPalette } from "react-icons/lu";
import { CgColorPicker } from "react-icons/cg";

const UiColorPicker = (props) => {
    const { title, value, setValue, showDefaultColors = true, pickerPos = "top" } = props;
    const hexColorRegex = /^#([A-Fa-f0-9]{6})$/;
    const solidDefaults = [
        { type: "color", value: "#ffffff" },
        { type: "color", value: "#F44A5E" },
        // { type: "color", value: "#FF5C36" },
        { type: "color", value: "#FBD757" },
        { type: "color", value: "#61C884" },
        { type: "color", value: "#236AFF" },
        // { type: "color", value: "#5E3DFC" },
        { type: "color", value: "#111111" },
    ];

    const [showPicker, setShowPicker] = useState(false);
    const pickerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setShowPicker(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="gap-6 flex flex-col w-full">
            <div className="flex flex-1 flex-row justify-between items-center gap-2">
                {title && <div className="flex-1">{title} :</div>}
                <div className="flex flex-1 flex-row gap-2 relative">
                    <div className="border border-lightblue100 rounded-xl w-full flex items-center">
                        <div
                            className={`cursor-pointer  h-full flex items-center justify-center pl-2
                            `}
                            onClick={() => {
                                setShowPicker(!showPicker);
                            }}
                        >
                            <div className="flex flex-row items-center justify-center ">
                                <div className="w-7 h-7 rounded-lg border border-lightblue100" style={{ backgroundColor: value }}></div>
                            </div>
                        </div>
                        <input
                            type="text"
                            value={value}
                            className="text-center !p-2"
                            onChange={(e) => {
                                let inputValue = e.target.value.replace(/[^A-Fa-f0-9#]/g, "");
                                // Ensure it starts with a #
                                if (inputValue.indexOf("#") !== 0) {
                                    inputValue = `#${inputValue}`;
                                }

                                if (inputValue.length > 7) {
                                    inputValue = inputValue.slice(0, 7);
                                }
                                setValue(inputValue);
                            }}
                        />
                    </div>

                    {showPicker && (
                        <div
                            ref={pickerRef}
                            className={`absolute ${
                                pickerPos === "bottom" ? "top-[30px]" : "bottom-[30px]"
                            } z-40 right-0 w-200 bg-white/90 p-2 rounded-2xl slideFromBot border border-lightblue100/50 `}
                        >
                            <div className="flex flex-row gap-1 justify-between pb-3">
                                {solidDefaults.map((def, b) => (
                                    <div
                                        key={b}
                                        className={`cursor-pointer w-9 h-9 border-4 rounded-xl
                                ${def.value === value ? "border-lightblue100" : " border-white"}
                                `}
                                        style={{ backgroundColor: def.value, boxShadow: "inset 0 0 2px 0 rgba(0,0,0,0.4)" }}
                                        onClick={() => {
                                            setValue(def.value);
                                        }}
                                    ></div>
                                ))}
                            </div>

                            <div className="custom-picker">
                                <HexColorPicker
                                    color={value}
                                    onChange={(val) => {
                                        setValue(val);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* {showDefaultColors && (
                <div className="flex flex-row gap-1 justify-between">
                    {solidDefaults.map((def, b) => (
                        <div
                            key={b}
                            className={`cursor-pointer w-9 h-9 border-4 rounded-xl
                                ${def.value === value ? "border-lightblue100" : " border-white"}
                                `}
                            style={{ backgroundColor: def.value, boxShadow: "inset 0 0 2px 0 rgba(0,0,0,0.4)" }}
                            onClick={() => {
                                setValue(def.value);
                            }}
                        ></div>
                    ))}
                 
                </div>
            )} */}
        </div>
    );
};

export default UiColorPicker;
