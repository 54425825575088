import React from "react";
import { RiCopperCoinLine } from "react-icons/ri";
import { getStoredUser } from "src/common/components/auth/auth";

const CoinCard = ({ coins, type }) => {
    const user = getStoredUser();

    if (!coins) {
        coins = user.coins;
    }

    return (
        <>
            {type === "price" && (
                <div className="text-amber-500 text-xs w-7 h-7 rounded-full flex items-center justify-center border-2 border-amber-500 font-semibold bg-amber-100">{coins}</div>
            )}
            {!type && (
                <div className="coin-card flex items-center gap-1">
                    <RiCopperCoinLine className="text-xl text font-bold text-amber-500" />
                    <div className="">{coins}</div>
                    <div className="">{parseInt(user.coins) === 1 ? "Coin" : "Coins"}</div>
                </div>
            )}
        </>
    );
};

export default CoinCard;
