import React, { useRef, useState, useEffect } from "react";
import { Text, Transformer, Group } from "react-konva";

const TextLayer = (props) => {
    const { project, stageSize, detail, selectedDetail, setSelectedDetail, previewOnly } = props;
    const textRef = useRef();
    const trRef = useRef();
    const layerRef = useRef();
    const [isDragging, setIsDragging] = useState(false);

    // Initial positioning effect
    useEffect(() => {
        if (textRef.current && (detail.params.x === null || detail.params.y === null)) {
            const textWidth = textRef.current.width();
            const textHeight = textRef.current.height();

            updateDetail({
                newWidth: textWidth,
                newHeight: textHeight,
                newX: (stageSize.width - textWidth) / 2,
                newY: (stageSize.height - textHeight) / 2,
            });
        }
    }, [textRef.current, stageSize]);

    // Transform effect
    useEffect(() => {
        if (selectedDetail?.id === detail.id && trRef.current && textRef.current) {
            trRef.current.nodes([textRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [selectedDetail]);

    const updateDetail = ({ node, newX, newY, newWidth, newHeight }) => {
        const scaleX = !node ? 1 : node.scaleX();
        const scaleY = !node ? 1 : node.scaleY();

        if (setSelectedDetail) {
            setSelectedDetail((prev) => ({
                ...prev,
                params: {
                    ...prev.params,
                    x: newX ?? node?.x(),
                    y: newY ?? node?.y(),
                    width: newWidth ?? node?.width() * scaleX,
                    height: newHeight ?? node?.height() * scaleY,
                    rotation: node?.rotation() ?? prev?.rotation ?? 0,
                    textRef: textRef.current,
                },
            }));
        }

        if (node) {
            node.scaleX(1);
            node.scaleY(1);
        }
    };

    const handleSelect = () => {
        if (previewOnly) return;

        if (selectedDetail?.id !== detail.id) {
            setSelectedDetail({ ...detail });
            return;
        }
    };

    const handleEdit = () => {
        //Handle click for text editing
        const node = textRef.current;
        const stage = node.getStage();
        const stageBox = stage.container().getBoundingClientRect();
        const textPosition = node.getClientRect();

        const textarea = document.createElement("textarea");
        document.body.appendChild(textarea);

        // Style textarea to match text
        Object.assign(textarea.style, {
            position: "absolute",
            top: `${stageBox.top + textPosition.y}px`,
            left: `${stageBox.left + textPosition.x}px`,
            fontSize: `${node.fontSize()}px`,
            fontFamily: node.fontFamily(),
            fontWeight: detail.params.font.style.split(" ")[1],
            textAlign: node.align(),
            color: node.fill(),
            border: "none",
            padding: "0px",
            margin: "0px",
            overflow: "hidden",
            background: "none",
            outline: "none",
            resize: "none",
            lineHeight: node.lineHeight(),
            width: `${node.width() - node.padding() * 2}px`,
            zIndex: "999",
        });

        textarea.value = detail.params.text;
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight + 3}px`;
        textarea.focus();

        // Hide Konva text and transformer

        node.hide();
        trRef.current.hide();

        const removeTextarea = () => {
            setSelectedDetail((prev) => ({
                ...prev,
                params: {
                    ...prev.params,
                    text: textarea.value,
                },
            }));
            textarea.parentNode.removeChild(textarea);
            window.removeEventListener("click", handleOutsideClick);
            node.show();
            trRef.current.show();
        };

        // Event listeners
        textarea.addEventListener("input", () => {
            setSelectedDetail((prev) => ({
                ...prev,
                params: {
                    ...prev.params,
                    text: textarea.value,
                },
            }));
            textarea.style.height = "auto";
            textarea.style.height = `${textarea.scrollHeight + 3}px`;
        });

        textarea.addEventListener("keydown", (e) => {
            if ((e.key === "Enter" && !e.shiftKey) || e.key === "Escape") {
                removeTextarea();
            }
        });

        const handleOutsideClick = (e) => {
            if (e.target !== textarea) {
                removeTextarea();
            }
        };

        setTimeout(() => {
            window.addEventListener("click", handleOutsideClick);
        });
    };

    return (
        <Group ref={layerRef}>
            <Text
                ref={textRef}
                text={detail.params.text}
                fontSize={detail.params.font?.size}
                fill={detail.params.font?.color}
                align={detail.params.align}
                fontFamily={detail.params.font?.name}
                fontStyle={detail.params.font?.style}
                draggable={!previewOnly}
                x={detail.params.x}
                y={detail.params.y}
                width={detail.params.width}
                height={detail.params.height}
                rotation={detail.params?.rotation}
                onDblClick={handleEdit}
                onMouseDown={(e) => {
                    if (isDragging) {
                        return;
                    }
                    handleSelect();
                }}
                onDragStart={() => setIsDragging(true)}
                onDragEnd={(e) => {
                    updateDetail({ node: e.target });
                    setIsDragging(false);
                }}
                onTransformStart={() => {
                    setIsDragging(true);
                }}
                onTransformEnd={(e) => {
                    const node = textRef.current;
                    updateDetail({ node });
                    setIsDragging(false);
                }}
            />

            {selectedDetail?.id === detail.id && !previewOnly && (
                <Transformer
                    ref={trRef}
                    anchorStroke="#6676FF"
                    borderStroke="#6676FF"
                    borderStrokeWidth={2}
                    anchorStrokeWidth={2}
                    rotateEnabled={false}
                    enabledAnchors={["top-left", "top-center", "top-right", "middle-left", "middle-right", "bottom-left", "bottom-center", "bottom-right"]}
                    boundBoxFunc={(oldBox, newBox) => {
                        return newBox.width < 10 || newBox.height < 10 ? oldBox : newBox;
                    }}
                />
            )}
        </Group>
    );
};

export default TextLayer;
