import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Dbox from "src/common/components/dbox/dbox";
import { dateFormatter, shorten, processUrl } from "src/common/utils/utils";
import { deleteInputImage } from "src/common/services/input";
import More from "./card-image-more";
import logoIcon from "src/common/assets/images/logo-white.svg";

import { RiMore2Fill } from "react-icons/ri";

const CardImage = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { image, genData } = props;
    const [showMore, setShowMore] = useState();
    const [imageError, setImageError] = useState(false);

    const [the_image_url, set_the_image_url] = useState();
    const [the_image_name, set_the_image_name] = useState();

    useEffect(() => {
        //image
        //console.log("image ::", image);
        const image_url = processUrl(image?.url ?? "");
        //console.log("image_url ::", image_url);
        set_the_image_url(image_url);
        //name
        const image_name = image?.name ?? (image?.url ? image.url.split("/").pop() : "");
        set_the_image_name(image_name);
    }, [image]);

    const handleImage = async ({ opr, imageid, image }) => {
        switch (opr) {
            case "delete":
                if (imageid) {
                    const del_res = await deleteInputImage({ imageid });
                    //console.log("del_res :", del_res);

                    props.OnDelete(true);
                }
                break;
            case "onuse":
                if (image) {
                    const theimage = { ...image };
                    if (theimage.id === genData?.imageid) {
                        props.OnUse({});
                    } else {
                        theimage.type = "image";
                        props.OnUse({ ...theimage });
                    }
                }
                break;

            default:
                break;
        }
    };

    return (
        <div
            data-id={image.id}
            className={`transition-all flex flex-col relative border-4 border-lightblue100/50 rounded-3xl
                    ${props.for && genData && genData.imageid !== image.id ? "hover:border-4 hover:border-indigo-500" : ""}
                    ${props.for && genData && genData.imageid === image.id ? "border-4 !border-indigo-500" : ""}
                `}
        >
            <div
                className={`transition-all  min-h-${props.thumbsize ? props.thumbsize : "400"} max-h-${
                    props.thumbsize ? props.thumbsize : "400"
                }  flex-1 flex flex-col  relative overflow-hidden rounded-2xl ${
                    props.for && genData && genData.imageid === image.id ? "border-8 border-lightblue100 rounded-3xl" : ""
                }`}
            >
                <div
                    className={`relative flex items-center justify-center flex-1 overflow-hidden cursor-pointer ${
                        genData && genData.imageid === image.id ? "bg-blue" : " bg-zinc-950"
                    }`}
                    onClick={() => {
                        if (image.url) {
                            {
                                props.for === "select" && handleImage({ opr: "onuse", image });
                            }
                            {
                                !props.for && navigate(location.pathname, { state: { show: "detail-image", data: image } });
                            }
                        }
                    }}
                >
                    {the_image_url && !imageError && (
                        <img
                            src={the_image_url}
                            className={`w-full h-full object-contain ${genData && genData.imageid === image.id ? "opacity-50" : ""}`}
                            onError={(e) => {
                                //console.log("image error::", e);
                                setImageError(true);
                            }}
                        />
                    )}

                    {the_image_url && imageError && (
                        <div className="flex items-center justify-center flex-1 flex-col gap-3">
                            <img src={logoIcon} className={`w-1/3 `} />
                            <div className="text-xs text-white p-4 text-center opacity-40">No preview image</div>
                        </div>
                    )}
                </div>

                <div className={`info-box p-5 pr-3 bg-white flex gap-4 items-center justify-between ${props.for ? "cursor-pointer" : ""}`}>
                    <div className="flex flex-col justify-center">
                        <div>{shorten(the_image_name)}</div>
                        <div className="opacity-50 text-xs">{dateFormatter(image.uploaddate)}</div>
                    </div>

                    {!props.for && (
                        <div>
                            <RiMore2Fill
                                className="text-black text-xl cursor-pointer "
                                onClick={() => {
                                    setShowMore(true);
                                }}
                            />
                        </div>
                    )}
                    {props.actions && <div>{props.actions}</div>}
                </div>
            </div>
            {showMore && (
                <More
                    image={image}
                    OnClose={() => {
                        setShowMore(false);
                    }}
                />
            )}
        </div>
    );
};

export default CardImage;
