import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";

import FormInput from "../form/input";
import FormPhone from "../form/phone";
import { FaRegCircleCheck } from "react-icons/fa6";
import { SMS } from "src/common/services/share";
import Preloader from "../preloader/preloader";

const ShareSMS = (props) => {
    const { video } = props;
    const [onSent, setOnSent] = useState(null);
    const [fetching, setFetching] = useState(false);

    const [smsData, setSmsData] = useState({
        recipient_number: "",
        content: `Hey! Check this out. ${video?.result} ` + "\n" + `Got it from www.digitalu.live`,
    });

    useEffect(() => {
        if (onSent === true) {
            const timeout = setTimeout(() => {
                props.OnClose(true);
                clearTimeout(timeout);
            }, 1500);
        }
    }, [onSent]);

    const sendMessage = async () => {
        if (smsData && smsData.recipient_number != "") {
            setFetching(true);
            try {
                const send_res = await SMS(smsData);
                //console.log(send_res);
                setOnSent(true);
            } catch (error) {
                //console.log("error:", error);
            }
            setFetching(false);
        }
    };
    return (
        <>
            {fetching && <Preloader />}
            {!onSent && (
                <div className="flex flex-col gap-10 p-10">
                    <div className="flex items-start justify-between gap-4 mb-4">
                        <div className="text-xl font-medium tracking-snug">Share via SMS</div>
                        <IoMdClose
                            className="text-xl opacity-40 hover:opacity-100 cursor-pointer"
                            onClick={() => {
                                props.OnClose(true);
                            }}
                        />
                    </div>

                    <div className="flex flex-col gap-5">
                        {/* <div className="font-semibold">Recipient Number : </div> */}
                        <FormPhone
                            OnChange={(phone) => {
                                smsData.recipient_number = phone;
                                setSmsData({ ...smsData });
                            }}
                        />

                        <FormInput
                            type={"textarea"}
                            label={"Message :"}
                            value={smsData.content}
                            onChange={(e) => {
                                smsData.content = e.target.value;
                                setSmsData({ ...smsData });
                            }}
                        />

                        <button className="button" disabled={smsData.recipient_number === ""} onClick={async () => await sendMessage()}>
                            Send
                        </button>
                    </div>
                </div>
            )}

            {onSent && (
                <div className="flex-1 min-h-72 flex flex-col items-center justify-center gap-10">
                    <FaRegCircleCheck className="text-teal-500 text-6xl" />
                    <div>Message sent successfully.</div>
                </div>
            )}
        </>
    );
};

export default ShareSMS;
