import axios from "axios";
import endpoint from "src/common/services/endpoints";
import { getStoredUser } from "src/common/components/auth/auth";
import { handleRes, handleErr } from "src/common/services/response";

// --------------------------- QUERIES VIDEOS

export const getInputVideos = async ({ pageNum }) => {
    const token = getStoredUser()?.token;

    pageNum = pageNum || 1;

    const config = {
        method: "get",
        url: `${endpoint.rest}video/all?orderby=createdate desc&pagenumber=${pageNum}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

export const getInputVideo = async ({ id }) => {
    const token = getStoredUser()?.token;

    const config = {
        method: "get",
        url: `${endpoint.rest}video/${id}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

export const deleteInputVideo = async ({ videoid }) => {
    const token = getStoredUser()?.token;

    const config = {
        method: "delete",
        url: `${endpoint.rest}video/${videoid}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

// MUTATION VIDEOS

export const updateInputVideo = async ({ id, url }) => {
    const token = getStoredUser()?.token;

    const config = {
        method: "patch",
        url: `${endpoint.rest}video/${id}`,
        data: {
            auth: token,
            url,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

// --------------------------- QUERIES IMAGES

export const getInputImages = async ({ pageNum }) => {
    const token = getStoredUser()?.token;

    pageNum = pageNum || 1;

    const config = {
        method: "get",
        url: `${endpoint.rest}image/all?orderby=createdate desc&pagenumber=${pageNum}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

export const getInputImage = async ({ id }) => {
    const token = getStoredUser()?.token;

    const config = {
        method: "get",
        url: `${endpoint.rest}image/${id}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};

export const deleteInputImage = async ({ imageid }) => {
    const token = getStoredUser()?.token;

    const config = {
        method: "delete",
        url: `${endpoint.rest}image/${imageid}`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
        },
    };

    try {
        const res = await axios(config);
        return handleRes({res, config});
    } catch (error) {
        handleErr({error, config});
    }
};
