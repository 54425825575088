import { v4 as uuidv4 } from "uuid";

export default class SceneClass {
    constructor(data = {}) {
        // Base properties
        this.id = data.id || uuidv4();
        this.name = data.name || `Scene ${data.projectSceneCount + 1}`;
        this.active = data.active || false;
        this.steps = data.steps || [];
        this.inputs = data.inputs || [];
        this.result = data.result || {
            src: null,
        };
        this.background_base64 = data.background_base64 || null;
        this.base64 = data.base64 || null;

        this.background = data.background || "#ffffff";
    }

    getData() {
        return {
            id: this.id,
            name: this.name,
            active: this.active,
            steps: this.steps,
            inputs: this.inputs,
            result: this.result,
            background_base64: this.background_base64,
            base64: this.base64,
            background: this.background,
        };
    }

    addStep(step) {
        this.steps.push(step);
    }

    static create(data = {}) {
        const sceneDefaults = {
            name: data.name || `Scene ${data.projectSceneCount + 1}`,
            projectSceneCount: data.projectSceneCount || 0,
        };

        return new SceneClass({
            ...sceneDefaults,
            ...data,
        });
    }
}
