import { useState, useEffect } from "react";

import VideoUrl from "./tabs/video-url";
import VideoUpload from "./tabs/video-upload";

import ListVideos from "src/modules/admin/inputed-videos/list-videos";
import ListImages from "src/modules/admin/inputed-images/list-images";

import { getNameFromUrl } from "src/common/utils/utils";

import { checkFileType } from "src/common/utils/utils";

const AddVideo = (props) => {
    const { genData, onUse } = props;

    const videoTabs = [
        {
            id: "your-videos",
            label: "Your Videos",
            desc: "Videos you have uploaded.",
        },
        {
            id: "your-images",
            label: "Your Images",
            desc: "Images you have uploaded.",
        },
        {
            id: "upload",
            label: "Upload",
            desc: "Upload a new video or image.",
        },
        {
            id: "url",
            label: "URL",
            desc: "Add a URL of a video or image.",
        },
    ];

    const [videoTab, setVideoTab] = useState(videoTabs[0].id);

    useEffect(() => {
        if (genData) {
            if (genData.videourl || genData.imageurl) {
                setVideoTab("url");
            } else if (genData.videoid) {
                setVideoTab("your-videos");
            } else if (genData.imageid) {
                setVideoTab("your-images");
            }
        }
    }, []);

    useEffect(() => {
        props.OnSet({ ...genData });
    }, [videoTab]);

    const extractName = (url) => {
        let thename = "";
        if (url) {
            thename = getNameFromUrl(url, true).replace(/[^a-zA-Z0-9_\s]/g, "");
        }
        return thename;
    };

    const updateGenData = ({ video, image, file, url, name }) => {
        let updatedGenData = {
            ...genData,
            name: name ? extractName(name) : genData.name,
        };

        // Properties to always clear
        const propertiesToClear = ["file", "videoid", "videourl", "imageid", "imageurl"];
        propertiesToClear.forEach((prop) => delete updatedGenData[prop]);

        // Conditionally set properties
        if (video?.id) updatedGenData.videoid = video.id;
        if (image?.id) updatedGenData.imageid = image.id;
        if (file?.name) {
            updatedGenData.file = file;
        }

        if (url) {
            updatedGenData = { ...updatedGenData, ...url };
        }

        props.OnSet(updatedGenData);
    };

    return (
        <div className="flex flex-1 flex-col gap-4  md:gap-8 py-0 md:py-10 w-full mx-auto">
            <div className="flex flex-row items-start gap-6">
                {/* <div className="flex items-center justify-center text-lg bg-lightblue100  rounded-full w-12 h-12 mt-2">1</div> */}
                <div className="flex flex-col gap-1">
                    <div className="text-lg md:text-xl font-semibold"> Select Video / Image</div>
                    <div className="opacity-50">Select a video or an image.</div>
                </div>
            </div>

            {(!onUse || (onUse && onUse.type === "voice")) && (
                <>
                    <div className="flex flex-row items-center justify-center text-center bg-lightblue80 rounded-2xl p-2">
                        {videoTabs.map((tab, t) => (
                            <div
                                key={t}
                                className={`transition flex-1 cursor-pointer ${videoTab === tab.id ? "opacity-100 bg-white rounded-2xl py-4" : "opacity-50"}`}
                                onClick={() => {
                                    // delete genData.videoid;
                                    // delete genData.videourl;
                                    // delete genData.imageid;
                                    // delete genData.imageurl;
                                    //props.OnSelectTab(tab.id);
                                    setVideoTab(tab.id);
                                }}
                            >
                                <div className="font-semibold ">{tab.label}</div>
                                <div className="opacity-40 hidden md:block">{tab.desc}</div>
                            </div>
                        ))}
                    </div>

                    {videoTab === "your-videos" && (
                        <div className="flex-1">
                            <ListVideos
                                cols={"5"}
                                selectedId={genData.videoid}
                                for={"select"}
                                OnUse={(video) => {
                                    updateGenData({ video, name: video.url });
                                }}
                            />
                        </div>
                    )}

                    {videoTab === "your-images" && (
                        <div className="flex-1">
                            <ListImages
                                cols={"5"}
                                genData={genData}
                                for={"select"}
                                OnUse={(image) => {
                                    updateGenData({ image, name: image.url });
                                }}
                            />
                        </div>
                    )}

                    {videoTab === "upload" && (
                        <VideoUpload
                            genData={genData}
                            OnSet={(data) => {
                                //console.log(data);

                                updateGenData({ file: data.file });

                                // if (data.file?.name) {
                                //     data.name = data.file.name.split(".")[0].replace(/[^a-zA-Z0-9\s]/g, "");
                                // }
                                // props.OnSet({ ...data });
                                //  setGenData({ ...data });
                            }}
                        />
                    )}

                    {videoTab === "url" && (
                        <VideoUrl
                            genData={genData}
                            OnSet={(data) => {
                                //console.log(data);
                                updateGenData({ url: data });

                                // let theurl;
                                // if (data.videourl) {
                                //     theurl = data.videourl;
                                // } else if (data.imageurl) {
                                //     theurl = data.imageurl;
                                // } else if (data.URL) {
                                //     theurl = data.URL;
                                // }

                                // data.name = extractName(theurl);

                                // props.OnSet({ ...data });
                                //setGenData({ ...data });
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default AddVideo;
