import { useEffect, useState } from "react";
import axios from "axios";
import { MdOutlineFileDownload } from "react-icons/md";
import { TbRotateClockwise2 } from "react-icons/tb";
import { genUpscale } from "src/common/services/generate";
import { handleDownload } from "src/common/utils/utils";
const EnhanceVid = ({ video }) => {
    const [upscale, setUpscale] = useState("checking");
    const initialLink = {
        href: `${video.result}/upscaled.mp4`,
        target: null,
        label: (
            <>
                Enhance{" "}
                <div className="text-amber-500 text-xs w-6 h-6 rounded-full flex items-center justify-center border-2 border-amber-500 font-semibold">5</div>
            </>
        ),
        className: "",
    };

    const [link, setLink] = useState(initialLink);

    useEffect(() => {
        handleEnhance();
    }, []);

    useEffect(() => {
        switch (upscale) {
            case "initial":
                setLink(initialLink);
                break;

            case "checking":
                link.label = "Checking";
                link.className = "text-amber-500 loading";
                setLink({ ...link });
                break;

            case "processing":
                link.label = (
                    <>
                        Enhancing <TbRotateClockwise2 className="text-lg animate-spin" />
                    </>
                );
                link.className = "text-amber-500";

                setLink({ ...link });
                break;

            case "done":
                link.label = (
                    <>
                        Enhanced <MdOutlineFileDownload className="text-lg" />
                    </>
                );
                link.className = "text-teal-500";
                link.target = "_blank";

                setLink({ ...link });
                break;
            default:
                break;
        }
    }, [upscale]);

    const handleEnhance = async () => {
        let theopr = "get";

        if (upscale === "initial") {
            theopr = "set";
        }

        try {
            setUpscale("checking");

            const up_res = await genUpscale({ opr: theopr, video });
            //console.log("get res: ", up_res);
            if (up_res && up_res[0]?.message) {
                const message = up_res[0]?.message;
                switch (true) {
                    case message === "no upscaled video found":
                        setUpscale("initial");
                        break;
                    case message === "in processing":
                        setUpscale("processing");
                        break;
                    case message !== "no upscaled video found" && message !== "in processing" && message !== "":
                        //console.log("done");
                        setUpscale("done");
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            //console.log(error);
        }
    };

    return (
        <a
            href={link.href}
            target={link.target}
            //    download={upscale === "done" ? `${video.result}/upscale.mp4` : "#"}
            className="px-6 py-1 transition cursor-pointer hover:opacity-70 hover:translate-x-0.5  flex-1  "
            onClick={async (e) => {
                e.preventDefault();
                if (upscale === "done") {
                    handleDownload(video.result + "/upscaled.mp4", video.name);
                } else {
                    await handleEnhance();
                }
              
            }}
        >
            <div className={`flex-1 flex flex-row items-center justify-between gap-4 ${link.className}`}>{link.label}</div>
        </a>
    );
};

export default EnhanceVid;
