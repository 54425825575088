import { useState, useRef } from "react";

import { v4 as uuidv4 } from "uuid";
import { GrUploadOption } from "react-icons/gr";

import ListGen from "../../generated-videos/list-gen";
import ListVideos from "../../inputed-videos/list-videos";
import ListImages from "../../inputed-images/list-images";
import { prettyDOM } from "@testing-library/react";
const InputSelect = (props) => {
    const { setSelectedScene, setSelectedDetail, setShowInputAdd } = props;
    const [search, setSearch] = useState("");
    const fileInputRef = useRef(null);

    const tabs = [
        {
            id: "generated",
            label: "Generated Videos",
        },
        {
            id: "input-videos",
            label: "Input Videos",
        },
        {
            id: "input-images",
            label: "Input Images",
        },
        {
            id: "upload",
            label: "Upload",
        },
    ];

    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const handleSelectInput = (input) => {
        console.log(input);

        const theInput = {
            id: uuidv4(),
            type: null,
            detailType: "inputs",
            params: {
                ...input.params,
                src: null,
                //file: null,
                width: null,
                height: null,
                x:null,
                y:null

            },
        };

        //NORMALIZE INPUT
        if (input.result) {
            theInput.params.src = input.result;
        } else if (input.url) {
            theInput.params.src = input.url;
        } else if (input.constructor.name === "File") {
            theInput.params.src = URL.createObjectURL(input);
            theInput.params.file = input;
            theInput.type = input.type.split("/")[0];
        }

        if (selectedTab.id === "input-images") {
            theInput.type = "image";
        } else if (selectedTab.id === "input-videos" || selectedTab.id === "generated") {
            theInput.type = "video";
        }

        console.log('theInput ::', theInput)

        setSelectedScene((prev) => ({
            ...prev,
            inputs: [theInput], 
        }));
        setSelectedDetail(theInput)

        setShowInputAdd(false);
    };

    return (
        <div className="flex flex-col  min-h-600 max-h-600">
            <div className="flex flex-col p-10 gap-8">
                <div className=" text-lg">Select Input</div>
                <div className="flex flex-row items-center justify-center text-center bg-lightblue80 rounded-2xl p-2 ">
                    {tabs.map((tab, t) => (
                        <div
                            key={t}
                            className={`transition flex-1 cursor-pointer ${tab.id === selectedTab.id ? "opacity-100 bg-white rounded-xl py-2" : "opacity-50"}`}
                            onClick={() => {
                                setSelectedTab({ ...tab });
                            }}
                        >
                            <div className="">{tab.label}</div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="flex flex-1 flex-col min-w-full relative z-10 overflow-y-auto p-10 pt-0">
                {selectedTab.id === "generated" && (
                    <ListGen
                        cols={4}
                        thumbsize={"300"}
                        // selectedId={theVideo.avatar.video?.id}
                        for={`select`}
                        paging={"manual"}
                        OnUse={(video) => {
                            handleSelectInput(video);
                        }}
                    />
                )}

                {selectedTab.id === "input-videos" && (
                    <ListVideos
                        cols={4}
                        thumbsize={"300"}
                        // selectedId={theVideo.avatar.video?.id}
                        for={`select`}
                        paging={"manual"}
                        OnUse={(video) => {
                            handleSelectInput(video);
                        }}
                    />
                )}

                {selectedTab.id === "input-images" && (
                    <ListImages
                        cols={4}
                        thumbsize={"300"}
                        // selectedId={theVideo.avatar.video?.id}
                        for={`select`}
                        paging={"manual"}
                        OnUse={(video) => {
                            handleSelectInput(video);
                        }}
                    />
                )}

                {selectedTab.id === "upload" && (
                    <div className="flex-1 flex flex-col items-center justify-center">
                        <div className="transition min-h-full min-w-full flex-1 flex items-center gap-2 p-3 group border border-dashed border-indigo-800/30 rounded-2xl cursor-pointer hover:border-blue bg-blue/10 text-black/30 hover:text-blue items-center justify-center">
                            <button
                                className="relative z-10 button-white p-2 pr-5 rounded-3xl cursor-pointer flex items-center gap-2 capitalize"
                                onClick={() => fileInputRef.current?.click()}
                            >
                                <GrUploadOption className="text-md" />
                                Upload Media
                            </button>
                        </div>

                        <input
                            type="file"
                            ref={fileInputRef}
                            id="mediaInput"
                            className="hidden"
                            accept="image/*,video/*"
                            onChange={(e) => {
                                handleSelectInput(e.target.files[0]);
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default InputSelect;
