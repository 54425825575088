import React, { useRef, useState, useEffect } from "react";
import { GrRedo, GrUndo } from "react-icons/gr";
import StageContainer from "./stage";
import Preloader from "src/common/components/preloader/preloader";

const Artboard = (props) => {
    const { project, setProject, selectedDetail, setSelectedDetail, selectedScene, setSelectedScene, stageSize, setStageSize } = props;
    const containerRef = useRef(null);

    return (
        <div className="flex-1 relative">
            <div className="artboard absolute w-full h-full left-0 top-0 flex flex-col flex-1 overflow-hidden">

                <div className="flex bg-white rounded-full border-2 border-lightblue100/90 absolute right-0 top-0 z-20">
                    <div className={`flex p-3 py-2 cursor-pointer hover:bg-lightblue100/20`} onClick={() => {}}>
                        <GrUndo className="text-lg" />
                    </div>
                    <div className={`flex p-3 py-2 cursor-pointer border-l border-lightblue100 hover:bg-lightblue100/20`} onClick={() => {}}>
                        <GrRedo className="text-lg" />
                    </div>
                </div>



                <div ref={containerRef} className="stage-continer relative flex flex-1 z-10 items-center justify-center">
                    {selectedScene?.generating && <Preloader text={`Generating scene`} />}   
                    <StageContainer
                        containerRef={containerRef}
                        project={project}
                        setProject={setProject}
                        stageSize={stageSize}
                        setStageSize={setStageSize}
                        
                        selectedScene={selectedScene}
                        setSelectedScene={setSelectedScene}
                        selectedDetail={selectedDetail}
                        setSelectedDetail={setSelectedDetail}
                      
                        
                    />
                </div>
            </div>
        </div>
    );
};

export default Artboard;
