import { useLocation, useNavigate } from "react-router-dom";
import AdminLayout from "src/layouts/admin-layout";
import ListGen from "src/modules/admin/generated-videos/list-gen";
import { all } from "src/common/data/apis-generate";

const HotVideos = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <AdminLayout
                header={(
                    <div className="flex flex-1 flex-col ">
                        <div className="flex flex-1 flex-row px-0 items-center">
                            <div className="flex items-center gap-5">
                                <div className="flex-1 text-lg md:text-xl font-semibold">Trending  Videos</div>
                            </div>
                        </div>
                    </div>
                )}
            >
                <div className="container mx-auto flex  flex-col flex-1 h-full">
                    <ListGen vtype={`public`} />
                </div>
            </AdminLayout>
        </>
    );
};

export default HotVideos;
