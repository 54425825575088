import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable, horizontalListSortingStrategy } from "@dnd-kit/sortable";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import SceneClass from "./class-scene";
import Scene from "./scene";
import SceneProject from "./scene-project";
import { generateProject } from "../studio-funcs";
import { MdOutlineFileDownload } from "react-icons/md";
import { handleDownload } from "src/common/utils/utils";
import StepClass from "../step/class-step";

const getSceneDimensions = (project) => ({
    width: (120 * project.width) / project.height,
    height: 120,
});

const Scenes = (props) => {
    const [editMode, setEditMode] = useState(false);
    const { project, setProject, setSelectedScene, selectedScene, selectedDetail, setSelectedDetail, stageSize } = props;
    const [merging, setMerging] = useState(false);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                delay: 0, // 500ms = 0.5 seconds
                tolerance: 5, // Allow slight movement during delay
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
            const oldIndex = project.scenes.findIndex((scene) => scene.id === active.id);
            const newIndex = project.scenes.findIndex((scene) => scene.id === over.id);

            const newScenes = arrayMove(project.scenes, oldIndex, newIndex);
            setProject((prev) => ({ ...prev, scenes: newScenes }));
        }
    };

    const handleScene = ({ opr, scene }) => {
        switch (opr) {
            case "add":
                const newScene = SceneClass.create({ projectSceneCount: project.scenes.length });
                setProject((prev) => ({ ...prev, scenes: [...prev.scenes, newScene.getData()] }));
                setSelectedScene(newScene.getData());
                setSelectedDetail(null);
                break;

            case "active":
                setProject((prev) => ({ ...prev, scenes: prev.scenes.map((s) => (s.id === scene.id ? { ...s, active: !s.active } : s)) }));
                break;

            case "delete":
                const updatedScenes = project.scenes.filter((s) => s.id !== scene.id);
                setProject((prev) => ({ ...prev, scenes: updatedScenes }));
                if (updatedScenes.length === 0) {
                    setEditMode(false);
                }
                if (selectedScene && selectedScene.id === scene.id) {
                    setSelectedScene(null);
                }

                break;
            default:
                break;
        }
    };

    const handleProject = async ({ opr }) => {
        switch (opr) {
            case "merge":
                const activeScenes = project.scenes.filter((scene) => scene.active);
                const step_mergeScenes = StepClass.transition({ params: { scenes: activeScenes, duration: 5 } });
                console.log("step_mergeScenes", step_mergeScenes);

                const newScene = SceneClass.create({ name: `Merged Scene ${project.scenes.length + 1}`, projectSceneCount: project.scenes.length });
                newScene.addStep(step_mergeScenes);
                
                setProject((prev) => ({
                    ...prev,
                    scenes: [...prev.scenes, newScene.getData()],
                }));

                setSelectedScene(newScene.getData());
                setSelectedDetail(null);
                
                break;
            case "selectAll":
                setProject((prev) => ({ ...prev, scenes: prev.scenes.map((scene) => ({ ...scene, active: true })) }));
                break;
            default:
                break;
        }
    };

    // Check for both active scenes and their result.src
    const activeSceneCount = project.scenes.filter((scene) => scene.active && scene.result?.src).length;
   
    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <div className="scenes-container flex flex-row bg-white border border-lightblue100 rounded-2xl relative overflow-hidden">
                {project.result.url && (
                    <div className="scene-generated flex flex-col gap-4 border-r border-lightblue100 p-4">
                        <div className="scene-generated-label flex-1 px-1 min-h-8 max-h-8 flex flex-row items-center justify-between gap-6">
                            <div className="">Merged Video</div>
                            <div className="flex flex-row gap-2 items-center justify-center">
                                <MdOutlineFileDownload
                                    className="text-lg w-5 h-5 text-black cursor-pointer hover:text-blue"
                                    onClick={() => {
                                        handleDownload(project.result.url, `${project.name}.mp4`);
                                    }}
                                />
                            </div>
                        </div>
                        <SceneProject
                            project={project}
                            setProject={setProject}
                            setSelectedDetail={setSelectedDetail}
                            setSelectedScene={setSelectedScene}
                            stageSize={stageSize}
                        />
                    </div>
                )}
                <div className="flex flex-1 flex-col gap-4 p-4 pb-0">
                    <div className="scenes-actions flex-1 px-1 min-h-8 max-h-8 flex flex-col items-start justify-center">
                        <div className="flex flex-row gap-8">
                            <div
                                className={`cursor-pointer text-black  hover:text-blue`}
                                onClick={() => {
                                    handleScene({ opr: "add" });
                                }}
                            >
                                Add Scene
                            </div>

                            <div
                                className={`cursor-pointer ${editMode ? "text-blue" : "text-black hover:text-blue"} `}
                                onClick={() => {
                                    setEditMode(!editMode);
                                }}
                            >
                                {editMode ? "Done" : "Edit"}
                            </div>

                            <div className="separator w-0.5 h-full border-r border-lightblue100"></div>

                            {activeSceneCount >= 2 && (
                                <div
                                    className={`cursor-pointer text-black  ${merging ? "opacity-30 cursor-not-allowed" : "hover:text-blue"}`}
                                    onClick={() => {
                                        if (!merging) {
                                            handleProject({ opr: "merge" });
                                        }
                                    }}
                                >
                                    {merging ? "Merging..." : "Merge"}
                                </div>
                            )}

                            {/* {hasInactiveScenes && (
                                <div
                                    className={`cursor-pointer text-black  ${
                                        !hasActiveScenes || merging ? "opacity-30 cursor-not-allowed" : "hover:text-blue"
                                    }`}
                                    onClick={() => {
                                        handleProject({ opr: "selectAll" });
                                    }}
                                >
                                    Select All
                                </div>
                            )} */}
                        </div>
                    </div>
                    <div className="scenes-list flex-1 flex flex-row gap-4 relative min-h-[150px]">
                        <div className="absolute top-0 left-0 w-full h-full bg-white z-20 overflow-x-auto flex flex-row gap-4 pb-4">
                            <div className="flex flex-1 flex-row gap-4 relative">
                                <SortableContext items={project.scenes.map((scene) => scene.id)} strategy={horizontalListSortingStrategy}>
                                    {project.scenes.map((scene) => (
                                        <Scene
                                            key={scene.id}
                                            scene={scene}
                                            setProject={setProject}
                                            project={project}
                                            selectedScene={selectedScene}
                                            setSelectedScene={setSelectedScene}
                                            selectedDetail={selectedDetail}
                                            setSelectedDetail={setSelectedDetail}
                                            handleScene={handleScene}
                                            editMode={editMode}
                                            stageSize={stageSize}
                                        />
                                    ))}
                                </SortableContext>
                                {project.scenes?.length <= 0 && (
                                    <div className="flex flex-col items-center justify-center gap-2 sticky right-0 bg-white min-w-32 z-20">
                                        <div
                                            className={`transition-all scene-video rounded-2xl overflow-hidden relative text-blue text-xl border border-dashed border-blue/50 flex items-center justify-center cursor-pointer hover:border-blue hover:bg-blue/10 text-black/30 hover:text-blue`}
                                            style={getSceneDimensions(project)}
                                            onClick={() => {
                                                handleScene({ opr: "add" });
                                            }}
                                        >
                                            +
                                        </div>
                                        <div className="scene-name text-xs text-center text-blue">Add Scene</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DndContext>
    );
};

export default Scenes;
