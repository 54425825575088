import { useEffect, useState } from "react";
import { getStoredUser } from "src/common/components/auth/auth";

import { copyToClipboard } from "../utils/utils";
import { FaRegCopy } from "react-icons/fa";
import { LuCheckCircle } from "react-icons/lu";
import Dbox from "./dbox/dbox";
import { IoClose } from "react-icons/io5";

const LogBox = (props) => {
    const { video, data } = props;
    const [copied, setCopied] = useState(false);
    const [copiedResult, setCopiedResult] = useState(false);
    const [show, setShow] = useState(false);

    const thevideo = { ...video };
    delete thevideo.id;
    delete thevideo.createdate;
    delete thevideo.result;
    delete thevideo.state;

    
    useEffect(() => {
        if (copied) {
            const timeout = setTimeout(() => {
                setCopied(false);
                clearTimeout(timeout);
            }, 2000);
        }
    }, [copied]);

    useEffect(() => {
        if (copiedResult) {
            const timeout = setTimeout(() => {
                setCopiedResult(false);
                clearTimeout(timeout);
            }, 2000);
        }
    }, [copiedResult]);

    const [selected, setSelected] = useState();

    useEffect(() => {
        if (data) {
            setSelected(data[0]);
        }
    }, [data]);

    return (
        <>
            <div
                style={{ zIndex: 999999 }}
                className="fixed bottom-5 left-5 z-50 transition-all bg-white border border-zinc-500/40 rounded-full p-2 px-5  flex items-center gap-2 opacity-60 hover:opacity-100 hover:text-blue hover:border-blue cursor-pointer"
                onClick={() => {
                    setShow(!show);
                    //navigate(location.pathname, { state: { show: "api-box" } });
                }}
            >
                <div>Log</div>
            </div>

            {show && (
                <Dbox style={{ zIndex: 999999 }} className="fixed bottom-0 left-0  w-full flex gap-5 min-h-96 max-h-96 bg-white p-8 overflow-hidden">
                    <IoClose
                        className="absolute right-5 top-5 cursor-pointer text-lg "
                        onClick={() => {
                            setShow(false);
                        }}
                    />
                    <div className="flex-1 flex flex-row gap-6">
                        <div className="flex-1 flex flex-col min-h-full relative gap-3">
                            <div className="">Query :</div>
                            <div className="flex-1 flex flex-col border border-lightblue100 rounded-2xl bg-lightblue50 relative">
                                <div className="flex flex-row gap-2 p-3">
                                    {data &&
                                        data.map((api, a) => (
                                            <div
                                                key={a}
                                                className={`px-4 py-1 cursor-pointer ${
                                                    selected && selected.id === api.id ? "bg-white rounded-full shadow-lg shadow-indigo-500/10" : "opacity-40"
                                                }`}
                                                onClick={() => {
                                                    setSelected(api);
                                                }}
                                            >
                                                {api.id}
                                            </div>
                                        ))}
                                </div>
                                {/* {selected && ( */}
                                <>
                                    <div
                                        className="transition-all absolute bottom-4 right-4 bg-white rounded-full p-4 shadow-lg shadow-indigo-500/30 cursor-pointer hover:opacity-50 z-50 "
                                        onClick={() => {
                                            // setCopied(copyToClipboard(selected.query));
                                            setCopied(copyToClipboard(localStorage.getItem("api-curl")));
                                        }}
                                    >
                                        {!copied ? <FaRegCopy className="text-lg" /> : <LuCheckCircle className="text-lg text-emerald-500" />}
                                    </div>

                                    <div className="flex-1 relative overflow-hidden">
                                        <div className="absolute p-6 left-0 top-0 w-full h-full overflow-auto">
                                            {/* <pre className="text-wrap">{selected.query}</pre> */}
                                            <pre className="text-wrap">{localStorage.getItem("api-curl")}</pre>
                                        </div>
                                    </div>
                                </>
                                {/* )} */}
                            </div>
                        </div>
                        <div className="flex-1 flex flex-col  min-h-full relative gap-3">
                            <div className="">Result :</div>
                            <div className="flex-1 flex flex-col border border-lightblue100 rounded-2xl bg-lightblue50 relative">
                                <div
                                    className="transition-all absolute bottom-4 right-4 bg-white rounded-full p-4 shadow-lg shadow-indigo-500/30 cursor-pointer hover:opacity-50 z-50 "
                                    onClick={() => {
                                        setCopied(copyToClipboard(localStorage.getItem("api-result")));
                                    }}
                                >
                                    {!copiedResult ? <FaRegCopy className="text-lg" /> : <LuCheckCircle className="text-lg text-emerald-500" />}
                                </div>

                                <div className="flex-1 relative overflow-hidden">
                                    <div className="absolute p-6 left-0 top-0 w-full h-full overflow-auto">
                                        <pre className="text-nowrap">{localStorage.getItem("api-result")}</pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Dbox>
            )}
        </>
    );
};

export default LogBox;
