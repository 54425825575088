import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import AdminLayout from "src/layouts/admin-layout";
import Artboard from "src/modules/admin/studio/artboard/artboard";
import Sidebar from "src/modules/admin/studio/sidebar/sidebar";

import { LuRectangleHorizontal } from "react-icons/lu";
import { LuRectangleVertical } from "react-icons/lu";
import { LuSquare } from "react-icons/lu";

import { getQueue } from "src/common/services/utils";
import { getRealVal, handleOverlay, handleToEdit } from "./studio-funcs";
import Preloader from "src/common/components/preloader/preloader";

const Studio = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [fetching, setFetching] = useState(false);

    const screens = [
        {
            id: "desktop",
            icon: <LuRectangleHorizontal className="text-lg" />,

            width: 1280,
            height: 720,
        },
        {
            id: "mobile",
            icon: <LuRectangleVertical className="text-lg" />,

            width: 720,
            height: 1280,
        },
        {
            id: "square",
            icon: <LuSquare className="text-lg" />,

            width: 720,
            height: 720,
        },
    ];

    // imageurl, videourl, audiourl, imageid, videoid, voiceid, voicegender
    const initialVideoState = {
        avatar: {
            effects: [
                {
                    type: "nobg",
                    value: false,
                },
                {
                    type: "enhance",
                    value: false,
                },
            ],
            video: null,
        },
        script: { text: "", speed: 1.0 },
        bg: { type: "color", value: "#ffffff", base64: null },
        texts: [],
        audio: {
            file: null,
            volume: 1,
        },

        gen_input: null,
        gen_output: null,

        overlay_input: null,
        overlay_output: null,

        texts_base64: null,
    };

    const [selScreen, setSelScreen] = useState(screens[1]);
    const [stageSize, setStageSize] = useState({ width: 0, height: 0 });
    const [selElem, setSelElem] = useState({ type: null, value: null });
    const [theVideo, setTheVideo] = useState(initialVideoState);
    const [history, setHistory] = useState([initialVideoState]); // Keeps track of state history
    const [currentIndex, setCurrentIndex] = useState(0); // Tracks current state in history
    const [redoStack, setRedoStack] = useState([]); // Tracks redoable states

    useEffect(() => {
        if (location?.state?.todo === "edit-studio" && location?.state?.data) {
            const toEditVideo = { ...location.state.data };
            toEditVideo.json = JSON.parse(toEditVideo.json);
            //console.log("toEditVideo json :: ", toEditVideo.json);
            handleToEdit({ toEdit: toEditVideo.json, setTheVideo, stageSize, selScreen });
        }
    }, [location]);

    useEffect(() => {
        //console.log(" -- theVideo ::", theVideo);
        //FOR OVERLAY
        if (
            // theVideo.gen_output &&
            // (theVideo.gen_output.state === "complete" ||  theVideo.gen_output.state === "reused") &&
            // theVideo.gen_output.result &&
            // theVideo.overlay_input === "init" &&
            // theVideo.overlay_output === null

            theVideo.avatar.video &&
            theVideo.avatar.video.result &&
            theVideo.overlay_input === "init" &&
            theVideo.overlay_output === null
        ) {
            setFetching(true);
            handleOverlay({ theVideo, stageSize, selScreen }).then((res) => {
                try {
                    setTheVideo((prev) => ({
                        ...prev,
                        overlay_output: res,
                    }));

                    navigate("/admin/studio/videos");
                } catch (error) {
                    //console.log(error);
                    setTheVideo((prev) => ({
                        ...prev,
                        overlay_output: error,
                    }));
                }
                setFetching(false);
            });
        }
    }, [theVideo]);

    // useEffect(() => {
    //     //console.log(" -- selElem ::", selElem);
    // }, [selElem]);

    const updateTheVideo = (newVideoState) => {
        const newHistory = [...history.slice(0, currentIndex + 1), newVideoState];
        setTheVideo(newVideoState);
        setHistory(newHistory);
        setCurrentIndex(newHistory.length - 1);
        setRedoStack([]); // Clear redo stack whenever a new change is made
    };

    // Undo function
    const undo = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            setTheVideo(history[currentIndex - 1]);
            setRedoStack([theVideo, ...redoStack]);
        }
    };

    // Redo function
    const redo = () => {
        if (redoStack.length > 0) {
            const nextState = redoStack[0];
            setRedoStack(redoStack.slice(1));
            setCurrentIndex(currentIndex + 1);
            setTheVideo(nextState);
        }
    };

    return (
        <AdminLayout
            type="full"
            // header={() => (
            //     <div className="flex flex-1 items-center ">
            //         <div className="flex flex-1 flex-row px-0 items-center gap-6">
            //             <div className="text-xl font-semibold">Studio</div>
            //         </div>
            //     </div>
            // )}
        >
            {fetching && <Preloader text={`Generating your video`} />}
            <div className="studio-container absolute left-0 top-0 h-full w-full flex-1 flex flex-row gap-4 relative overflow-hidden">
                <Sidebar
                    screens={screens}
                    stageSize={stageSize}
                    setStageSize={setStageSize}
                    selScreen={selScreen}
                    setSelScreen={setSelScreen}
                    theVideo={theVideo}
                    setTheVideo={updateTheVideo}
                    selElem={selElem}
                    setSelElem={setSelElem}
                />
                <Artboard
                    screens={screens}
                    stageSize={stageSize}
                    setStageSize={setStageSize}
                    selScreen={selScreen}
                    setSelScreen={setSelScreen}
                    theVideo={theVideo}
                    setTheVideo={updateTheVideo}
                    selElem={selElem}
                    setSelElem={setSelElem}
                    currentIndex={currentIndex}
                    redoStack={redoStack}
                    OnHistory={(dir) => {
                        switch (dir) {
                            case "undo":
                                undo();
                                break;
                            case "redo":
                                redo();
                                break;
                            default:
                                break;
                        }
                    }}
                />
            </div>
        </AdminLayout>
    );
};

export default Studio;
